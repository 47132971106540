import ModalForm from '@/components/modal/ModalForm';
import { ButtonClear, ButtonPrimary } from '@/components/button/Button';
import useModals from '@/src/hooks/useModals';

export default function ModalNotEnoughMoney({
    error: {
        apiError: { messageRus = 'Погасите задолженность по счету дом.ру', headerUI },
    },
}) {
    const { closeModal } = useModals();
    const pageTitle = 'Недостаточно денег для оплаты покупки';

    return (
        <ModalForm
            className={'modal--primary modal--process-buy-big'}
            pageUrl={'#not-enough-money'}
            pageTitle={pageTitle}
            onHide={closeModal}
        >
            <div className="modal__content-title">{headerUI || title || pageTitle}</div>
            <div className="modal__content-subtitle">
                {messageRus}.<br />
                После зачисления денег повторите покупку.
            </div>
            <div className="modal__qr-wrapper">
                <img
                    className="modal__qr-image"
                    src="/assets/img/qr-pay.svg"
                    width="240"
                    height="240"
                    alt="Сканируйте QR-код, чтобы&nbsp;пополнить баланс в&nbsp;личном кабинете дом.ру"
                />
                <div className="modal__qr-image-hint">
                    Сканируйте QR-код, чтобы&nbsp;пополнить баланс в&nbsp;личном кабинете дом.ру или&nbsp;нажмите
                    «Пополнить баланс»
                </div>
            </div>
            <div className="modal__btnset">
                <div className="modal__wrapper-btn">
                    <ButtonPrimary
                        type={'link'}
                        title="Пополнить баланс"
                        href="https://dom.ru/lk"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                    />
                </div>
                <div className="modal__wrapper-btn">
                    <ButtonClear title={'Закрыть'} onClick={closeModal} />
                </div>
            </div>
        </ModalForm>
    );
}
