import { useState, useEffect } from 'react';
import { getSubscriptions } from '@/src/helpers/DataHelper';
import { useApiStore } from '@/src/stores/RootStoreProvider';

export default function useSubscriptions(isPurchasedFilter = false, isNotAdultFilter = false, fetch = true) {
    const { api } = useApiStore();
    const [subscriptions, setSubscriptions] = useState(undefined);

    useEffect(() => {
        if (!api) {
            return;
        }
        if (fetch) {
            async function fetchData() {
                try {
                    setSubscriptions(await getSubscriptions(api, isPurchasedFilter, isNotAdultFilter));
                } catch (e) {
                    // Nothing
                }
            }
            // noinspection JSIgnoredPromiseFromCall
            fetchData();
        }
    }, [api, isPurchasedFilter, isNotAdultFilter, fetch]);

    return subscriptions;
}
