import Icon from '@/components/icon/Icon';
import styles from './Label.module.scss';

export default function Label({ icon, modifier }) {
    return (
        <div className={styles['label'] + (modifier ? ' ' + modifier : '')}>
            <Icon id={icon} modifier={'label__icon'} />
        </div>
    );
}
