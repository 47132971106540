import { GOOGLE_ANALYTICS_ID } from '@/src/constants/statistics';
import ApiToken from '@/src/models/ApiToken';
import { getDeviceTypeByUserAgent, getTimezone } from '@/src/modules/Common';

const isGTMActive = process.env.NEXT_PUBLIC_PRODUCTION_MODE && GOOGLE_ANALYTICS_ID;

export const LAYERS = {
    MODAL_PIN_CODE: 'ModalPinCode',
    PLAYER_VOD: 'PlayerVod',
};

const PLAYER_EVENTS = {
    LIVE: 'playerLive',
    VOD: 'playerVod',
};

export const initGTM = (isGuest, token) => {
    if (!isGTMActive) {
        return;
    }
    let params;
    if (isGuest) {
        params = {
            user_id: 'guest',
        };
    } else {
        const apiToken = new ApiToken(token);
        params = {
            device_id: apiToken.getDeviceId(),
            user_id: apiToken.getContractNumber(),
            subscriber_id: apiToken.getSubscriberId(),
            domain: apiToken.getRegionId(),
        };
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'userIdEvent',
        event_timestamp: Date.now(),
        time_zone: getTimezone(),
        device_type: getDeviceTypeByUserAgent(),
        ...params,
    });
};

export const eventPush = (event) => {
    console.log('event', event);
    if (isGTMActive) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);
    }
};

export const gtmEvent = (event, eventSubtype, params = {}) => {
    eventPush({
        event: event,
        event_subtype: eventSubtype,
        ...params,
    });
};

/**
 * @param {String} eventSubtype
 * @param {?String} layer
 */
export const clickButtonEvent = (eventSubtype, layer = null) => {
    gtmEvent('clickButton', eventSubtype, layer ? { layer: layer } : {});
};

/**
 * @param {String} eventSubtype
 */
export const playerVodEvent = (eventSubtype) => {
    gtmEvent(PLAYER_EVENTS.VOD, eventSubtype);
};

/**
 * @param {String} eventSubtype
 */
export const playerLiveEvent = (eventSubtype) => {
    gtmEvent(PLAYER_EVENTS.LIVE, eventSubtype);
};

export const modalViewEvent = (eventSubtype, layer = null) => {
    gtmEvent('modalView', eventSubtype, layer ? { layer: layer } : {});
};

/**
 * @param {Boolean} isTv
 * @param {String} eventSubtype
 */
export const playerEvent = (isTv, eventSubtype) => {
    gtmEvent(isTv ? PLAYER_EVENTS.LIVE : PLAYER_EVENTS.VOD, eventSubtype);
};
