import Offer from '@/src/api/v3/models/Offer';

/**
 * Trait Offers
 */

export default class Offers {
    /** @type {[Offer]} */
    offers = [];
    /** @type {Offer} */
    offer;

    /**
     * Загрузить офферы
     * @param {Object} object
     */
    constructor(object) {
        if (object?.offer) {
            this.offer = new Offer(object.offer);
        }
        if (object?.offers?.showcases && Array.isArray(object.offers.showcases)) {
            object.offers.showcases.forEach((showcaseObject) => {
                showcaseObject.items.forEach((offerObject) => {
                    this.offers.push(new Offer(offerObject));
                });
            });
        }
        if (!this.offer && this.offers.length > 0) {
            this.offer = this.offers[0];
            /*
            this.offers.forEach((offer) => {
                if (offer.subscription) {
                    this.offer = offer;
                }
            });
             */
        }
    }

    /**
     * Бесплатный ли оффер
     * @returns {boolean}
     */
    isFree() {
        const price = this.offer?.price;
        return price === 0 && !this.isTNBActivated();
    }

    /**
     * Получить скидку первого предложения
     * @returns {?number}
     */
    getDiscount() {
        return this.offer?.stock?.discount;
    }

    /**
     * Try and Buy доступен
     * @returns {boolean}
     */
    isTNBAvailable() {
        return this.offer?.stock?.isTNBAvailable();
    }

    /**
     * Try and Buy активирован
     * @returns {boolean}
     */
    isTNBActivated() {
        return this.offer?.stock?.isTNBActivated();
    }

    /**
     * Получить подписку первого предложения
     * @returns {?Subscription}
     */
    getSubscription() {
        return this.offer?.subscription;
    }

    /**
     * Получить поставщика первого предложения
     * @returns {?string}
     */
    getProvider() {
        return this.offer?.provider;
    }

    /**
     * Получить дату покупки
     * @returns {?Date}
     */
    getPurchaseDate() {
        return this.offer?.purchaseAt;
    }

    /**
     * Получить дату окончания действия
     * @returns {?Date}
     */
    getExpirationDate() {
        return this.offer?.expireAt;
    }

    /**
     * Returns cheapest offer from offers array.
     * @returns Offer|null
     */
    getCheapestOffer() {
        let cheapestOffer = null;
        this.offers.forEach((offer) => {
            if (!cheapestOffer || offer.price < cheapestOffer.price) {
                cheapestOffer = offer;
            }
        });
        return cheapestOffer;
    }
}
