import ModalForm from '../ModalForm';
import CreditCard from '@/components/credit-card/CreditCard';
import useCardPayments from '@/src/hooks/useCardPayments';
import { plural } from '@/src/helpers/GrammarHelper';

/**
 * @param {Offer} offer
 * @param {Number} id
 * @param {Number} purchaseType
 * @param {PaymentCard} card
 * @param {Object} params
 * @param {Object} eventParams
 */
export default function ModalPurchaseLinkedCard({ offer, id, purchaseType, card, params, eventParams }) {
    const { purchaseByLinkedCard } = useCardPayments();

    const onPay = async (cvv) => {
        await purchaseByLinkedCard({ offer, id, purchaseType, card, cvv, params, eventParams });
    };

    const price = params['use_tnb'] ? offer.stock?.priceWithDiscount : offer.price;

    return (
        <ModalForm
            className={'modal--primary modal--process-buy-big'}
            closeButton={true}
            pageUrl={'#card-pay'}
            pageTitle={'Оплата с банковской карты'}
        >
            <div className="modal__content-title">Оплата с банковской карты</div>
            <div className="modal__content-subtitle">
                Сумма платежа {price} {plural(price, 'рубль', 'рубля', 'рублей')}
            </div>
            <CreditCard card={card} offer={offer} params={params} onPay={onPay} />
        </ModalForm>
    );
}
