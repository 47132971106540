import BaseModel from '@/src/api/v3/models/BaseModel';
import { ShowcaseItemType } from '@/src/constants/types';

/**
 *  Жанр
 */
export default class Genre extends BaseModel {
    SLIDER_STUB_CLASS = 'cover--compilations';
    URL_PREFIX = 'genres';
    EVENT_ITEM_CATEGORY = ShowcaseItemType.GENRE;

    images = ['3_smarttv_genre_poster_video_library_blueprint'];
    backgroundModifier = 'backbg--package';

    /**
     * Получить изборажение для фона
     * @returns null|string
     */
    getBackgroundSrc() {
        return this.resources.getResourceUrl('3_smarttv_genre_background_video_library_blueprint');
    }
}
