import { QUERY_POST } from '@/src/api/Api';
import { ApiMixin } from '@/src/api/ApiMixin';
import WatchEverywhereStatus from '@/src/api/v2/models/WatchEverywhereStatus';

/**
 * Trait WatchEverywhere
 * @package application\components\api
 * @see https://kblion.ertelecom.ru/display/BA/Everywhere+watch
 */

export class WatchEverywhere extends ApiMixin {
    /**
     * Get WatchEverywhere status
     * @returns {Promise<?WatchEverywhereStatus>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getWatchEverywhereStatus = async () => {
        const response = await this.api.v2({
            uri: '/er/watch_everywhere/status',
        });
        const services = response['status'];
        const activeService = services.find((service) => service.status === 1);
        return activeService ? new WatchEverywhereStatus(activeService) : null;
    };

    /**
     * Get WatchEverywhere tariff
     * @returns {Promise<WatchEverywhereStatus[]>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getWatchEverywhereTariffs = async () => {
        const response = await this.api.v2({
            uri: '/er/watch_everywhere/status',
        });
        const services = response['status'];
        return services.map((service) => new WatchEverywhereStatus(service));
    };

    /**
     * Activate service
     * @param serviceId {number} Everywhere watch service identification (from request GetStatusList)
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    activateWatchEverywhere = async (serviceId) => {
        await this.api.v2({
            uri: '/er/watch_everywhere/activate',
            params: { service_id: serviceId },
            method: QUERY_POST,
        });
    };

    /**
     * Deactivate service
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    deactivateWatchEverywhere = async () => {
        await this.api.v2({
            uri: '/er/watch_everywhere/deactivate',
            method: QUERY_POST,
        });
    };
}
