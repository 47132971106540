import { ShowcaseItemType } from '@/src/constants/types';
import BaseModel from '@/src/api/v3/models/BaseModel';
import Movie from '@/src/api/v3/models/Movie';
import Person from '@/src/api/v3/models/Person';
import Schedule from '@/src/api/v3/models/Schedule';
import ChannelPackage from '@/src/api/v3/models/ChannelPackage';
import Season from '@/src/api/v3/models/Season';
import Episode from '@/src/api/v3/models/Episode';
import Channel from '@/src/api/v3/models/Channel';
import Compilation from '@/src/api/v3/models/Compilation';
import TvShow from '@/src/api/v3/models/TvShow';
import Genre from '@/src/api/v3/models/Genre';
import SuggestAutocomplete from '@/src/api/v3/models/SuggestAutocomplete';
import Banner from '@/src/api/v3/models/Banner';
import Subscription from '@/src/api/v3/models/Subscription';
import Series from '@/src/api/v3/models/Series';
import Bundle from '@/src/api/v3/models/Bundle';

/**
 * @param {Object} item
 * @returns {BaseModel}
 */
export default function getModelByObject(item) {
    let model;
    switch (item.type) {
        case ShowcaseItemType.MOVIE:
            model = new Movie(item);
            break;
        case ShowcaseItemType.PERSON:
            model = new Person(item);
            break;
        case ShowcaseItemType.SERIAL:
            model = new Series(item);
            break;
        case ShowcaseItemType.SCHEDULE:
            model = new Schedule(item);
            break;
        case ShowcaseItemType.CHANNEL_PACKAGE:
            model = new ChannelPackage(item);
            break;
        case ShowcaseItemType.SUBSCRIPTION:
            model = new Subscription(item);
            break;
        case ShowcaseItemType.SEASON:
            model = new Season(item);
            break;
        case ShowcaseItemType.EPISODE:
            model = new Episode(item);
            break;
        case ShowcaseItemType.CHANNEL:
            model = new Channel(item);
            break;
        case ShowcaseItemType.PACKAGE:
        case ShowcaseItemType.COLLECTION:
            model = new Compilation(item);
            break;
        case ShowcaseItemType.TV_SHOW:
            model = new TvShow(item);
            break;
        case ShowcaseItemType.GENRE:
            model = new Genre(item);
            break;
        case ShowcaseItemType.SUGGEST_RICH:
            model = getModelByObject(item.data);
            break;
        case ShowcaseItemType.SUGGEST_AUTOCOMPLETE:
            model = new SuggestAutocomplete(item);
            break;
        case ShowcaseItemType.BANNER:
            model = new Banner(item);
            break;
        case ShowcaseItemType.BUNDLE:
            model = new Bundle(item);
            break;
        default:
            model = new BaseModel(item);
    }
    return model;
}
