/**
 * Модель статуса родительского контроля
 */
export default class ParentalControlStatus {
    /** @type array */
    channels = [];
    /** @type ?Object */
    state;
    /** @type ?number */
    level;

    /**
     * Region constructor.
     * @param object json
     */
    constructor(object) {
        this.channels = object.channels || [];
        this.state = object.state;
        this.level = object.level;
    }
}
