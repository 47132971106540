import useNotifications from '@/src/hooks/useNotifications';
import { PaymentStatus } from '@/src/constants/types';
import Common from '@/src/modules/Common';
import useModals from '@/src/hooks/useModals';
import ModalPurchaseContinuation from '@/components/modal/purchases/ModalPurchaseContinuation';
import ModalPurchaseLinkedCard from '@/components/modal/purchases/ModalPurchaseLinkedCard';
import { PurchaseType } from '@/src/constants/purchase-type';
import apiCache from '@/src/api/ApiCache';
import { formPost } from '@/src/helpers/UrlHelper';
import ModalNotEnoughMoney from '@/components/modal/purchases/ModalNotEnoughMoney';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import { isObjectEmpty } from '@/src/helpers/MiscHelper';
import { Subscriptions } from '@/src/constants/subscriptions';
import { gtmEvent } from '@/src/modules/events/GTM';
import { PaymentType } from '@/src/constants/payment-type';

const PAYMENTS_STORAGE_KEY = 'payments';

const getPayments = () => {
    return JSON.parse(localStorage.getItem(PAYMENTS_STORAGE_KEY)) || [];
};

const savePayments = (payments) => {
    localStorage.setItem(PAYMENTS_STORAGE_KEY, JSON.stringify(payments));
};

const addPaymentToStorage = (data) => {
    const payments = getPayments();
    if (!payments.find((elem) => elem.order_number === data.order_number)) {
        payments.push(data);
        savePayments(payments);
    }
};

const deletePaymentFromStorage = (data) => {
    const payments = getPayments();
    const newPayments = payments.filter((elem) => elem.order_number !== data.order_number);
    savePayments(newPayments);
};

const getPaymentData = ({ offer, id, purchaseType, params = {}, eventParams = {} }) => {
    return {
        purchase_type: purchaseType,
        ...(id ? { asset_id: id } : {}),
        ...(offer.id ? { offer_id: offer.id } : {}),
        ...(!isObjectEmpty(params) ? { params: JSON.stringify(params) } : {}),
        ...(!isObjectEmpty(eventParams) ? { event_params: JSON.stringify(eventParams) } : {}),
    };
};

export default function useCardPayments() {
    const { api } = useApiStore();
    const { showError, showSuccess } = useNotifications();
    const { openModal, closeModal } = useModals();

    /**
     * Payment By Linked Card
     * @param {Offer} offer
     * @param {Number} [id]
     * @param {Number} purchaseType
     * @param {PaymentCard} card
     * @param {string} cvv
     * @param {Object} params
     * @param {Object} eventParams
     * @returns {void}
     */
    const purchaseByLinkedCard = async ({ offer, id, purchaseType, card, cvv, params = {}, eventParams = {} }) => {
        const price = params['use_tnb'] ? offer.stock?.priceWithDiscount : offer.price;
        const data = getPaymentData({ offer, id, purchaseType, params, eventParams });
        const returnUrl = new URL(window.location.href);
        returnUrl.search = new URLSearchParams(data);
        const response = await api.payment.payByLinkedCard(
            card.bindingId,
            cvv,
            price * 100,
            returnUrl.toString() + '&'
        );
        // TODO: Бэкенд на платежи с ошибкой возвращает pay_status = 2
        const jsonData = JSON.parse(response.json_data);
        if (jsonData.error) {
            throw new Error(response.action_code_descr);
        }
        if (response.acsUrl) {
            formPost(response.acsUrl, {
                MD: response.orderId,
                PaReq: response.paReq,
                TermUrl: response.termUrl,
            });
        }
        if (response.pay_status === PaymentStatus.SUCCESS || response.pay_status === PaymentStatus.IN_PROGRESS) {
            data.order_number = response.billing_transaction_id;
            data.params = params;
            await continuePayment(data);
        } else {
            throw new Error('Ошибка при оплате с карты');
        }
    };

    /**
     * Payment By Card
     * @param {Offer} offer
     * @param {Number} [id]
     * @param {Number} purchaseType
     * @param {Object} params
     * @param {Object} eventParams
     * @returns {void}
     */
    const purchaseByCard = async ({ offer, id, purchaseType, params = {}, eventParams = {} }) => {
        const cards = await api.payment.getPaymentCards();
        if (cards.length) {
            const card = cards[0];
            openModal(
                <ModalPurchaseLinkedCard
                    offer={offer}
                    id={id}
                    purchaseType={purchaseType}
                    card={card}
                    params={params}
                    eventParams={eventParams}
                />
            );
        } else {
            const data = getPaymentData({ offer, id, purchaseType, params, eventParams });
            const price = params['use_tnb'] ? offer.stock?.priceWithDiscount : offer.price;
            const returnUrl = new URL(window.location.href);
            returnUrl.search = new URLSearchParams(data);
            const response = await api.payment.addCardWithPayment(price * 100, returnUrl.href + '&');
            window.location.assign(response.pay_url);
        }
    };

    const continuePayment = async (payment) => {
        Common.clearUrl();
        openModal(<ModalPurchaseContinuation />);
        try {
            const status = await api.payment.payCard(
                payment.order_number,
                payment.purchase_type,
                payment.asset_id,
                payment.offer_id,
                payment.params
            );
            if (status === PaymentStatus.SUCCESS) {
                showSuccessMessage(payment.purchase_type, payment.asset_id);
                if (payment.event_params) {
                    gtmEvent('purchase', 'checkout_end', {
                        ...payment.event_params,
                        payment_method: PaymentType.CARD,
                    });
                }
                clearCaches(payment.purchase_type);
                window.location.reload();
            } else {
                addPaymentToStorage(payment);
                setTimeout(() => {
                    checkCardPayments(10 * 1000, true);
                }, 5000);
            }
        } catch (error) {
            closeModal();
            if (['AGREEMENT_NOT_ACTIVE', 'INSUFFICIENT_CREDIT_BALANCE'].includes(error.code || '')) {
                openModal(<ModalNotEnoughMoney error={error} gaEventType={payment.purchase_type} />);
            } else {
                showError(error.message || 'Ошибка при завершении платежа');
            }
        }
    };

    /**
     * @param {Number} purchaseType
     * @param {Number} assetId
     */
    const showSuccessMessage = (purchaseType, assetId) => {
        let message;
        let subMessage;
        switch (purchaseType) {
            case PurchaseType.ASSET:
                message = 'Теперь фильм доступен для просмотра';
                break;
            case PurchaseType.SUBSCRIPTION:
            case PurchaseType.BUNDLE:
                message = 'Теперь фильмы из подписки доступны для просмотра';
                if ([Subscriptions.KINOPOISK, Subscriptions.MOVIX_ONE].includes(assetId)) {
                    subMessage = 'Для продолжения нажмите на кнопку «Активировать подписку Плюс».';
                }
                break;
            case PurchaseType.CHANNEL_PACKAGE:
                message = 'Теперь каналы из пакета доступны для просмотра';
                break;
            case PurchaseType.CATCHUP:
            case PurchaseType.WATCH_EVERYWHERE:
                message = 'Теперь услуга доступна для использования';
                break;
            default:
                message = 'Теперь контент доступен';
        }
        showSuccess({ title: 'Покупка оплачена', message: message, subMessage: subMessage }, true);
    };

    const checkCardPayments = async (timeout = 5 * 60 * 1000, reloadWindowAfterSuccess = false) => {
        const payments = getPayments();
        for (const payment of payments) {
            try {
                const status = await api.payment.getPayStatus(payment.order_number);
                if (status === PaymentStatus.SUCCESS) {
                    deletePaymentFromStorage(payment);
                    showSuccessMessage(payment.purchase_type, payment.asset_id);
                    if (payment.event_params) {
                        gtmEvent('purchase', 'checkout_end', {
                            ...payment.event_params,
                            payment_method: PaymentType.CARD,
                        });
                    }
                    clearCaches(payment.purchase_type);
                    if (reloadWindowAfterSuccess) {
                        window.location.reload();
                    }
                }
            } catch (e) {
                deletePaymentFromStorage(payment);
                showError(e.message || 'Ошибка при оплате');
            }
        }
        if (payments.length > 0) {
            setTimeout(() => {
                checkCardPayments(timeout, reloadWindowAfterSuccess);
            }, timeout);
        }
    };

    const initCardPayments = async () => {
        const orderNumber = Common.getURLParameter('order_number');
        if (orderNumber) {
            const params = Common.getURLParameter('params');
            const eventParams = Common.getURLParameter('event_params');
            const data = {
                order_number: orderNumber,
                purchase_type: Number(Common.getURLParameter('purchase_type')),
                asset_id: Number(Common.getURLParameter('asset_id')),
                offer_id: Number(Common.getURLParameter('offer_id')),
                params: params ? JSON.parse(params) : {},
                event_params: eventParams ? JSON.parse(eventParams) : {},
            };
            await continuePayment(data);
        } else {
            await checkCardPayments();
        }
    };

    const clearCaches = (purchaseType) => {
        switch (purchaseType) {
            case PurchaseType.SUBSCRIPTION:
            case PurchaseType.CHANNEL_PACKAGE:
                apiCache.removeSubscriptionCaches();
                break;
            case PurchaseType.CATCHUP:
                apiCache.removeCatchupCaches();
                break;
            case PurchaseType.WATCH_EVERYWHERE:
                apiCache.removeWatchEverywhereCaches();
                break;
            case PurchaseType.BUNDLE:
                apiCache.removeBundleCaches();
                break;
            default:
            // Nothing
        }
    };

    return {
        checkCardPayments,
        purchaseByCard,
        purchaseByLinkedCard,
        initCardPayments,
    };
}
