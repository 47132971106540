import { QUERY_POST } from '@/src/api/Api';
import { ApiMixin } from '@/src/api/ApiMixin';

export class ViewStatus extends ApiMixin {
    /** Save viewed mark
     * @param {number} id
     * @param {string} type
     * @param {number} duration
     * @param {number} position
     * @returns {Promise<Object>}
     */
    setViewStatus = async (id, type, duration, position) => {
        return this.api.v3({
            uri: '/api/v3/view-status',
            params: {
                id: id,
                type: type,
                duration: duration,
                position: position,
            },
            method: QUERY_POST,
        });
    };
}
