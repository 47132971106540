import { QUERY_POST } from '@/src/api/Api';
import CatchupStatus from '@/src/api/v2/models/CatchupStatus';
import { ApiMixin } from '@/src/api/ApiMixin';

/**
 * Catchup
 * @see https://kblion.ertelecom.ru/display/BA/Trans+12.+Catchup
 */

export class Catchup extends ApiMixin {
    /**
     * Receive the status
     * @returns {Promise<CatchupStatus>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getCatchupStatus = async () => {
        let response = await this.api.v2({
            uri: '/er/catchup/status',
        });
        return new CatchupStatus(response['status']);
    };

    /**
     * Возращает ссылку на воспроизведение потока
     * @param {number} catchupId
     * @param {number} useExactTime
     * @returns {Promise<string>}
     * @throws {ApiBackendException}
     * @throws ApiNetworkException
     */
    getCatchupStream = async (catchupId, useExactTime = 0) => {
        let response = await this.api.v2({
            uri: '/catchup/stream',
            params: {
                epg_schedule_item_id: catchupId,
                use_exact_time: useExactTime,
            },
        });
        return response['url'];
    };

    /**
     * Connect the service
     * @param {Boolean} isTNB
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    activateCatchup = async (isTNB = false) => {
        await this.api.v2({
            uri: '/er/catchup/activate',
            params: {
                use_tnb: isTNB ? 1 : 0,
            },
            method: QUERY_POST,
        });
    };

    /**
     * Disconnect the service
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    deactivateCatchup = async () => {
        await this.api.v2({
            uri: '/er/catchup/deactivate',
            method: QUERY_POST,
        });
    };
}
