import { getPeriodText } from '@/src/helpers/FormatHelper';

export const StockTypes = {
    DISCOUNT: 'discount',
    PRE_ORDER: 'pre-order',
    TRY_AND_BUY: 'try-and-buy',
};

const StockStatuses = {
    USED: 'used',
    NOT_USED: 'not-used',
};

export const STOCK_NOTICE = 'Мы сообщим за 3 дня до окончания пробного периода.';

/**
 *  Акция
 */
export default class Stock {
    /** Скидка
     *  @type {number} */
    discount;
    /** Цена без скидки
     *  @type {number} */
    originalPrice;
    /** Цена со скидкой
     *  @type {number} */
    priceWithDiscount;
    /** @type {?string} */
    type = null;
    /** @type {?string} */
    status = null;
    /** Дата активации предложения
     *  @type {?Date} */
    startAt;
    /** Дата истечения предложения
     * @type {?Date} */
    endAt;
    /** Период
     *  @type {?number} */
    period = null;
    /** Тип перилжа
     *  @type {?string} */
    periodType;

    /**
     * Offer constructor.
     * @param {Object} object
     */
    constructor(object) {
        this.discount = object.discount?.value;
        this.originalPrice = object['oldPrice'];
        this.priceWithDiscount = object.discount ? object.discount.priceWithDiscount : object.price;
        this.type = object.type;
        this.status = object.status?.value;
        this.startAt = object.startAt ? new Date(object.startAt) : null;
        this.endAt = object.endAt ? new Date(object.endAt) : null;
        if (object.type === StockTypes.TRY_AND_BUY) {
            this.period = object.period?.value ?? object.period?.duration;
            this.periodType = object.period?.type;
        }
    }

    /**
     * Возвращает true если акция - Try&Buy
     * @returns {Boolean}
     */
    isTNB() {
        return this.type === StockTypes.TRY_AND_BUY;
    }

    /**
     * Try and Buy доступен
     * @returns {boolean}
     */
    isTNBAvailable() {
        return this.type === StockTypes.TRY_AND_BUY && this.status === StockStatuses.NOT_USED;
    }

    /**
     * Try and Buy активирован
     * @returns boolean
     */
    isTNBActivated() {
        if (this.type === StockTypes.TRY_AND_BUY) {
            return this.status === StockStatuses.USED;
        }
        return false;
    }

    getPeriodText() {
        return getPeriodText(this.period, this.periodType);
    }

    /**
     * @returns {string}
     */
    getTitle(isMiddleText = false) {
        const lowerCaseFirstLetter = (string) => {
            return string.charAt(0).toLowerCase() + string.slice(1);
        };
        let title = '';
        if (this.isTNB()) {
            title = `Попробуйте ${this.getPeriodText()}`;
        }
        if (title && isMiddleText) {
            title = ' ' + lowerCaseFirstLetter(title);
        }
        return title;
    }
}
