import { useEffect, useContext } from 'react';
import styles from './layout.module.scss';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import AppContext from '@/src/contexts/app/state/AppContext';
import dynamic from 'next/dynamic';
const Notifications = dynamic(() => import('@/components/notification/Notifications', { ssr: false }));

export default function Layout({ children, isStaticWrapper, isSidebar, isGame }) {
    const {
        modal: { current: modal },
    } = useContext(AppContext);

    useEffect(() => {
        if (!modal.isOpen && modal.scrollPos) {
            document.documentElement.scrollTop = modal.scrollPos;
        }
    }, [modal.isOpen, modal.scrollPos]);

    return (
        <>
            <Header marginRight={modal.isOpen ? modal.scrollbarWidth : 0} />
            <div
                className={
                    styles['wrapper--main'] +
                    (isStaticWrapper ? ' wrapper--static' : '') +
                    (isSidebar ? ' wrapper--sidebar' : '') +
                    (isGame ? ' wrapper--game' : '') +
                    (modal.isOpen ? ' wrapper--overlay' : '')
                }
                style={{
                    marginRight: modal.isOpen ? modal.scrollbarWidth + 'px' : 0,
                }}
            >
                <main id="content" className={'wrapper__right-content'} role="main">
                    {children}
                    <Footer />
                </main>
                {modal.isOpen ? modal.body : ''}
                <Notifications />
            </div>
            <script defer src="/assets/libs/nprogress.js" />
        </>
    );
}
