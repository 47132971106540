import Common from '@/src/modules/Common';
import Cookies from 'js-cookie';
import { COOKIES } from '@/src/constants/cookies';

/**
 * @param {Api} api
 */
export async function afterLogin(api) {
    sessionStorage.clear();

    let errorMessage = '';
    const status = await api.multiscreen.getMultiscreenStatus();
    if (!status.state) {
        try {
            await api.multiscreen.activateMultiscreen();
        } catch (e) {
            errorMessage = 'Не удалось активировать услугу мультискрин';
        }
    }
    const watchEverywhereStatus = await api.watchEverywhere.getWatchEverywhereStatus();
    if (!status.isBound && watchEverywhereStatus?.freeSlotCount > 0) {
        try {
            await api.multiscreen.bindMultiscreenDevice();
        } catch (e) {
            errorMessage = 'Не удалось добавить устройство';
        }
    }
    return errorMessage;
}

/**
 * @param {Api} api
 * @param {string} username
 * @param {string} password
 * @param {string} region
 */
export async function loginByPassword(api, username, password, region) {
    const SSOKey = await api.authorization.getSSOKey(username, password, region);
    const newToken = await api.authorization.getTokenBySSOKey(SSOKey);
    api.setToken(newToken);
    return newToken;
}

/**
 * @param {Api} api
 * @param {string} phone
 * @param {string} region
 * @param {number} agreementId
 * @param {string} code
 */
export async function loginBySMS(api, phone, region, agreementId, code) {
    let response = await api.authorization.checkAuthSMS(phone, region, agreementId, code);
    if (!response.token) {
        let error =
            response.Agreements.sms_error_text ||
            response.Agreements.sms_error_code ||
            response.agreements.sms_error_text ||
            response.agreements.sms_error_code ||
            null;
        if (error === 'Ошибка авторизации: Превышено допустимое кол-во ошибок авторизации') {
            error =
                'Авторизоваться не удалось. Превышено допустимое количество ошибок авторизации. ' +
                'Попробуйте повторить авторизацию через 1 час.';
        }
        throw new Error(error);
    }
    api.setToken(response.token);
}

export const findRegionByAccount = (regions, account) => {
    if (!regions) {
        return null;
    }

    const findByCode2Num = (value) => {
        let items = [];
        for (let region of regions) {
            const code = Math.floor(parseInt(region.code) / 10);
            if (value === code) {
                items.push(region);
            }
        }
        let sorted = items.slice(0);
        sorted.sort(function (a, b) {
            return b.weight - a.weight;
        });

        return sorted.length ? sorted : null;
    };

    const findByCode3Num = (value) => {
        let items = [];
        for (let region of regions) {
            if (value === region.code) {
                items.push(region);
            }
        }
        let sorted = items.slice(0);
        sorted.sort(function (a, b) {
            return b.weight - a.weight;
        });
        return sorted.length ? sorted[0] : null;
    };

    let region = null;
    account = account ? account.trim() : '';
    if (/^\d{3,}$/.test(account)) {
        region = findByCode2Num(parseInt(account.slice(0, 2), 10));
        if (!region) {
            return null;
        }
        let region3Num;
        if (region.length > 1) {
            region3Num = findByCode3Num(parseInt(account.slice(0, 3), 10));
        }
        region = region3Num || region[0];
    }
    return region;
};

export function logout() {
    Cookies.remove(COOKIES.ACCESS_TOKEN);
    Cookies.remove(COOKIES.UID);
    Cookies.remove(COOKIES.REGION);
    Cookies.remove(COOKIES.API_TOKEN);
    if (typeof window !== 'undefined') {
        Common.pageRedirect('/');
    }
}
