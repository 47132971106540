import ModalForm from '../ModalForm';
import Loader from '@/components/modal/helpers/Loader';

export default function ModalPurchaseContinuation() {
    return (
        <ModalForm
            className={'modal--primary'}
            closeButton={false}
            pageUrl={''}
            pageTitle={'Завершение покупки'}
            notHide={true}
        >
            <div className="modal__content-title">Выполняется покупка</div>
            <div className="modal__content-subtitle">Пожалуйста, подождите. Выполняется завершение покупки</div>
            <Loader />
        </ModalForm>
    );
}
