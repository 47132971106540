import Button from '@/components/button/Button';
import styles from './BannerTv.module.scss';

export default function BannerTv({ modifier, title, description, purchaseButton = null, infoButton = {} }) {
    return (
        <div className={styles['banner-tv'] + (modifier ? ' ' + modifier : '')}>
            <div className="banner-tv__background"></div>
            <div className="banner-tv__wrap">
                <div className="banner-tv__title">{title}</div>
                <div className="banner-tv__description">{description}</div>
                <div className="banner-tv__action">
                    {purchaseButton && (
                        <Button
                            title={purchaseButton.title}
                            className="button--pro button--large"
                            href={purchaseButton.href}
                            target="_blank"
                            rel="noreferrer"
                            type="link"
                        />
                    )}
                    <Button
                        title={infoButton.title}
                        className={'button--secondary' + (purchaseButton ? ' button--large' : '')}
                        href={infoButton.href}
                        target="_blank"
                        rel="noreferrer"
                        type="link"
                    />
                </div>
            </div>
        </div>
    );
}
