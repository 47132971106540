import { URN_TO_URL_ALIASES } from '@/src/constants/aliases.config';
import Showcase from '@/src/api/v3/models/Showcase';
import { ShowcaseItemType } from '@/src/constants/types';

const getFilterUrn = (params) => {
    return decodeURIComponent(new URLSearchParams(params).toString());
};

/**
 * Выполнить поиск по витрине
 * @returns {Promise<Showcase>}
 */
export async function getShowcaseItems(api, { types, title, sort, genre, country }) {
    const params = getFilterParams({ types, title, sort, genre, country });
    const showcase = await api.showcases.loadShowcase({
        urn: 'showcase-items',
        params: params,
        offset: null,
        limit: 24,
    });
    showcase.urn = 'showcase-items';
    showcase.params = params;
    showcase.showHeader = false;
    return showcase;
}

/**
 * @returns {Promise<Object>}
 */
export async function getShowcaseData(api, { types, title, sort, genre, country }) {
    const params = getFilterParams({ types, title, sort, genre, country });
    return api.pages.loadPageData({ urn: 'showcase-items', params: params, limit: 24 });
}

export const getShowcaseByData = (data) => {
    if (!data.showcaseData) {
        return null;
    }
    const showcase = new Showcase(data.showcaseData);
    showcase.urn = 'showcase-items';
    showcase.params = getFilterParams({
        types: ShowcaseItemType.MOVIE,
        sort: data.sort,
        genre: data.genres,
        country: data.countries,
    });
    showcase.showHeader = false;
    return showcase;
};

export const getFilterParams = ({ types, title, sort, genre, country }) => {
    return getFilterUrn({
        types: types,
        sortBy: sort || 'WEIGHT',
        sortDirection: 'DESC',
        ...(title ? { title: title } : {}),
        ...(genre ? { propertyGenreIds: genre } : {}),
        ...(country ? { propertyCountryIds: country } : {}),
    });
};

export function getShowcaseUrl(urn) {
    const aliasUrl = URN_TO_URL_ALIASES[urn];
    return '/' + (aliasUrl || urn);
}
