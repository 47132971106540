/**
 * @param {string} url
 * @returns {string}
 */
export function secureURL(url) {
    // noinspection HttpUrlsUsage
    return url.startsWith('http://') ? 'https://' + url.slice(7) : url;
}

export function replaceQuery(query) {
    const url = new URL(window.location.href);
    url.search = new URLSearchParams(query);
    window.history.replaceState(
        { ...window.history.state, as: url.toString(), url: url.toString() },
        '',
        url.toString()
    );
}

export function replacePathname(pathname) {
    const url = new URL(window.location.href);
    url.pathname = pathname;
    url.search = '';
    window.history.replaceState({ ...window.history.state, as: pathname, url: url.toString() }, '', url.toString());
}

export function formPost(url, params) {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = params[key];
            form.appendChild(hiddenField);
        }
    }
    document.body.appendChild(form);
    form.submit();
}
