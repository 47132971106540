class BonusBalanceDetail {
    /** @type {Date} */
    expireDate;
    /** @type {Number} */
    balance;

    /**
     * BonusBalanceDetail constructor.
     * @param {Object} data
     * @param {Number} data.expires
     * @param {Number} data.balance
     */
    constructor(data) {
        this.balance = data.balance;
        this.expireDate = new Date(data.expires * 1000);
    }
}

export default class BonusBalance {
    /** @type {Number} */
    total;
    /** @type {BonusBalanceDetail[]} */
    details = [];

    /**
     * BonusBalance constructor.
     * @param {Object} data
     * @param {Number} data.total
     * @param {Object} data.details
     */
    constructor(data) {
        this.total = data.total;
        (data.details || []).forEach((detail) => {
            this.details.push(new BonusBalanceDetail(detail));
        });
    }
}
