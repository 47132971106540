import styles from './CardNav.module.scss';
import Link from 'next/link';
import Icon from '@/components/icon/Icon';
import { isObjectEmpty } from '@/src/helpers/MiscHelper';
import { gtmEvent } from '@/src/modules/events/GTM';

export default function CardNav({ href, title, imgSrc, needLabel = false, offerText = null, eventParams = {} }) {
    const onClick = () => {
        if (!isObjectEmpty(eventParams)) {
            gtmEvent('menu', 'item_click', eventParams);
        }
    };
    return (
        <Link
            className={styles['card-nav']}
            href={href}
            title={title}
            rel={'nofollow noopener noreferrer'}
            target={needLabel ? '_blank' : ''}
            onClick={onClick}
        >
            {imgSrc && <img className="card-nav__image" src={imgSrc} alt={title} loading={'lazy'} />}
            {!imgSrc && (
                <div className="card-nav__text">
                    Все
                    <Icon id="arrow-right" modifier="icon--x-small card-nav__icon" />
                </div>
            )}
            {needLabel && (
                <div className="card-nav__label">
                    <div className="label label--small">
                        <Icon id="subtract" modifier="label__icon" />
                    </div>
                </div>
            )}
            {!!offerText && (
                <div className="card-nav__gift">
                    <span>{offerText}</span>
                    <Icon id={'gift'} modifier="card-nav__gift-icon" />
                </div>
            )}
        </Link>
    );
}
