import Common, { throttle } from '@/src/modules/Common';

// TODO: Переписать
export default class Recommendations {
    static setLastBrowseItem = function (params) {
        if (params.type === 'episode') {
            return;
        }
        localStorage.setItem(LAST_BROWSE_STORAGE_NAME, JSON.stringify(params));
    };

    static setEvent(type, params) {
        try {
            params = { ...params };
            if (type === TYPES.BlockAsset) {
                let channelIds = params.end.filter((value) => -1 === params.start.indexOf(value));
                for (let channelId of channelIds) {
                    let item = new RecItem({
                        ...params,
                        assetId: channelId,
                        itemType: 'channel',
                        //TODO: usecase not defined for event
                        usecaseId: 'profile',
                    });
                    item.save();
                }
            } else {
                const item = new RecItem(params);
                if (!item) {
                    return;
                }
                item.save();
            }
            this.checkRecItemsCount();
            if (type === TYPES.Browse) {
                this.setLastBrowseItem(params);
            }
        } catch (e) {
            // Nothing
        }
    }

    static sendRecItems = () => {
        try {
            let items = Storage.getAll();
            Storage.clear();
            this.sendEvents(items);
        } catch (e) {}
    };

    static startupCheck = () => {
        let date = new Date();
        let recItems = Storage.getAll();
        if (
            !recItems.length ||
            recItems[0].ts > Math.round(date.setMinutes(date.getMinutes() - SEND_FREQUENCY_MINUTES) / 1000)
        ) {
            return;
        }

        setTimeout(this.sendRecItems.bind(null, recItems.length), 1000);
    };

    static startInterval = () => {
        this.interval = setInterval(this.sendRecItems, SEND_FREQUENCY);
    };

    static checkRecItemsCount = throttle(() => {
        let events = Storage.getAll();
        if (events.length < MAX_EVENTS_COUNT) {
            return;
        }

        this.sendRecItems();
    }, 10 * 1000);

    static sendEvents = (events) => {
        if (!events || Object.keys(events).length === 0) {
            return;
        }
        //TODO: start remove debug
        events.map(events, (event) => {
            delete event.t;
        });
        //TODO end remove debug

        // TODO enable sending events
        // fetch(process.env.RECOMMENDATIONS_DIRECT_ENDPOINT + '/api/v3/events', {
        //     headers: {
        //         'x-auth-token': api.token,
        //         'Content-Type': 'application/json',
        //     },
        //     method: 'POST',
        //     body: JSON.stringify({ items: events }),
        // }).catch(() => {
        //     //on error return events to storage for resend
        //     Storage.appendItems(events);
        // });
    };
}

const SEND_FREQUENCY_MINUTES = 15; //minutes
const SEND_FREQUENCY = SEND_FREQUENCY_MINUTES * 60 * 1000;
const MAX_EVENTS_COUNT = 256;
const MAX_STORED_EVENTS_COUNT = 1024;
const RECOMMENDATION_STORAGE_NAME = 'recommendations';
export const WATCH_EVENT_PERIOD = 240; //seconds
export const PLAYED_EVENT_PERCENT = 90; // %
const LAST_BROWSE_STORAGE_NAME = 'last_browse';
const ITEM_TYPES = [
    'channel',
    'episode',
    'movie',
    'season',
    'serial',
    'package',
    'subscription',
    'channel-package',
    'schedule',
    'genre',
    'person',
];
export const TYPES = {
    BlockAsset: 'BlockAsset', //2.2
    Bookmark: 'Bookmark', //2.3
    Browse: 'Browse', //2.4

    ChangeVolume: 'ChangeVolume', //2.6
    ClickedOnSearchResult: 'ClickedOnSearchResult', //2.7
    Dislike: 'Dislike', //2.8
    Like: 'Like', //2.9

    PauseAnyContent: 'PauseAnyContent', //2.11

    PlayAvailableContent: 'PlayAvailableContent', //2.13
    PlayedAny: 'PlayedAny', //2.14
    Purchased: 'Purchased', //2.15

    SetChannelConfiguration: 'SetChannelConfiguration', //2.18

    StopAnyContent: 'StopAnyContent', //2.20
    UnBookmark: 'UnBookmark', //2.21
    Unsubscribe: 'Unsubscribe', //2.22
    Watch: 'Watch', //2.23

    PlayTrailer: 'PlayTrailer', //2.26
};
const MINIMUM_ONBOARDING_ELEMENTS = 5;

const TYPES_MAP = {
    [TYPES.Like]: 15,
    [TYPES.Dislike]: 16,
    [TYPES.Bookmark]: 5,
    [TYPES.UnBookmark]: 21,
    [TYPES.Browse]: 6,
    [TYPES.ClickedOnSearchResult]: 12,
    [TYPES.Purchased]: 11,
    [TYPES.BlockAsset]: 22,
    [TYPES.ChangeVolume]: 30,
    [TYPES.PauseAnyContent]: 28,
    [TYPES.SetChannelConfiguration]: 25,
    [TYPES.StopAnyContent]: 29,
    [TYPES.Watch]: 31,
    [TYPES.PlayedAny]: 10,
    [TYPES.PlayAvailableContent]: 19,
    [TYPES.Unsubscribe]: 26,
    [TYPES.PlayTrailer]: 34,
};

class RecItem {
    constructor(params) {
        let lastBrowse = this.getLastBrowseItem(params.id);

        this.type = params.type;
        this.ts = Common.getTime();
        this.assetId = params.id;
        this.recommendationId = params.recommendationId || 'none';
        this.itemType = params.itemType;
        this.assetPosition = params.assetPosition || lastBrowse.assetPosition;

        this.fillByType(params);

        this.usecaseId = this.usecaseId || this.guessUseCase(this.itemType);
        this.assetPosition =
            typeof this.assetPosition !== 'undefined' && this.assetPosition !== null && this.assetPosition !== ''
                ? this.assetPosition
                : -1;
    }

    fillByType(params) {
        switch (params.type) {
            case TYPES.Like:
            case TYPES.Dislike:
                this.itemType = params.type;
                break;
            case TYPES.Bookmark:
            case TYPES.UnBookmark:
                this.itemType = params.type;
                this.usecaseId = params.assetPosition ? params.usecaseId : null;
                break;
            case TYPES.Browse:
                this.itemType = params.type;
                this.usecaseId = params.usecaseId || this.getUsecaseByType(params.type);
                break;
            case TYPES.ClickedOnSearchResult:
                this.itemType = params.type;
                this.usecaseId = 'search';
                break;
            case TYPES.Purchased:
                this.itemType = params.type;
                this.usecaseId = params.usecaseId || params.type;
                break;
            case TYPES.ChangeVolume: {
                let changeType;
                if (params.before[1] && !params.after[1]) {
                    changeType = 'unmute';
                } else if (!params.before[1] && params.after[1]) {
                    changeType = 'mute';
                } else if (params.before[0] > params.after[0]) {
                    changeType = 'lower';
                } else if (params.before[0] < params.after[0]) {
                    changeType = 'louder';
                } else {
                    return;
                }
                this.usecaseId = this.getUsecaseByType(params.itemType);
                this.changeType = changeType;
                break;
            }
            case TYPES.PauseAnyContent:
                this.usecaseId = this.getUsecaseByType(params.itemType);
                this.isCatchup = params.isCatchup || false;
                this.position = Math.round(params.position);
                break;
            case TYPES.StopAnyContent:
                this.usecaseId = this.getUsecaseByType(params.itemType, lastBrowse.usecaseId);
                this.isCatchup = params.isCatchup || false;
                this.position = Math.round(params.position);
                this.start = params.start;
                this.finish = Common.getTime();
                break;
            case TYPES.Watch:
                this.usecaseId = this.getUsecaseByType(params.itemType, lastBrowse.usecaseId);
                this.position = Math.round(params.assetPosition);
                this.progressValue = params.progressValue;
                break;
            case TYPES.PlayedAny:
            case TYPES.SetChannelConfiguration:
                this.usecaseId = this.getUsecaseByType(params.itemType, lastBrowse.usecaseId);
                this.assetPosition = lastBrowse.assetPosition;
                break;
            case TYPES.PlayAvailableContent:
                this.usecaseId = this.getUsecaseByType(params.itemType, lastBrowse.usecaseId);
                this.assetPosition = lastBrowse.assetPosition;
                this.isCatchup = params.isCatchup || false;
                this.start = params.start;
                this.finish = Common.getTime();
                break;
            case TYPES.Unsubscribe: {
                let usecaseId = '';
                switch (params.type) {
                    case 'subscription':
                        usecaseId = 'subscription-settings';
                        break;
                    case 'channel-package':
                        usecaseId = 'channelpack-settings';
                        break;
                    default:
                }
                this.itemType = params.type;
                this.usecaseId = params.usecaseId || usecaseId || params.type;
                break;
            }
            case TYPES.PlayTrailer:
                this.itemType = 'movie';
                this.usecaseId = this.getUsecaseByType('trailer', lastBrowse.usecaseId);
                break;
        }
    }

    getUsecaseByType(itemType, usecaseId) {
        if (usecaseId && ['channel', 'schedule'].indexOf(itemType) === -1) {
            return usecaseId;
        }
        return (
            (['channel', 'schedule'].indexOf(itemType) !== -1 && 'liveTvPlayer') ||
            (['movie', 'trailer'].indexOf(itemType) !== -1 && 'moviePlayer') ||
            (itemType === 'episode' && 'seriesPlayer')
        );
    }

    guessUseCase(itemType) {
        switch (itemType) {
            case 'movie':
                return 'movieCard';
            case 'serial':
                return 'seriesCard';
            case 'channel':
                return 'tvProgramCard';
            // no default
        }
        return null;
    }

    getLastBrowseItem(id) {
        let item = localStorage.getItem(LAST_BROWSE_STORAGE_NAME);
        item = item ? JSON.parse(item) : {};
        if (item.id !== id) {
            return {};
        }
        return item;
    }

    save() {
        if (!this.validate()) {
            return;
        }
        //TODO: start remove debug
        TYPES_MAP.forEach((v, k) => {
            if (v === this.type) {
                this.t = k;
                return false;
            }
        });
        //TODO end remove debug

        Storage.setItem(this);
    }

    validate() {
        let required = ['type', 'ts', 'assetId', 'itemType', 'usecaseId'];
        for (let value of required) {
            if (!this[value]) {
                return false;
            }
        }
        return ITEM_TYPES.indexOf(this.itemType) !== -1;
    }
}

class Storage {
    static getAll() {
        if (typeof window === 'undefined') return [];
        let items = localStorage.getItem(RECOMMENDATION_STORAGE_NAME);
        if (items) {
            return JSON.parse(items);
        }
        return [];
    }

    static setItem(recItem) {
        let items = this.getAll();
        if (items.length < MAX_STORED_EVENTS_COUNT) {
            items.push(recItem);
            this.setItems(items);
        }
    }

    static setItems(items) {
        return localStorage.setItem(RECOMMENDATION_STORAGE_NAME, JSON.stringify(items));
    }

    static clear() {
        return this.setItems([]);
    }

    static appendItems(events) {
        let items = this.getAll().concat(events);
        items = items.slice(0, MAX_STORED_EVENTS_COUNT);
        this.setItems(items);
    }
}
