import { ShowcaseItemType } from '@/src/constants/types';
import ChannelPackage from '@/src/api/v3/models/ChannelPackage';
import Subscription from '@/src/api/v3/models/Subscription';
import Bundle from '@/src/api/v3/models/Bundle';
import Stock from '@/src/api/v3/models/Stock';
import { getPriceText } from '@/src/helpers/FormatHelper';

const TYPE_EST = 'est';

/**
 *  Предложение
 */
export default class Offer {
    /** ID
     * @type {Number} */
    id;
    /** Название типа
     *  @type {string} */
    typeName = 'Предложение';
    /** Цена
     *  @type {number} */
    price;
    /** Дата покупки
     *  @type {?Date} */
    purchaseAt;
    /** Дата истечения
     * @type {?Date} */
    expireAt;
    /** Подписка
     *  @type {Subscription} */
    subscription = null;
    /** Поставщик
     *  @type {?string} */
    provider = null;
    /** @type {?string} */
    ivodType = null;
    ivodTitle = null;
    qualityType = null;

    /** Период
     *  @type {?number} */
    period = null;
    /** Единица измерения
     *  @type {?string} */
    periodType = null;
    /** Акция
     *  @type {?Stock} */
    stock = null;

    /**
     * Offer constructor.
     * @param {Object} object
     */
    constructor(object) {
        /** @namespace object.stocks **/
        /** @namespace object.ivod **/
        this.id = object.id;
        this.price = object.price;
        this.purchaseAt = object.purchaseAt ? new Date(object.purchaseAt) : null;
        this.expireAt = object.expireAt ? new Date(object.expireAt) : null;
        this.period = object.period?.value;
        this.periodType = object.period?.type;

        // Есть акции
        if (object.stocks?.length > 0) {
            this.stock = new Stock(object.stocks[0]);
        }

        if (object.ivod) {
            // Если это предложение - подписка
            if (object.ivod.type === 'svod') {
                switch (object.root?.type) {
                    case ShowcaseItemType.CHANNEL_PACKAGE:
                        this.subscription = new ChannelPackage(object.root);
                        break;
                    case ShowcaseItemType.BUNDLE:
                        this.subscription = new Bundle(object.root);
                        break;
                    default:
                        this.subscription = new Subscription(object.root);
                }
                if (!this.subscription.title && object.provider.title) {
                    this.subscription.title = object.provider.title;
                }
            }
            this.ivodTitle = object.ivod.title;
            this.ivodType = object.ivod.type;
        }

        if (object.quality?.[0]) {
            this.qualityType = object.quality[0].type;
        }

        this.provider = object.provider?.title;
    }

    /**
     * Get offer conditions for modal popup before purchase.
     *
     * @param title
     * @returns string
     */
    getOfferText(title) {
        return this.ivodType === TYPE_EST
            ? `Стоимость покупки фильма "${title}" ${this.price} ₽. Купленный фильм всегда будет доступен к просмотру на всех ваших устройствах.`
            : `Стоимость аренды фильма "${title}" ${this.price} ₽. У вас будет 30 дней, чтобы начать смотреть фильм, и 48 часов, чтобы досмотреть его до конца.`;
    }

    /**
     * Get short offer conditions for modal popup before purchase.
     *
     * @returns string
     */
    getOfferTextShort() {
        if (this.stock?.isTNB()) {
            return 'Попробовать';
        } else {
            return this.ivodType === TYPE_EST
                ? `Стоимость покупки ${this.price} ₽.`
                : `Стоимость аренды ${this.price} ₽.`;
        }
    }

    /**
     * Получить цену по оптовому предложению
     * @param {BaseModel} [model]
     * @param {Number} period
     * @param {String} periodType
     * @returns {string}
     */
    getPriceInfo(model, period = this.period, periodType = this.periodType) {
        const price = this.price;
        if (this.isPartOfBundle(model)) {
            return `в подписке ${this.subscription.title}`;
        }
        if (this.stock?.isTNBActivated()) {
            return this.stock?.priceWithDiscount > 0 ? this.getStockTitle() : 'Пробный период';
        }
        if (price === 0) {
            return 'Доступно';
        }
        if (!price) {
            return '';
        }
        return getPriceText(price, period, periodType);
    }

    /**
     * @param {BaseModel} model
     */
    isPartOfBundle(model) {
        return this.subscription instanceof Bundle && model && this.subscription.id !== model?.id;
    }

    getStockPrice() {
        if (this.stock?.isTNB() && this.stock?.priceWithDiscount === 0) {
            return 'Бесплатно';
        } else {
            return `за ${this.stock?.priceWithDiscount} ₽`;
        }
    }

    getStockTitle(isMiddleText = false) {
        const lowerCaseFirstLetter = (string) => {
            return string.charAt(0).toLowerCase() + string.slice(1);
        };
        let title = null;
        if (this.stock?.isTNB() && this.stock?.priceWithDiscount > 0) {
            title = `На ${this.stock.getPeriodText()} ${this.getStockPrice()}`;
        } else if (this.stock?.isTNB()) {
            title = `${this.getStockPrice()} ${this.stock.getPeriodText()}`;
        }
        if (title && isMiddleText) {
            title = ' ' + lowerCaseFirstLetter(title);
        }
        return title;
    }

    /**
     * @returns {string}
     */
    getStockInfo() {
        const stockTitle = this.stock?.getTitle();
        if (!stockTitle) {
            return '';
        }
        return `${stockTitle}, далее ${this.getPriceInfo(null, 1, 'month')}`;
    }

    /**
     * Получить заголовок оффера для кнопки подписки
     *
     * @param typeName
     * @returns string
     */
    getButtonTitle(typeName) {
        const priceText = getPriceText(this.price, this.period, this.periodType, '');
        if (this.stock?.isTNB()) {
            return 'Подключить';
        }
        if (this.subscription) {
            return 'Подключить и смотреть';
        } else if (this.ivodType === TYPE_EST) {
            return `Купить ${typeName.toLowerCase()} за ${priceText}`;
        } else {
            return `Арендовать ${typeName.toLowerCase()} за ${priceText}`;
        }
    }

    /**
     * Получить заголовок оффера для кнопки
     *
     * @param {string} typeName
     * @param {Boolean} isGuest
     * @returns {{buttonTitle: (string), buttonIcon: string}}
     */
    getDetailsButton(typeName, isGuest) {
        let buttonTitle;
        let buttonIcon = '';
        if (isGuest && !this.stock?.isTNBAvailable()) {
            buttonTitle = 'Зарегистрироваться';
        } else {
            buttonTitle = 'Подключить и смотреть';
        }
        return { buttonTitle, buttonIcon };
    }

    /**
     * Получить заголовок оффера для кнопки в блоке оффера на деталке
     *
     * @param {string} typeName
     * @param {Boolean} isGuest
     * @returns {{buttonTitle: (string), buttonIcon: string}}
     */
    getOfferButton(typeName, isGuest) {
        let buttonTitle;
        let buttonIcon;
        if (isGuest && !this.stock?.isTNBAvailable()) {
            buttonTitle = 'Зарегистрироваться';
            buttonIcon = '';
        } else if (this.price === 0 && !this?.stock?.isTNBActivated()) {
            buttonTitle = 'Попробовать';
            buttonIcon = 'accept';
        } else if (this.stock?.isTNBAvailable() && this.stock?.priceWithDiscount > 0) {
            buttonTitle = 'Подключить и смотреть';
        } else if (this.stock?.isTNBAvailable()) {
            buttonTitle = 'Подключить и смотреть';
            buttonIcon = 'gift';
        } else {
            buttonTitle = this.getButtonTitle(typeName);
            buttonIcon = 'accept';
        }
        return { buttonTitle, buttonIcon };
    }
}
