// noinspection JSUnusedGlobalSymbols

import { QUERY_POST } from '@/src/api/Api';
import MultiscreenStatus from '@/src/api/v2/models/MultiscreenStatus';
import { ApiMixin } from '@/src/api/ApiMixin';
import MultiscreenDevice from '@/src/api/v2/models/MultiscreenDevice';

/**
 * Multiscreen
 * @see https://kblion.ertelecom.ru/display/BA/Trans+11.+Multiscreen
 */

export class Multiscreen extends ApiMixin {
    /**
     * This call allows receiving the current status of multiscreen service.
     * @returns {Promise<MultiscreenStatus>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getMultiscreenStatus = async () => {
        const response = await this.api.v2({
            uri: '/er/multiscreen/status',
        });
        return new MultiscreenStatus(response['status']);
    };

    /**
     * Connect service
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    activateMultiscreen = async () => {
        await this.api.v2({
            uri: '/er/multiscreen/activate',
            method: QUERY_POST,
        });
    };

    /**
     * Возвращает ID устройства указанного в токене
     * @returns {Promise<number>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getMultiscreenDeviceId = async () => {
        const response = await this.api.v2({
            uri: '/er/multiscreen/device/id',
        });
        return response['device_id'];
    };

    /**
     * Get the list of devices
     * @returns {Promise<MultiscreenDevice[]>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getMultiscreenDevices = async () => {
        const response = await this.api.v2({
            uri: '/er/multiscreen/devices',
        });
        const devices = response['devices'] || [];
        return devices.map((device) => new MultiscreenDevice(device));
    };

    /**
     * Connect a device
     * @param {string} [title]
     * @returns {Promise<void>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    bindMultiscreenDevice = async (title = 'movix.ru') => {
        await this.api.v2({
            uri: '/er/multiscreen/device/bind',
            params: title ? { title: title } : null,
            method: QUERY_POST,
        });
    };

    /**
     * Rename the device
     * @param {number} deviceId
     * @param {string} title
     * @returns {Promise<Array>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    renameMultiscreenDevice = async (deviceId, title) => {
        const response = await this.api.v2({
            uri: '/er/multiscreen/device/rename',
            params: {
                device_id: deviceId,
                title: title,
            },
            method: QUERY_POST,
        });
        return response['devices'];
    };

    /**
     * Disconnect a device
     * @param {number} deviceId
     * @returns {Promise<Array>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    unbindMultiscreenDevice = async (deviceId) => {
        const response = await this.api.v2({
            uri: '/er/multiscreen/device/unbind',
            params: { device_id: deviceId },
            method: QUERY_POST,
        });
        return response['devices'];
    };

    /**
     * Connect an additional slot
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    addMultiscreenSlot = async () => {
        await this.api.v2({
            uri: '/er/multiscreen/slot/add',
            method: QUERY_POST,
        });
    };
}
