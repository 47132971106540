import ApiBackendException from '@/src/api/ApiBackendException';
import { APP_VERSION, QUERY_GET } from '@/src/api/Api';

export default class APIv2 {
    static isPostResponseEmpty = false;

    static validateJson = (url, response) => {
        if (response.result !== 1) {
            let message = response.error?.message_rus;
            if (!message) {
                const error = response.error?.exception || response.error;
                message = error?.message_rus || error?.message;
            }
            throw new ApiBackendException(
                message || 'Ошибка при запросе',
                response.error?.exception ? response.error.exception.code : response.error?.message,
                response.error || null
            );
        }
    };

    static httpQuery = async (url, token, data = {}, method = QUERY_GET, useHeaders = true) => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        if (useHeaders) {
            headers['x-auth-token'] = token;
            headers['View'] = 'stb3';
            headers['X-App-Version'] = APP_VERSION;
        }
        let response;
        if (method === QUERY_GET) {
            let queryString = data
                ? '?' +
                  Object.keys(data)
                      .map((key) => key + '=' + encodeURIComponent(data[key]))
                      .join('&')
                : '';
            response = await fetch(url + queryString, {
                method: 'GET',
                headers: headers,
            });
        } else {
            let queryString = data
                ? Object.keys(data)
                      .map((key) => key + '=' + encodeURIComponent(data[key]))
                      .join('&')
                : '';
            response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: queryString,
            });
        }
        return response;
    };
}
