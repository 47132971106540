import BaseModel from '@/src/api/v3/models/BaseModel';
import { ShowcaseItemType } from '@/src/constants/types';
import Program from '@/src/api/v3/models/Program';
import Channel from '@/src/api/v3/models/Channel';
import { SECONDS_IN_DAY } from '@/src/api/cache.config';
import { slug } from '@/src/modules/Common';
import { CATCHUP_AVAILABLE_DAYS } from '@/src/constants/global';
import { PinReason } from '@/src/constants/pin-reason';

const CATCHUP_PERIOD = CATCHUP_AVAILABLE_DAYS * SECONDS_IN_DAY;

export default class Schedule extends BaseModel {
    URL_PREFIX = 'programms';
    SLIDER_STUB_CLASS = 'cover--shows';
    typeName = 'Телепередача';
    EVENT_ITEM_CATEGORY = ShowcaseItemType.SCHEDULE;

    /** Доступен ли кетчап для данной передачи
     *  @type {boolean} */
    isCatchupAvailable = false;
    /** Начало передачи
     * @type {?Date} */
    startAt;
    /** Начало передачи с точной меткой
     * @type {?Date} */
    exactStartAt;
    /** Конец передачи
     * @type {?Date} */
    endAt;
    /** Конец передачи с точной меткой
     * @type {?Date} */
    exactEndAt;
    /** Длительность передачи
     * @type {?Number} */
    duration;
    /** Длительность передачи по точным меткам
     * @type {?Number} */
    exactDuration;
    /** Канал
     *  @type  {?Channel} */
    channel = null;
    /** @type  {Program} */
    program;

    constructor(object) {
        super(object);
        this.position = object?.position ?? 0;
        if (object?.data?.type === ShowcaseItemType.PROGRAM) {
            this.program = new Program(object.data);
            this.age = this.program.age;
            this.adult = this.program.adult;
        }

        if (object?.global) {
            if (object.global.locals?.[0]) {
                this.channel = new Channel(object.global.locals[0]);
            } else if (object.global.title) {
                this.channel = new Channel(object.global);
            } else {
                this.channel = new Channel({});
            }
            this.isCatchupAvailable = this.channel.isCatchup();
        } else {
            this.channel = new Channel({});
        }
        this.startAt = object.startAt ? new Date(object.startAt) : null;
        this.exactStartAt = object.exactTimeStart ? new Date(object.exactTimeStart * 1000) : this.startAt;

        this.endAt = object.endAt ? new Date(object.endAt) : null;
        this.exactEndAt = object.exactTimeEnd ? new Date(object.exactTimeEnd * 1000) : this.endAt;

        if (this.endAt && this.startAt) {
            this.duration = (this.endAt - this.startAt) / 1000;
        }
        if (this.exactEndAt && this.exactStartAt) {
            this.exactDuration = (this.exactEndAt - this.exactStartAt) / 1000;
        }

        const programTitle = this.getProgramTitle();
        if (programTitle) {
            this.title = programTitle;
        }

        this.pageTitle = this.getChannelTitle() + ' - ' + this.getProgramTitle();
    }

    /**
     * Получение изображения
     */
    getImageSrc(options = null) {
        if (this.program) {
            return this.program.getImageSrc(options);
        }
        return null;
    }

    /**
     * Получить процент завершения
     * @returns number|null
     */
    getCompletePercent() {
        const time = new Date();
        if (time > this.endAt || time < this.startAt) {
            return null;
        }
        const duration = this.endAt - this.startAt;
        return duration ? (100 * (time - this.startAt)) / duration : null;
    }

    /**
     * Получить название канала
     * @returns null|string
     */
    getChannelTitle() {
        return this.channel ? this.channel.title : null;
    }

    /**
     * Получить название передачи
     * @returns null|string
     */
    getProgramTitle() {
        if (this.title) {
            return this.title;
        }
        if (this.program) {
            return this.program.title + (this.program.episodeTitle ? ' - ' + this.program.episodeTitle : '');
        }
        return null;
    }

    /**
     * Получить изображения для фона
     * @returns null|string
     */
    getBackgroundSrc() {
        return this.program ? this.program.getBackgroundSrc() : null;
    }

    getTitleWithoutRate() {
        return this.title.replace(/(s[d{1,2}+])/, '');
    }

    /**
     * Получить ссылку на просмотр телеархива передачи в ТВ
     * @returns string
     */
    getCatchUpUrl() {
        const channelTitle = this.channel ? `${slug(this.channel.title)}-` : '';
        const channelId = this.channel ? `${this.channel.id}-` : '';
        return `/television/${channelTitle}${slug(this.getTitleWithoutRate())}-${channelId}${this.id}`;
    }

    /**
     * Получить ссылку на просмотр канала в ТВ
     * @returns string
     */
    getLiveUrl() {
        return `/television/${slug(this.getChannelTitle())}-${this.channel.id}`;
    }

    /**
     * Передача в телеархиве
     * @returns boolean
     */
    isCatchup() {
        return new Date() > this.endAt;
    }

    /**
     * Передача в прямом эфире
     * @returns boolean
     */
    isLive() {
        const time = new Date();
        return time >= this.startAt && time <= this.endAt;
    }

    /**
     * Передача в будущем
     * @returns boolean
     */
    isFuture() {
        return new Date() <= this.startAt;
    }

    /**
     * Check if program not too old for catchup playback.
     *
     * @returns boolean
     */
    isNotOldForCatchup() {
        return (new Date().getTime() - this.endAt.getTime()) / 1000 < CATCHUP_PERIOD;
    }

    /**
     * Проверяет нужен ли ПИН для просмотра контента модели
     * @param {?PinStatus} pinStatus
     * @param {Boolean} isTvSession
     * @param {Boolean} isTvAdultSession
     * @returns ?string
     */
    getProgramPinReason(pinStatus, isTvSession, isTvAdultSession) {
        if (pinStatus) {
            if (this.isAdult() && !isTvAdultSession) {
                return PinReason.ADULT;
            }
            if (
                (pinStatus.isUnstated() && this.age >= 18) ||
                (pinStatus.isEnabled() && pinStatus.inappropriateContentEnabled && this.age >= 18 && !isTvSession)
            ) {
                return PinReason.INAPPROPRIATE_CONTENT;
            }
        } else {
            if (this.isAdult()) {
                return PinReason.ADULT;
            }
            if (this.age >= 18) {
                return PinReason.INAPPROPRIATE_CONTENT;
            }
        }
        return null;
    }
}
