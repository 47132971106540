import { action, makeObservable, observable } from 'mobx';

const STORAGE_KEY = 'pin';
export const ADULT_SESSION_TTL = 15 * 60; // Время действия Adult сессии

export class PinStore {
    root;

    @observable /** @type {string} */ pinCode = '';
    @observable /** @type {?PinStatus} */ pinStatus = undefined;
    @observable /** @type {?Date} */ expiredDate = undefined;
    @observable /** @type {Boolean} */ isTvAdultSession;
    @observable /** @type {Boolean} */ isTvSession;
    @observable /** @type {Boolean} */ pinChecked;
    @observable /** @type {?Date} */ adultSessionExpiredDate = undefined;

    constructor(root) {
        this.root = root;
        this.isTvAdultSession = false;
        this.isTvSession = false;
        this.pinChecked = false;
        makeObservable(this);
    }

    @action
    setPinCode = (pinCode) => {
        this.pinCode = pinCode;
    };

    @action
    setIsTvAdultSession = (value) => {
        this.isTvAdultSession = value;
    };

    @action
    setIsTvSession = (value) => {
        this.isTvSession = value;
    };

    @action
    setPinChecked = (value) => {
        this.pinChecked = value;
    };

    @action
    setExpiredDate = (expiredDate, isUpdateSessionStorage = true) => {
        this.expiredDate = expiredDate;
        if (isUpdateSessionStorage) {
            this.updateSessionStorage();
        }
    };

    @action
    setAdultSessionExpiredDate = (expiredDate, isUpdateSessionStorage = true) => {
        this.adultSessionExpiredDate = expiredDate;
        if (isUpdateSessionStorage) {
            this.updateSessionStorage();
        }
    };

    updateSessionStorage() {
        const sessionValue = {
            ...(this.expiredDate ? {expired: this.expiredDate.toJSON()} : {}),
            ...(this.adultSessionExpiredDate ? {adultExpired: this.adultSessionExpiredDate.toJSON()} : {}),
        };
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(sessionValue));
    }

    /**
     * Возвращает true если ПИН еще не вводился или введенный ПИН истёк на момент вызова
     * @returns {Boolean}
     */
    checkIsPinExpired = () => {
        return !this.expiredDate || new Date() >= this.expiredDate;
    }

    /**
     * Возвращает true если активна сессия взрослого контента
     * @returns {Boolean}
     */
    checkIsAdultSession = () => {
        return Boolean(this.adultSessionExpiredDate && new Date() < this.adultSessionExpiredDate);
    }

    @action
    setPinStatus = (pinStatus) => {
        // TODO: Обновлять значение и в кеше или обновлять зависимый кеш
        this.pinStatus = pinStatus;
    };

    @action
    hydrate(data) {
        if (data) {
            this.pinStatus = data.pinStatus;
        }
        if (this.root.authStore.isGuest) {
            this.pinStatus = null;
        } else if (typeof window !== 'undefined') { // Заполняем статус только на клиенте
            const { expired, adultExpired } = JSON.parse(sessionStorage.getItem(STORAGE_KEY)) || {};
            if (expired) {
                this.setExpiredDate(new Date(expired), false);
            }
            if (adultExpired) {
                this.setAdultSessionExpiredDate(new Date(adultExpired), false);
            }
            this.root.apiStore.api.parentalControl.getPinStatus()
                .then((status) => {
                    this.setPinStatus(status);
                })
                .catch((error) => {
                    console.log('getPinStatus error', error);
                });
        }
    }
}

export function getInitialStoreState() {
    return {};
}
