import AnalyticLink from '@/components/header/AnalyticLink';
import styles from './Nav.module.scss';
import Button from '@/components/button/Button';
import { useEffect, useRef, useState } from 'react';
import { throttle } from '@/src/modules/Common';

export default function Nav({ items, modifier, isVertical, onIndexChange }) {
    const wrapperRef = useRef();
    const sliderRef = useRef();
    const shiftRef = useRef(0);
    const offsetRef = useRef(0);
    const maxShiftRef = useRef(0);

    const [isNavPrev, setIsNavPrev] = useState(false);
    const [isNavNext, setIsNavNext] = useState(false);

    const enterHandler = (e) => {
        if (typeof onIndexChange === 'function') {
            onIndexChange(Number(e.target.dataset.index));
        }
    };

    const leaveHandler = (e) => {
        const target = e.relatedTarget;
        if (
            target &&
            typeof target.closest === 'function' &&
            target.closest('.menu__content') &&
            !target.classList.contains('nav__item')
        ) {
            return;
        }
        if (typeof onIndexChange === 'function') {
            onIndexChange(-1);
        }
    };

    useEffect(() => {
        const render = throttle(() => {
            const wrapper = /** @type {HTMLElement} */ wrapperRef.current;
            const slider = /** @type {HTMLElement} */ sliderRef.current;
            if (!wrapper || !slider) {
                return;
            }
            if (isVertical) {
                const heightWrapper = wrapper.offsetHeight;
                const heightSlider = slider.scrollHeight;
                maxShiftRef.current = heightSlider - heightWrapper;
                offsetRef.current = heightWrapper - 40;
                slider.scrollTop = 0;
                setIsNavPrev(false);
                setIsNavNext(heightWrapper < heightSlider);
            } else {
                const widthWrapper = wrapper.offsetWidth;
                const widthSlider = slider.scrollWidth;
                maxShiftRef.current = widthSlider - widthWrapper;
                offsetRef.current = widthWrapper - 120;
                slider.scrollLeft = 0;
                setIsNavPrev(false);
                setIsNavNext(widthWrapper < widthSlider);
            }
        });
        render();
        window.addEventListener('resize', render);
        return () => {
            window.removeEventListener('resize', render);
        };
    }, [isVertical]);

    const updateSlider = () => {
        const slider = /** @type {HTMLElement} */ sliderRef.current;
        if (isVertical) {
            slider.scroll({ top: shiftRef.current, behavior: 'smooth' });
        } else {
            slider.scroll({ left: shiftRef.current, behavior: 'smooth' });
        }
        setIsNavPrev(shiftRef.current > 0);
        setIsNavNext(shiftRef.current < maxShiftRef.current);
    };

    const onPrev = () => {
        shiftRef.current = shiftRef.current - offsetRef.current;
        if (shiftRef.current < 0) {
            shiftRef.current = 0;
        }
        updateSlider();
    };

    const onNext = () => {
        shiftRef.current = shiftRef.current + offsetRef.current;
        if (shiftRef.current >= maxShiftRef.current) {
            shiftRef.current = maxShiftRef.current;
        }
        updateSlider();
    };

    return (
        <div
            className={
                styles['nav'] +
                (modifier ? ' ' + modifier : '') +
                (isNavNext ? ' nav--next' : '') +
                (isNavPrev ? ' nav--prev' : '')
            }
        >
            <div className="nav__action nav__action--prev">
                <Button
                    icon={isVertical ? 'arrow-up' : 'arrow-left'}
                    className={
                        isVertical
                            ? 'button--secondary button--nav-vertical'
                            : 'button--small button--secondary button--nav button--icon-only'
                    }
                    onClick={onPrev}
                />
            </div>
            <div className="nav__wrapper" ref={wrapperRef}>
                <nav className="nav__slider" ref={sliderRef}>
                    {items.map((item, index) => (
                        <AnalyticLink
                            key={index} // NOSONAR
                            data-index={index}
                            href={item.url}
                            className={'nav__item' + (item.active ? ' nav__item--active' : '')}
                            onMouseEnter={item.submenu ? enterHandler : null}
                            onMouseLeave={item.submenu ? leaveHandler : null}
                            onClick={(e) => {
                                e.target.blur();
                            }}
                        >
                            {item.name}
                        </AnalyticLink>
                    ))}
                </nav>
            </div>
            <div className="nav__action nav__action--next">
                <Button
                    icon={isVertical ? 'arrow-down' : 'arrow-right'}
                    className={
                        isVertical
                            ? 'button--secondary button--nav-vertical button--icon-left'
                            : 'button--small button--secondary button--nav button--icon-right'
                    }
                    title={'Ещё'}
                    onClick={onNext}
                />
            </div>
        </div>
    );
}
