import slugify from 'slugify';

// TODO: Разбить на отдельные функции

export default class Common {
    static copyToClipboard(string) {
        let clipboard = document.createElement('input');
        document.body.appendChild(clipboard);
        clipboard.value = string;
        clipboard.select();
        // noinspection JSDeprecatedSymbols
        document.execCommand('copy'); // NOSONAR
        document.body.removeChild(clipboard);
    }

    static pageRedirect(url) {
        window.location.replace(url);
    }

    static clearUrl() {
        const url = location.search ? window.location.href.replace(location.search, '') : window.location.href;
        window.history.pushState('', '', url);
    }

    static getURLParameter(parameterName) {
        const items = location.search.slice(1).split('&');
        for (const item of items) {
            const temp = item.split('=');
            if (temp.shift() === parameterName) {
                return decodeURIComponent(temp.join('='));
            }
        }
        return null;
    }

    static getTime() {
        let date = new Date();
        return Math.floor(date.getTime() / 1000);
    }

    static scrollbarWidth() {
        const div = document.createElement('div'),
            body = document.querySelector('body');

        div.style.overflowY = 'scroll';
        div.style.width = '50px';
        div.style.height = '50px';
        div.style.visibility = 'hidden';

        document.body.appendChild(div);

        const scrollWidth = div.offsetWidth - div.clientWidth;

        document.body.removeChild(div);

        if (body.offsetHeight > window.innerHeight) {
            return scrollWidth;
        }

        return 0;
    }

    static keyEscape = 27;
    static keyEnter = 13;
    static keyUp = 38;
    static keyDown = 40;

    static isPressEscape(evt) {
        return evt.keyCode && evt.keyCode === this.keyEscape;
    }

    static isPressEnter(evt) {
        return evt.keyCode && evt.keyCode === this.keyEnter;
    }

    static isPressKeyUp(evt) {
        return evt.keyCode && evt.keyCode === this.keyUp;
    }

    static isPressKeyDown(evt) {
        return evt.keyCode && evt.keyCode === this.keyDown;
    }
}

export function slug(string = '', replacement = '-') {
    return slugify(string || '', {
        replacement: replacement,
        remove: undefined,
        lower: true,
        strict: true,
        locale: 'en',
    });
}

export function getTimeMilliseconds() {
    return new Date().getTime();
}

export function throttle(fn, threshold, scope) {
    threshold || (threshold = 250);
    let last, deferTimer;
    return function () {
        let context = scope || this;

        let now = +new Date(),
            args = arguments;
        if (last && now < last + threshold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(() => {
                last = now;
                fn.apply(context, args);
            }, threshold);
        } else {
            last = now;
            fn.apply(context, args);
        }
    };
}

export function format(value, pattern) {
    let i = 0,
        v = value.toString();
    // eslint-disable-next-line no-unused-vars
    return pattern.replace(/#/g, (_) => v[i++]);
}

export function makeCancelable(promise) {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
}

export function isMobile() {
    if (typeof window === 'undefined') {
        return false;
    }
    let check = false;
    (function (a) {
        if (
            /* NOSONAR */ /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /* NOSONAR */ /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-\/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ \/])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                a.substring(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera); // NOSONAR for 'vendor' is deprecated.
    return check;
}

export function isMobileOrTablet() {
    if (typeof window === 'undefined') {
        return false;
    }
    let check = false;
    (function (a) {
        if (
            /* NOSONAR */ /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            /* NOSONAR */ /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-\/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ \/])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                a.substring(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera); // NOSONAR for 'vendor' is deprecated.
    return check;
}

export function getTimezone() {
    const hourOffset = -(new Date().getTimezoneOffset() / 60);
    return hourOffset >= 0 ? `UTC+${hourOffset}` : `UTC-${Math.abs(hourOffset)}`;
}

export function getDeviceType() {
    return window.innerWidth <= 1023 ? 'mobile' : 'desktop';
}

export function getDeviceTypeByUserAgent() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
        return 'mobile';
    }
    return 'desktop';
}

export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isEdge() {
    return navigator.userAgent.indexOf('Edg') > -1;
}

export function getDrmId() {
    switch (true) {
        case isSafari():
            return 14;
        case isEdge():
            return 13;
        default:
            return 12;
    }
}

export function logDev(...message) {
    !process.env.NEXT_PUBLIC_PRODUCTION_MODE && console.log(...message);
}

export const emptyBackground = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
