import Page from '@/src/api/v3/models/Page';

export default class SearchPage extends Page {
    /** Всего элементов
     *  @type {number} */
    searchTotal;

    /**
     * @param {Object} object
     * @param {string} text
     */
    constructor(object, text) {
        super(object);
        this.searchTotal = object.searchTotal;
        // Fix
        if (text === '') {
            this.searchTotal = 1;
        }
        // Добавляем параметры поиска ко всем urn
        if (text.length >= 3 && object.searchTotal > 0) {
            this.showcases.forEach((showcase) => {
                showcase.urn = showcase.urn + '?text=' + encodeURI(text);
            });
        }
    }
}
