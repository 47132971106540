export const TARIFF_PLUS_ID = 101735;
export const TARIFF_STANDARD_ID = 101734;
export const TARIFF_VIP_ID = 101790;
const TARIFF_MOBILE = -1;

export default class WatchEverywhereStatus {
    dateFrom;
    dateTo;
    maxSlotCount;
    occupiedSlotCount;
    price;
    serviceId;
    isActive;
    title;
    tvSlotCount;
    freeSlotCount;

    /**
     * WatchEverywhereStatus constructor.
     * @param object
     */
    constructor(object) {
        this.dateFrom = object.date_from || null;
        this.dateTo = object.date_to || null;
        this.maxSlotCount = object.max_slot_count || null;
        this.occupiedSlotCount = object.occupied_slot_count || null;
        this.price = object.price || null;
        this.serviceId = object.service_id || null;
        this.isActive = object.status === '1';
        this.title = object.title || null;
        this.tvSlotCount = object.tv_slots_count || null;
        this.freeSlotCount = this.maxSlotCount - this.occupiedSlotCount || null;
    }

    /**
     * @returns boolean
     */
    isPlusAvailable() {
        return ![TARIFF_PLUS_ID, TARIFF_VIP_ID].includes(this.serviceId);
    }

    /**
     * @returns boolean
     */
    isPlus() {
        return this.serviceId === TARIFF_PLUS_ID;
    }

    /**
     * @returns boolean
     */
    isMobile() {
        return this.serviceId === TARIFF_MOBILE;
    }
}
