import SearchPage from '@/src/api/v3/models/SearchPage';
import { ApiMixin } from '@/src/api/ApiMixin';
import { buildUrl } from '@/src/api/v3/APIv3';

/**
 * Search
 * @see https://kblion.ertelecom.ru/pages/viewpage.action?pageId=168296758
 */

export class Search extends ApiMixin {
    /**
     * Загрузить страницу
     * @param {string} text
     * @returns {Promise<SearchPage>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    loadPage = async (text = '') => {
        const pageData = await this.loadPageData(text);
        return new SearchPage(pageData, text.trim());
    };

    /**
     * Загрузить страницу
     * @param {string} text
     * @returns {Promise<Object>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    loadPageData = async (text = '') => {
        const url = buildUrl('search', this.api.token, { params: 'text=' + encodeURI(text.trim()) });
        const response = await this.api.v3({ uri: url });
        return response['data'];
    };
}
