export default class PaymentCard {
    /** @type {?string} */
    firstDigits;
    /** @type {?string} */
    lastDigits;
    /** @type {?Number} - Expire date in YYYYMM */
    expiryDate;
    /** @type {?Number} */
    bindingId;

    /**
     * PaymentCard constructor.
     * @param {JSON} object
     * @param {string} object.first_digits
     * @param {string} object.last_digits
     * @param {Number} object.expiry_date
     * @param {Number} object.binding_id
     * */
    constructor(object) {
        this.firstDigits = object.first_digits;
        this.lastDigits = object.last_digits;
        this.expiryDate = object.expiry_date;
        this.bindingId = object.binding_id;
    }

    /**
     * @returns {string}
     */
    getTitle() {
        return `${this.firstDigits} **** ${this.lastDigits}`;
    }

    /**
     * @returns {string}
     */
    getExpiredTitle() {
        return `${this.expiryDate.toString().slice(-2)}/${this.expiryDate.toString().slice(2, 4)}`;
    }
}
