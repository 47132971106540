import { useContext } from 'react';
import AppContext from '@/src/contexts/app/state/AppContext';
import Notification from '@/src/models/Notification';

export default function useNotifications() {
    const {
        notifications: { dispatch: dispatchNotification },
    } = useContext(AppContext);

    /**
     * Show Error Notification
     * @param {string|Object} notification
     * @param {boolean} showAfterReload
     */
    const showError = (notification, showAfterReload = false) => {
        let title, message;
        if (typeof notification === 'string') {
            title = 'Внимание!';
            message = notification;
        } else {
            title = notification.title;
            message = notification.message;
        }
        const notificationObject = new Notification().error().setTitle(title).setMessage(message);
        if (showAfterReload) {
            notificationObject.showAfterReload();
        }
        dispatchNotification(notificationObject);
    };

    /**
     * Show Success Notification
     * @param {string|Object} notification
     * @param {boolean} showAfterReload
     */
    const showSuccess = (notification, showAfterReload = false) => {
        let title, message, subMessage;
        if (typeof notification === 'string') {
            title = notification;
        } else {
            title = notification.title;
            message = notification.message;
            subMessage = notification.subMessage;
        }
        const notificationObject = new Notification()
            .success()
            .setTitle(title)
            .setMessage(message)
            .setSubMessage(subMessage);
        if (showAfterReload) {
            notificationObject.showAfterReload();
        }
        dispatchNotification(notificationObject);
    };

    const showSmokeWarning = () => {
        dispatchNotification(new Notification().smoke());
    };

    const removeNotification = (id) => {
        dispatchNotification({ id, remove: true });
    };

    return { showError, showSuccess, showSmokeWarning, removeNotification };
}
