import { useMemo, useReducer } from 'react';
import 'styles/app.scss';
import 'styles/main.scss';
import modalReducer, { defaultModalContext } from '@/src/contexts/app/reducers/modalReducer';
import notificationReducer, { defaultNotificationContext } from '@/src/contexts/app/reducers/notificationReducer';
import DefaultLayout from '@/components/layouts/layout';
import AppContext, { getAppContext } from '@/src/contexts/app/state/AppContext';
import ApplicationComponent from '@/components/ApplicationComponent';
import { getInitialStoresState, RootStoreProvider } from '@/src/stores/RootStoreProvider';
import Head from 'next/head';
import App from 'next/app';
import lazySizes from 'lazysizes';
import supportedBrowsers from '@/src/constants/supported-browsers';
import { isBot } from '@/src/helpers/MiscHelper';

lazySizes['cfg'].loadMode = 1;
lazySizes['cfg'].expand = 10;
lazySizes['cfg'].loadHidden = false;

// noinspection JSUnusedGlobalSymbols
export default function MyApp({ Component, pageProps, initialStoreState }) {
    const [modal, dispatchModal] = useReducer(modalReducer, defaultModalContext, undefined);
    const [notifications, dispatchNotification] = useReducer(
        notificationReducer,
        defaultNotificationContext,
        undefined
    );

    const appContext = useMemo(
        () => getAppContext(notifications, dispatchNotification, modal, dispatchModal),
        [notifications, modal]
    );

    const Layout = Component.Layout ? Component.Layout : DefaultLayout;

    // noinspection HtmlRequiredTitleElement
    return (
        <RootStoreProvider initialState={initialStoreState}>
            <AppContext.Provider value={appContext}>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
                    />
                    {Component.pageTitle && <title>{Component.pageTitle}</title>}
                </Head>
                <ApplicationComponent />
                <Layout>
                    <Component {...pageProps} />
                </Layout>
            </AppContext.Provider>
        </RootStoreProvider>
    );
}

MyApp.getInitialProps = async (appContext) => {
    // Проверяем на поддерживаемые браузеры
    if (
        typeof window === 'undefined' &&
        !isBot(appContext.ctx.req.headers['user-agent']) &&
        !supportedBrowsers.test(appContext.ctx.req.headers['user-agent'])
    ) {
        appContext.ctx.res.writeHead(302, { Location: '/old-browser' });
        appContext.ctx.res.end();
    }

    const appProps = await App.getInitialProps(appContext);
    // На браузере инициализируем Store только один раз
    // При client-side page transitions through next/link не заполняем pageProps
    // noinspection JSUnresolvedVariable
    if (typeof window !== 'undefined' || appContext.ctx.req?.url.startsWith('/_next')) {
        return { ...appProps };
    }
    const initialStoreState = await getInitialStoresState(appContext.ctx);
    return { initialStoreState, ...appProps };
};
