import { useState } from 'react';
import useModals from '@/src/hooks/useModals';
import ModalForm from '@/components/modal/ModalForm';
import { ButtonClear, ButtonPrimary } from '@/components/button/Button';
import { logout } from '@/src/helpers/AuthHelper';

export default function ModalDialogExit() {
    const { closeModal } = useModals();

    const [submitting, setSubmitting] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();
        setSubmitting(true);
        logout();
    };

    const onHide = () => {
        closeModal();
    };

    return (
        <ModalForm
            className={'modal--primary modal--profile modal--title-margin'}
            closeButton={true}
            pageUrl="#logout"
            pageTitle="Выход из учётной записи"
            notHide={submitting}
        >
            <div className="modal__content-title">Выйти из&nbsp;учётной записи?</div>
            <form onSubmit={onSubmit}>
                <div className="modal__btnset">
                    <div className="modal__wrapper-btn">
                        <ButtonPrimary title="Выйти" type="submit" submitting={submitting} />
                    </div>
                    <div className="modal__wrapper-btn">
                        <ButtonClear title="Отмена" onClick={onHide} disabled={submitting} />
                    </div>
                </div>
            </form>
        </ModalForm>
    );
}
