import { FavoriteStatus, Quality, ShowcaseItemType } from '@/src/constants/types';
import BaseModel from '@/src/api/v3/models/BaseModel';
import Offers from '@/src/api/v3/models/mixins/Offers';
import { slug } from '@/src/modules/Common';
import { FILTER_ALL, FILTER_FAVORITE, FILTER_AVAILABLE } from '@/src/helpers/DataHelper';
import { PinReason } from '@/src/constants/pin-reason';
import ChannelPackage from '@/src/api/v3/models/ChannelPackage';

const POSTER_CHANNEL_GRID_BLUEPRINT = 'poster_channel_grid_blueprint';

/**
 *  Канал
 */
export default class Channel extends BaseModel {
    SLIDER_STUB_CLASS = 'cover--channels';
    images = ['newdesign_channel_logo_blueprint', POSTER_CHANNEL_GRID_BLUEPRINT];
    CARD_IMAGE_SIZE = { width: 182, height: 103 };
    EVENT_ITEM_CATEGORY = ShowcaseItemType.CHANNEL;

    /** Название типа
     *  @type {string} */
    typeName = 'Канал';
    /** Тип
     *  @type {string} */
    type = 'channel';
    /** Передачи
     *  @type {Children} */
    schedules;
    /** Текушая передача
     *  @type {Schedule} */
    schedule = null;
    /** Цвет фона
     *  @type {string} */
    backgroundColor;
    /** Номер канала
     *  @type {number} */
    lcn;
    /** Описание
     *  @type {string} */
    description;
    /** Качество
     *  @type {string} */
    quality;
    /** ID пакета каналов
     *  @type {number} */
    package_id;
    /** Список ПК, куда входит канал
     * @type {array} */
    packages = [];
    /** Доступен ли предпросмотр
     *  @type {boolean} */
    preview_available;
    /** Канал в избранном
     * @type {boolean} */
    isFavorite = false;
    /** Управляй просмотром
     *  @type {?string} */
    catchup = null;
    /** Категории для фильтров
     *  @type {Object[]} */
    categories = [];
    /** Фильтры
     *  @type {string[]} */
    filters = [];

    constructor(object) {
        if (object instanceof Channel) {
            super(object);
            this.preview_available = object.preview_available;
            this.offers = object.offers;
            this.isFavorite = object.isFavorite;
            this.lcn = object.lcn;
            this.backgroundColor = object.backgroundColor;
            this.description = object.description;
            this.quality = object.quality;
            this.catchup = object.catchup;
            this.packages = object.packages;
            this.categories = object.categories;
            this.filters = object.filters;
        } else {
            super(object);
            this.preview_available = object['previewAvailable'] ?? true;
            this.offers = new Offers(object);
            this.isFavorite = object.favorite?.type === FavoriteStatus.FAVORITE;
            this.lcn = object.lcn;
            this.backgroundColor = object.style?.backgroundColor;
            this.description = object.description;
            this.quality = object.quality?.type ?? Quality.SD;

            if (object.services) {
                for (const service of object.services) {
                    if (service.type === 'catchup') {
                        this.catchup = service.state.type;
                    }
                }
            }

            this.categories = object.categories || [];
            this.loadPackages(object);
            this.filters = this.getFilterCategories();
        }
    }

    loadPackages(object) {
        this.package_id = object.offer?.root?.id || object.offers?.showcases[0]?.items[0]?.root?.id;
        if (object.packages?.length) {
            object.packages.forEach((packageItem) => {
                this.packages.push(new ChannelPackage(packageItem));
            });
        } else if (this.package_id) {
            this.packages.push({
                id: this.package_id,
                title: object?.offer?.root?.title || '',
                offers: this.offers,
            });
        }
    }

    /**
     * Получить логотип канала
     * @returns null|string
     */
    getLogoSrc({ width = 100, height = 65 } = {}) {
        return this.resources.getResourceUrl(
            ['newdesign_channel_logo_blueprint', 'poster_blueprint', POSTER_CHANNEL_GRID_BLUEPRINT],
            {
                size: { width: width, height: height, func: 'crop' },
            }
        );
    }

    /**
     * HD качество
     * @returns boolean
     */
    isHD() {
        return this.quality === Quality.HD || this.quality === Quality.FOUR_THOUSAND;
    }

    /**
     * Есть ли Управляй просмотром на канале
     * @returns boolean
     */
    isCatchup() {
        return this.catchup !== null;
    }

    /**
     * URL для страницы телевидения
     * @returns string
     */
    getTVUrl() {
        return `/television/${slug(this.title)}-${this.id}`;
    }

    /**
     * Получить массив категории для фильтра
     * @returns string[]
     */
    getFilterCategories() {
        let filterCategories = [];
        if (this.packages) {
            this.packages.forEach((packageItem) => filterCategories.push(packageItem.id.toString()));
        }
        filterCategories.push(
            ...this.categories.map((category) => {
                return category.id.toString();
            })
        );
        if (!filterCategories.includes(FILTER_ALL)) {
            filterCategories.push(FILTER_ALL);
        }
        if (this.isFavorite && !filterCategories.includes(FILTER_FAVORITE)) {
            filterCategories.push(FILTER_FAVORITE);
        }
        if (this.isAvailable() && !filterCategories.includes(FILTER_AVAILABLE)) {
            filterCategories.push(FILTER_AVAILABLE);
        }
        return filterCategories;
    }

    /**
     * Get padded channel number string or empty string.
     *
     * @returns string
     */
    getNumberString() {
        if (!this.lcn) {
            return '';
        }
        return this.lcn.toString().padStart(3, '0');
    }

    /**
     * Получение URL элемента
     * @returns string
     */
    getUrl() {
        return this.getTVUrl();
    }

    /**
     * Доступен ли предпросмотр канала
     * @returns {Boolean}
     */
    isPreviewAvailable() {
        return (this.isAvailable() || this.preview_available) && !this.isAdult();
    }

    /**
     * Проверяет нужен ли ПИН для показа карточки модели
     * @param {?PinStatus} pinStatus
     * @param {Boolean} [isTvAdultSession]
     * @param {Boolean} [isTvSession]
     * @param {Boolean} [isAdultSession]
     * @returns ?string
     */
    getPinReason(pinStatus, isTvAdultSession, isTvSession, isAdultSession) {
        if (pinStatus) {
            if (this.isAdult() && !isTvAdultSession) {
                return PinReason.ADULT;
            }
            if (
                !isTvSession &&
                pinStatus.isEnabled() &&
                pinStatus.parentalControlEnabled &&
                ((this.age !== undefined && this.age >= pinStatus?.level) || pinStatus.channels.includes(this.id))
            )
                return PinReason.LOCKED;
        } else {
            return this.isAdult() ? PinReason.ADULT : null;
        }
        return null;
    }
}
