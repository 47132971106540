import { useEffect, useState } from 'react';

// TODO: Реализовать неасинхронный кеш для простых значений

/**
 * Hook for api getters
 * @param {function} fetcher
 * @param {boolean} fetch
 * @returns {any}
 * */
export function useApi(fetcher, fetch = true) {
    const cacheKey = fetcher.name;
    const [value, setValue] = useState();
    useEffect(() => {
        if (fetch) {
            fetcher
                .call(this)
                .then((value) => {
                    setValue(value);
                })
                .catch((error) => {
                    // TODO: Добавить вызов callback на ошибку
                    console.log('useApi error', error);
                });
        }
    }, [cacheKey, fetch, fetcher]);
    return value;
}
