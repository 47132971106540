import { useCallback, useContext } from 'react';
import AppContext from '@/src/contexts/app/state/AppContext';

export default function useModals() {
    const {
        modal: {
            dispatch: dispatchModal,
            current: { isOpen },
        },
    } = useContext(AppContext);

    /**
     * Open modal
     * @param {JSX.Element} body
     * @param {Boolean} isExitFromFullScreen
     */
    const openModal = useCallback(
        (body, isExitFromFullScreen = true) => {
            if (isExitFromFullScreen && document.fullscreenElement) {
                document.exitFullscreen().then();
            }
            dispatchModal({
                type: 'open',
                body: body,
            });
        },
        [dispatchModal]
    );

    const closeModal = () => {
        dispatchModal({ type: 'close' });
    };

    return { openModal, closeModal, isOpen };
}
