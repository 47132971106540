export default class Timezone {
    name;
    mskTimeShift;
    utcTimeShift;
    id;
    extId;

    /**
     * Timezone constructor.
     * @param data json
     */
    constructor(data) {
        this.name = data.title || null;
        this.mskTimeShift = data.msk_timeshift || null;
        this.utcTimeShift = data.utc_timeshift || null;
        this.id = data.id || null;
        this.extId = data.extid || null;
    }
}
