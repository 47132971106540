export const Subscriptions = {
    IVI: 3102186,
    KINOPOISK: 4139203,
    MOVIX_ONE: 9658082,
};

export const PartnerSubscriptions = {
    [Subscriptions.IVI]: {
        link: 'https://www.ivi.ru',
        watchTitle: 'Смотреть в Иви',
    },
    [Subscriptions.KINOPOISK]: {
        link: 'https://hd.kinopoisk.ru',
        watchTitle: 'Смотреть в Кинопоиске',
        partnerTitle: 'Уже есть подписка Плюс',
    },
};
