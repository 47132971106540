import Showcase, { RenderType } from '@/src/api/v3/models/Showcase';

/**
 * Trait Children
 */
export default class Children {
    /** Витрины
     * @type {Showcase[]} */
    showcases = [];

    /**
     * Загрузить параметры просмотра
     * @param object
     * @param [renderType]
     */
    constructor(object, renderType = RenderType.SLIDER) {
        if (object?.showcases && Array.isArray(object.showcases)) {
            let id = 0;
            object.showcases.forEach((showcaseObject) => {
                const showcase = new Showcase(showcaseObject);
                showcase.renderId = 'children-' + id++;
                showcase.renderType = renderType;
                if (renderType === RenderType.PLAIN) {
                    showcase.showHeader = false;
                }
                this.showcases.push(showcase);
            });
        }
    }
}
