import { plural } from '@/src/helpers/GrammarHelper';

function isSameDate(date1, date2) {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

/**
 * Получение времени в виде строки вида "02:10"
 * @param {Date} date
 * @returns string
 */
function timeFormat(date) {
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
}

/**
 * Получение периода в виде строки вида "Вт 10"
 * @param {Date} date
 * @returns string
 */
export function getPeriodDay(date) {
    const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    if (isSameDate(new Date(), date)) {
        return 'Сегодня';
    }
    return days[date.getDay()] + ' ' + date.getDate();
}

/**
 * Получение времени в виде строки вида "10, вт"
 * @returns string
 * @param date
 */
export function getTimeDay(date) {
    const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    if (isSameDate(new Date(), date)) {
        return 'Сегодня';
    }
    return date.getDate() + ', ' + days[date.getDay()];
}

/**
 * Получение времени в виде строки вида "16.10"
 * @returns string
 * @param {Date} date
 */
export function getDayMonth(date) {
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}`;
}

/**
 * Получение периода в виде строки вида "10:00-12:00"
 * @returns string
 * @param startDate
 * @param endDate
 */
export function getPeriodTime(startDate, endDate = null) {
    let result = timeFormat(startDate);
    if (endDate) {
        result = result + ' - ' + timeFormat(endDate);
    }
    return result;
}

/**
 * Получение периода в виде строки вида "Вт 10, 10:00"
 * @param date
 * @returns string
 */
export function getShortPeriod(date) {
    return getPeriodDay(date) + ', ' + getPeriodTime(date);
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @returns Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

export function getTimeString(time) {
    const totalSeconds = parseInt(time, 10);
    if (!totalSeconds) {
        return '-:-';
    }
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return (
        (hours === 0 ? '' : hours.toString().padStart(2, '0') + ':') +
        minutes.toString().padStart(2, '0') +
        ':' +
        seconds.toString().padStart(2, '0')
    );
}

/**
 * @returns string
 * @param {Number} period
 * @param {String} periodType
 */
const getPeriodTypeName = (period, periodType) => {
    switch (periodType) {
        case 'hour':
            return plural(period || 0, 'час', 'часа', 'часов');
        case 'day':
            return plural(period || 0, 'день', 'дня', 'дней');
        case 'month':
            return plural(period || 0, 'месяц', 'месяца', 'месяцев');
        case 'week':
            return plural(period || 0, 'неделя', 'недели', 'недель');
        default:
            return 'дней';
    }
};

/**
 * @returns string
 * @param {Number} period
 * @param {String} periodType
 */
export const getPeriodText = (period, periodType) => {
    return `${period} ${getPeriodTypeName(period, periodType)}`;
};

/**
 * @returns string
 * @param {Number} price
 * @param {Number} period
 * @param {String} periodType
 * @param {String} preText
 */
export const getPriceText = (price, period = 0, periodType = '', preText = 'в') => {
    const priceText = `${price} ₽`;
    if (preText === '') {
        return priceText;
    }
    switch (period) {
        case 0:
            return priceText;
        case 1:
            return `${priceText} ${preText} ${getPeriodTypeName(period, periodType)}`;
        default:
            return `${priceText} ${preText} ${period} ${getPeriodTypeName(period, periodType)}`;
    }
};
