export default function Pagination({ currentItemIndex = 0, items = [] }) {
    if (items.length <= 1) {
        return null;
    }

    return (
        <div className="modal__steps">
            {items.map((item, index) => (
                <div
                    className={`modal__steps-bullet${currentItemIndex === index ? ' modal__steps-bullet--active' : ''}`}
                    key={item.id}
                >
                    {' '}
                </div>
            ))}
        </div>
    );
}
