import styles from './ButtonSandwich.module.scss';

export default function ButtonSandwich({ modifier, label, onClick }) {
    return (
        <button
            className={styles['button-sandwich'] + (modifier ? ' ' + modifier : '')}
            aria-label={label}
            onClick={onClick}
        >
            <span className="button-sandwich__inner"></span>
        </button>
    );
}
