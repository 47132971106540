/**
 * @param {Object} state
 * @param {boolean} state.isOpen
 * @param {JSX.Element} state.body
 * @param {number} state.scrollPos
 * @param {number} state.scrollbarWidth
 * @param {Object} action
 * @param {string} action.type
 * @param {JSX.Element} [action.body]
 * */
export default function modalReducer(state, action) {
    switch (action.type) {
        case 'open':
            state.scrollPos = Math.max(window.scrollY, document.documentElement.scrollTop, document.body.scrollTop);
            state.body = action.body;
            if (!state.isOpen) {
                state.scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            }
            state.isOpen = true;
            document.documentElement.classList.add('scroll-disable');
            return { ...state };
        case 'close':
            document.documentElement.classList.remove('scroll-disable');
            return { ...state, isOpen: false, body: '' };
        default:
            throw new Error();
    }
}

/**
 * @param {boolean} isOpen
 * @param {JSX.Element} body
 * @param {number} scrollPos
 * */
export const defaultModalContext = {
    isOpen: false,
    body: null,
    scrollPos: 0,
    scrollbarWidth: 0,
};

// noinspection JSUnusedLocalSymbols
/**
 * @param {Object} action
 * @param {string} action.type
 * @param {JSX.Element} [action.body]
 * */
export const defaultModalDispatch = (action) => {
    // This is intentional
};
