/**
 * Trait Viewable
 */
export default class Viewable {
    /** Текущая позиция
     *  @type {number} */
    position;
    /** Продолжительность
     *  @type {number} */
    duration;

    /**
     * Загрузить параметры просмотра
     * @param object
     */
    constructor(object) {
        this.position = object?.position ?? 0;
        this.duration = object?.duration ?? 0;
    }

    /**
     * Получить прогресс просмотра (в %)
     * @returns number
     */
    getProgress() {
        if (this.duration > 0) {
            return (100 * this.position) / this.duration;
        }
        return 0;
    }

    /**
     * @returns boolean
     */
    isProgress() {
        return this.duration > 0 && this.position > 0;
    }

    /**
     * Получить длительность
     * @returns string
     */
    getDurationText() {
        if (this.duration) {
            const hours = Math.floor(this.duration / 3600);
            const minutes = Math.floor((this.duration - hours * 3600) / 60);

            let tmp = [];
            if (hours) {
                tmp.push(`${hours} ч`);
            }
            if (minutes) {
                tmp.push(`${minutes} мин`);
            }
            return tmp.join(' ');
        }
        return '';
    }
}
