import BaseModel from '@/src/api/v3/models/BaseModel';
import Children from '@/src/api/v3/models/mixins/Children';

/**
 *  Сезона сериала
 */
export default class Season extends BaseModel {
    URL_PREFIX = 'seasons';
    SLIDER_STUB_CLASS = 'cover--movies';

    /** Название типа
     *  @type {string} */
    typeName = 'Сезон';
    /** Сезоны
     * @type {Children} */
    episodes;
    /** Возрастной рейтинг
     * @type {number} */
    age;
    /** Дата выпуска
     * @type {?Date} */
    releasedAt;
    /** Жанры
     *  @type {string[]} */
    genres = [];
    /** Страны
     *  @type {string[]} */
    countries = [];
    /** Описание
     * @type {string} */
    description;
    /** Номер сезона
     * @type {number} */
    number;

    constructor(object) {
        super(object);
        this.description = object.description;
        this.number = object.number;
        this.releasedAt = object.releasedAt ? new Date(object.releasedAt) : null;

        if (object.genres) {
            object.genres.forEach((genre) => {
                this.genres.push(genre.title);
            });
        }

        if (object.countries) {
            object.countries.forEach((country) => {
                this.countries.push(country.title);
            });
        }

        this.children = new Children(object.episodes);
        this.episodes = this.children;
    }

    /**
     * Получение изображения
     * @returns null|string
     * @param options
     */
    getImageSrc(options) {
        if (!options) {
            options = { size: { width: '166', height: '244' } };
        }
        return this.resources.getResourceUrl('poster_blueprint', options);
    }
}
