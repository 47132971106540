export default class BillingDevice {
    /** Identifier of terminal resource in the billing
     * @type {?number} */
    trId;
    /** Name of terminal resource similar to nuid parameter.
    (nuid - for STBs Humax9000, Kaon, МАС-address - for STBs IPTV, Mag, card_id - for CAM-modules, smart_card_id for Humax7000,
    'multiscreen' - terminal resource of connecting packages without digital cable TV on the agreement)
     * @type {?string} */
    trName;
    /** [deprecated] Name of terminal resource. Similar to parameter tr_name
     * @type {?string} */
    nuid;

    /**
     * BillingDevice constructor.
     * @param {Object} data
     * @param {?number} data.tr_id
     * @param {?string} data.tr_name
     * @param {?string} data.nuid
     */
    constructor(data) {
        this.trId = data.tr_id || null;
        this.trName = data.tr_name || null;
        this.nuid = data.nuid || null;
    }
}
