import BaseModel from '@/src/api/v3/models/BaseModel';
import Children from '@/src/api/v3/models/mixins/Children';
import Showcase, { RenderType } from '@/src/api/v3/models/Showcase';
import { ShowcaseItemType } from '@/src/constants/types';

/**
 *  ТВ-шоу
 */
export default class TvShow extends BaseModel {
    SLIDER_STUB_CLASS = 'cover--packages';
    URL_PREFIX = 'tv-shows';
    EVENT_ITEM_CATEGORY = ShowcaseItemType.TV_SHOW;
    images = ['3_smarttv_tvshow_background_video_library_blueprint'];

    /** Описание
     *  @type {string} */
    description;
    /** @type {?Date} Дата выпуска */
    releasedAt;
    /** @type {number[]} Оценки */
    scores = [];
    /** @type {string[]} Страны */
    countries = [];
    /** @type {string[]} Жанры */
    genres = [];
    /** @type {Showcase[]} Персоны */
    persons = [];

    constructor(object) {
        super(object);
        this.children = object.schedules ? new Children(object?.schedules, RenderType.PLAIN) : null;
        this.description = object.description;
        if (object.score) {
            object.score.forEach((score) => {
                this.scores[score.type] = score.value;
            });
        }
        if (object.countries) {
            object.countries.forEach((country) => {
                this.countries.push(country.title);
            });
        }

        this.releasedAt = object.releasedAt ? new Date(object.releasedAt) : null;

        if (object.genres) {
            object.genres.forEach((genre) => {
                this.genres.push(genre.title);
            });
        }

        if (object?.persons?.showcases) {
            object.persons.showcases.forEach((showcase_object) => {
                let showcase = new Showcase(showcase_object);
                this.persons.push(showcase);
            });
        }
    }

    /**
     * Получить изборажение для фона
     * @returns null|string
     */
    getBackgroundSrc() {
        return this.resources.getResourceUrl('3_smarttv_tvshow_background_video_library_blueprint');
    }
}
