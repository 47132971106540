import ApiToken from '@/src/models/ApiToken';
import { APP_VERSION, QUERY_GET, QUERY_POST } from '@/src/api/Api';
import ApiBackendException from '@/src/api/ApiBackendException';

export default class APIv3 {
    static isPostResponseEmpty = true;

    static validateJson = (url, response) => {
        if (response.error) {
            let message;
            if (response.error.details) {
                message = response.error.details[0].message;
            } else {
                /** @namespace response.error.message_rus **/
                message = response.error.message_rus || response.error.message;
            }
            throw new ApiBackendException(message || 'Ошибка при запросе', response.details?.[0]?.type || '');
        }
        return response;
    };

    static httpQuery = async (url, token, data = {}, method = QUERY_GET, useHeaders = true) => {
        let headers = {};
        if (useHeaders) {
            headers['x-auth-token'] = token;
            headers['View'] = 'stb3';
            headers['X-App-Version'] = APP_VERSION;
            if (method === QUERY_POST) {
                const JSONData = JSON.stringify(data);
                headers['Content-Type'] = 'application/json';
                headers['Content-Length'] = JSONData.length;
            }
        }
        let response;
        if (method === QUERY_GET) {
            let queryString = data
                ? '?' +
                  Object.keys(data)
                      .map((key) => key + '=' + data[key])
                      .join('&')
                : '';
            response = await fetch(url + queryString, {
                method: 'GET',
                headers: headers,
            });
        } else {
            let queryString = data ? JSON.stringify(data) : '';
            response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: queryString,
            });
        }
        return response;
    };
}

/**
 * Получить url с добавленным к нему фильтром родительского контроля (см. WEB-2130)
 * @param {string }url
 * @param {string} token
 * @returns string
 * @throws ApiBackendException
 * @throws ApiNetworkException
 */
const getUrlWithAdultFilter = (url, token) => {
    const adultFilter = isGuestToken(token) ? 'adult=NOT_ADULT' : 'adult=ADULT,NOT_ADULT';
    return url + (url.indexOf('?') >= 0 ? '&' : '?') + adultFilter;
};

const isGuestToken = (token) => {
    return token ? ApiToken.isGuest(token) : true;
};

/**
 * @returns string
 * @throws ApiBackendException
 * @throws ApiNetworkException
 * @param {string} uri
 * @param {string} token
 * @param {?string} params
 * @param {?Number} offset
 * @param {?Number} limit
 * @param {?Number} childrenItemLimit
 * @param {?Number} childrenShowcaseLimit
 */
export const buildUrl = (
    uri,
    token,
    { params = null, offset = null, limit = null, childrenItemLimit = null, childrenShowcaseLimit = null }
) => {
    let url = getUrlWithAdultFilter(uri, token);
    if (params) {
        url += (url.indexOf('?') >= 0 ? '&' : '?') + params;
    }
    if (offset) {
        url += (url.indexOf('?') >= 0 ? '&' : '?') + 'offset=' + offset;
    }
    if (limit) {
        url += (url.indexOf('?') >= 0 ? '&' : '?') + 'limit=' + limit;
    }
    if (childrenItemLimit) {
        url += (url.indexOf('?') >= 0 ? '&' : '?') + 'childrenItemLimit=' + childrenItemLimit;
    }
    if (childrenShowcaseLimit) {
        url += (url.indexOf('?') >= 0 ? '&' : '?') + 'childrenShowcaseLimit=' + childrenShowcaseLimit;
    }
    return '/api/v3/' + url;
};
