// noinspection JSUnusedGlobalSymbols
import { QUERY_POST } from '@/src/api/Api';
import { ApiMixin } from '@/src/api/ApiMixin';

const TEMP_ENDPOINT =
    process.env.NEXT_PUBLIC_PRODUCTION_MODE === '1'
        ? 'http://web-game.public.svc:8010' // NOSONAR http is safe here
        : 'http://web-game.public-feature-k-34592448.svc:8010'; // NOSONAR http is safe here

export class Game extends ApiMixin {
    getAttempt = async (agr) => {
        const endpoint = this.api.endpoint;
        this.api.endpoint = TEMP_ENDPOINT;
        let points;
        try {
            const response = await this.api.v3({
                uri: '/api/v3/game/attempt',
                params: { agr },
            });
            points = response['points'];
        } catch (e) {
            points = e.toString() === 'Error: 404' ? -1 : undefined;
        } finally {
            this.endpoint = endpoint;
        }
        return points;
    };

    postAttempt = async ({ agr, name, email, points }) => {
        const endpoint = this.api.endpoint;
        this.api.endpoint = TEMP_ENDPOINT;
        try {
            await this.api.v3({
                uri: '/api/v3/game/attempt',
                params: { agr, name, email, points },
                method: QUERY_POST,
            });
        } finally {
            this.endpoint = endpoint;
        }
    };

    getTop100 = async () => {
        const endpoint = this.api.endpoint;
        this.api.endpoint = TEMP_ENDPOINT;
        let response = {};
        try {
            response = await this.api.v3({
                uri: '/api/v3/game/attempt/top100',
            });
        } finally {
            this.endpoint = endpoint;
        }
        return response;
    };
}
