import BaseModel from '@/src/api/v3/models/BaseModel';

/**
 *  Баннер
 */
export default class Banner extends BaseModel {
    /** @type {string} */
    description;
    /** @type {string} */
    imageSrc;
    /** @type {string} */
    url;

    constructor(object) {
        super(object);
        this.description = object.description;
        this.imageSrc = object.imageSrc;
        this.url = object.url;
    }

    /**
     * Получение изображения
     */
    getImageSrc(options = null) {
        return this.imageSrc;
    }

    /**
     * Получение URL элемента
     * @returns string
     */
    getUrl() {
        return this.url;
    }
}
