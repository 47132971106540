import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import useModals from '@/src/hooks/useModals';
import { useApiStore, useAuthStore } from '@/src/stores/RootStoreProvider';
import ModalOnboardingWelcome from '@/components/modal/onboarding/welcome/ModalOnboardingWelcome';
import Common from '@/src/modules/Common';
import Recommendations from '@/src/modules/events/Recommendations';
import useCardPayments from '@/src/hooks/useCardPayments';
import { hit } from '@/src/modules/events/YandexMetrika';
import { initGTM } from '@/src/modules/events/GTM';

export default function ApplicationComponent() {
    const { api } = useApiStore();
    const { isGuest } = useAuthStore();
    const { initCardPayments } = useCardPayments();
    const { openModal, closeModal } = useModals();
    const router = useRouter();
    const progressTimeoutRef = useRef(null);

    /** @namespace window.NProgress (/assets/libs/nprogress.js) **/
    const onRouteChangeStart = () => {
        progressTimeoutRef.current = setTimeout(() => {
            if (typeof window.NProgress !== 'undefined') {
                window.NProgress.start();
            }
        }, 500);
    };

    const onRouteChangeComplete = () => {
        clearTimeout(progressTimeoutRef.current);
        if (typeof window.NProgress !== 'undefined') {
            window.NProgress.done();
        }
        hit();
        closeModal();
    };

    const onRouteChangeError = () => {
        clearTimeout(progressTimeoutRef.current);
        if (typeof window.NProgress !== 'undefined') {
            window.NProgress.done();
        }
    };

    useEffect(() => {
        router.events.on('routeChangeStart', onRouteChangeStart);
        router.events.on('routeChangeComplete', onRouteChangeComplete);
        router.events.on('routeChangeError', onRouteChangeError);

        return () => {
            router.events.off('routeChangeStart', onRouteChangeStart);
            router.events.off('routeChangeComplete', onRouteChangeComplete);
            router.events.off('routeChangeError', onRouteChangeError);
        };
    });

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        clearTokenUrl();
        initGTM(isGuest, api.token);
        onBoarding().catch(() => {
            // Nothing
        });
        initCardPayments();

        Recommendations.startupCheck();
        Recommendations.startInterval();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearTokenUrl = async () => {
        if (Common.getURLParameter('token') && !Common.getURLParameter('utm_source')) {
            Common.clearUrl();
        }
    };

    const onBoarding = async () => {
        if (!isGuest) {
            const welcomeItems = await api.welcomeItems.getWelcomeItems();
            if (welcomeItems && welcomeItems.length > 0) {
                openModal(<ModalOnboardingWelcome items={welcomeItems} />);
            }
        }
    };

    return process.env.NEXT_PUBLIC_PRODUCTION_MODE ? <></> : <div id={'box'} className="box fade-out" />;
}
