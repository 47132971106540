import BaseModel from '@/src/api/v3/models/BaseModel';
import md5 from 'md5';
import { LIMIT_RESULTS } from '@/src/helpers/DataHelper';
import getModelByObject from '@/src/api/v3/models/helpers/ShowcaseHelper';
import { getShowcaseUrl } from '@/src/helpers/ShowcaseHelper';
import { ShowcaseItemType } from '@/src/constants/types';

export const ShowcaseType = {
    LIST: 'list',
    SLIDER: 'slider',
    DETAILS: 'details',
};

export const RenderType = {
    NONE: 0,
    SLIDER: 1,
    PLAIN: 2,
    TABS: 3,
    HTML: 4,
    SLIDER_BIG: 5,
};

/**
 *  Витрина
 */
export default class Showcase extends BaseModel {
    renderType = RenderType.SLIDER;
    /** ID Для отрисовки
     * @type {string} */
    renderId;
    /** Элементы
     *   @type {BaseModel[]} */
    items = [];
    /** Всего элементов
     *  @type {number} */
    total;
    /** Субназвание
     *  @type {?string} */
    subtitle = null;
    /** Рисовать ли заголовок
     *  @type {boolean} */
    showHeader = true;
    /** Рисовать ли кнопку "Все"
     *  @type {boolean} */
    showTotalButton = true;
    /** Класс для добавления в секцию
     *  @type {string} */
    class = '';
    /** HTML-содержимое витрины
     * @type {JSX.Element|string} */
    html = '';
    /** Название страницы
     *  @type {string} */
    pageTitle = '';
    /** Дополнительные пераметры для подгрузки элементов в витрину
     * @type {?string} */
    params = null;
    /** Количество элементов на странице
     *  @type {number} */
    elements = 4;
    /** Класс для витрины
     *  @type {string} */
    className = 'catalog--subs';

    /**
     * Загрузить модель из объекта
     * @param object
     * @throws Exception
     */
    constructor(object) {
        super(object);
        if (object['hideTitle']) {
            this.showHeader = false;
        }
        this.renderId = this.id?.toString();
        this.items = [];
        if (object.items && Array.isArray(object.items)) {
            object.items.forEach((objectItem) => {
                const item = getModelByObject(objectItem);
                if (item) {
                    this.items.push(item);
                }
            });
        }
        this.total = object.total;
        if (this.type === ShowcaseType.DETAILS) {
            this.renderType = RenderType.NONE;
        }
        if (this.type === ShowcaseType.SLIDER) {
            this.renderType = RenderType.SLIDER_BIG;
        }
    }

    /**
     * Возвращает хеш витрины
     * @returns string
     */
    getHash() {
        let hash = '';
        this.items.forEach((item) => {
            hash = md5(hash + item.getHash());
        });
        return hash;
    }

    /**
     * Получение URL элемента
     * @returns string
     */
    getUrl() {
        return getShowcaseUrl(this.urn);
    }

    /**
     * Загрузить элементы
     * @param {Api} api
     * @param {Number} limit
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    async loadItems(api, limit) {
        const showcase = await api.showcases.loadShowcase({
            urn: this.urn,
            params: this.params,
            offset: this.items.length,
            limit: limit,
        });
        this.total = showcase.total;
        this.items = this.items.concat(showcase.items);
    }

    /**
     * Загрузить все элементы
     * @param {Api} api
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    async loadAllItems(api) {
        while (this.items.length < this.total) {
            await this.loadItems(api, LIMIT_RESULTS);
        }
    }

    /**
     * Получить кол-во элементов на странице и класс
     * @returns {{elements: (number), className: (string)}} info
     */
    getElementsInfo() {
        let elements;
        let className;
        const item = this.items[0];
        switch (item?.type) {
            case ShowcaseItemType.MOVIE:
            case ShowcaseItemType.SERIAL:
            case ShowcaseItemType.CHANNEL:
                elements = 8;
                className = '';
                break;
            case ShowcaseItemType.SCHEDULE:
                elements = 6;
                className = 'catalog--program';
                break;
            case ShowcaseItemType.EPISODE:
                elements = 6;
                className = 'catalog--serial';
                break;
            default:
                elements = this.elements;
                className = this.className;
                break;
        }
        return {
            elements: elements,
            className: className,
        };
    }

    /**
     * Получить класс элементов
     * @returns string
     */
    getItemClass() {
        switch (this.getElementsInfo().elements) {
            case 4:
                return 'catalog__item-slider--packages';
            case 6:
                return 'catalog__item-slider--shows';
            default:
                return ' ';
        }
    }

    /**
     * Получить класс элементов
     * @returns string
     */
    getItemClassPlain() {
        return this.getElementsInfo().className;
    }

    /**
     * Получить селектор
     * @returns string
     */
    getSelectorId() {
        return ('showcase-' + (this.renderId || this.uniqueId)).replace(/[^\w\s]/gi, '-');
    }
}
