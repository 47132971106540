export class ApiMixin {
    /** @type {Api} */
    api;

    /**
     * @param {Api} api
     */
    constructor(api) {
        this.api = api;
    }
}
