/**
 * ShowcaseItemType
 * @enum {string}
 */
export const ShowcaseItemType = {
    MOVIE: 'movie',
    GENRE: 'genre',
    PACKAGE: 'package',
    SERIAL: 'serial',
    SUBSCRIPTION: 'subscription',
    CHANNEL: 'channel',
    SCHEDULE: 'schedule',
    OFFER: 'offer',
    PERSON: 'person',
    CHANNEL_PACKAGE: 'channel-package',
    SUGGEST_RICH: 'rich',
    SUGGEST_AUTOCOMPLETE: 'autocomplete',
    PROGRAM: 'program',
    EPISODE: 'episode',
    SEASON: 'season',
    COLLECTION: 'collection',
    TV_SHOW: 'tv-show',
    GIFT: 'gift',
    PROMOTION: 'promotion',
    BANNER: 'banner',
    BUNDLE: 'bundle',
};

/**
 * FavoriteStatusType
 * @enum {string}
 */
export const FavoriteStatus = {
    NOT_FAVORITE: 'not-favorite',
    FAVORITE: 'favorite',
};

/**
 * EstimateType
 * @enum {string}
 */
export const Vote = {
    NEUTRAL: 'neutral',
    LIKE: 'like',
    DISLIKE: 'dislike',
};

/**
 * AdultStateType
 * @enum {string}
 */
export const AdultState = {
    ADULT: 'adult',
    NOT_ADULT: 'not-adult',
};

/**
 * AvailableStateType
 * @enum {string}
 */
export const AvailableState = {
    AVAILABLE: 'available',
    NOT_AVAILABLE: 'not-available',
    CANCELING_PURCHASE: 'canceling-purchase',
    FREE: 'free',
    TRIAL: 'trial',
    SUSPEND_BY_USER: 'suspend-by-user',
    GIFT: 'gift',
    IN_PURCHASE: 'in-purchase',
    PENDING_BOUGHT: 'pending-bought',
};

// noinspection JSUnusedGlobalSymbols
/**
 * SpecialOfferStateType
 * @enum {string}
 */
export const SpecialOfferState = {
    NEW: 'new',
    VIEWED: 'viewed',
    ACTIVATED: 'activated',
    DEACTIVATED: 'deactivated',
};

/**
 * QualityType
 * @enum {string}
 */
export const Quality = {
    SD: 'sd',
    HD: 'hd',
    FOUR_THOUSAND: 'four-thousand',
};

/**
 * PaymentStatus
 * @enum {Number}
 */
export const PaymentStatus = {
    SUCCESS: 1,
    IN_PROGRESS: 2,
};
