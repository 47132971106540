import Common from '@/src/modules/Common';
import { CACHE_CONFIG, SECONDS_IN_DAY } from '@/src/api/cache.config';
import md5 from 'md5';

const MAX_IN_MEMORY_CACHE_SIZE = 5 * 1024 * 1024;

class ApiCache {
    memoryCache = {};

    get(config, token = null) {
        let cache;
        if (typeof window === 'undefined') {
            if (config.storeOnServerOnly) {
                cache = this.memoryCache[config.key];
            }
        } else if (config.storeInMemory) {
            cache = this.memoryCache[config.key];
        } else {
            cache = typeof localStorage !== 'undefined' && localStorage.getItem(config.key);
        }
        if (cache) {
            let data = JSON.parse(cache);
            if (
                data.value &&
                data.expired > Common.getTime() &&
                (config.anyToken || data.token === md5(token) || config.storeOnServerOnly)
            ) {
                return data.value;
            }
        }
    }

    set(config, response, token) {
        let data = {
            value: response,
            expired: Common.getTime() + (config.invalidationTime || SECONDS_IN_DAY),
        };
        if (!config.anyToken) {
            data.token = md5(token);
        }
        try {
            if (typeof window === 'undefined') {
                if (config.storeOnServerOnly) {
                    this.memoryCache[config.key] = JSON.stringify(data);
                }
            } else if (config.storeInMemory) {
                const size = JSON.stringify(this.memoryCache).length;
                if (size > MAX_IN_MEMORY_CACHE_SIZE) {
                    this.memoryCache = {};
                }
                this.memoryCache[config.key] = JSON.stringify(data);
            } else if (typeof localStorage !== 'undefined') {
                localStorage.setItem(config.key, JSON.stringify(data));
            }
        } catch (error) {
            return false;
        }
    }

    remove(key) {
        if (typeof localStorage !== 'undefined') {
            return localStorage.removeItem(key);
        }
    }

    removeSubscriptionCaches() {
        this.remove('/api/v3/showcases/library/subscriptions?adult=ADULT,NOT_ADULT&limit=48');
        this.remove('/api/v3/showcases/library/channel-packages?adult=ADULT,NOT_ADULT&limit=48');
        this.remove('/api/v3/channels');
    }

    removeCatchupCaches() {
        this.remove('/api/v3/channels');
        this.remove('/er/catchup/status');
    }

    removeWatchEverywhereCaches() {
        this.remove('/er/watch_everywhere/status');
    }

    removeBundleCaches() {
        this.remove('/api/v3/showcases/library/subscriptions?adult=ADULT,NOT_ADULT&limit=48');
        this.remove('/api/v3/showcases/library/channel-packages?adult=ADULT,NOT_ADULT&limit=48');
        this.remove('/api/v3/channels');
        this.remove('/er/catchup/status');
        this.remove('/er/multiscreen/status');
        this.remove('/er/billing/devices');
        this.remove('/er/multiscreen/devices');
    }

    clearMemoryCache() {
        this.memoryCache = {};
    }

    clearAllKeyCache() {
        Object.keys(CACHE_CONFIG).forEach((key) => {
            this.remove(key);
        });
    }
}

const apiCache = new ApiCache();

export default apiCache;
