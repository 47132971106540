export const GENRES = {
    18: 'Боевики',
    25: 'Вестерны',
    39: 'Детективы',
    53: 'Для детей',
    95: 'Комедии',
    102: 'Мелодрамы',
    116: 'Мультфильмы',
    151: 'Приключения',
    186: 'Спорт',
    200: 'Триллеры',
    214: 'Ужасы',
    221: 'Фантастика',
    11: 'Биографии',
    32: 'Военные',
    60: 'Документальные',
    67: 'Драмы',
    81: 'Исторические',
    109: 'Музыкальный',
    172: 'Для всей семьи',
};

export const ADULT_GENRES = {
    242: 'Для взрослых',
};

export const MENU_GENRES = {
    18: 'Боевики',
    25: 'Вестерны',
    39: 'Детективы',
    53: 'Для детей',
    95: 'Комедии',
    102: 'Мелодрамы',
    116: 'Мультфильмы',
    151: 'Приключения',
    186: 'Спорт',
    200: 'Триллеры',
    214: 'Ужасы',
    221: 'Фантастика',
};
