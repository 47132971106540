import Channel from '@/src/api/v3/models/Channel';
import { ApiMixin } from '@/src/api/ApiMixin';

/**
 * Trait Channels
 * @see https://kblion.ertelecom.ru/display/BA/v3-Channels
 */
export class Channels extends ApiMixin {
    /** Getting list of channels available to user (Получение списка каналов доступных пользователю)
     * @returns {Promise<Channel[]>} - Channels
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getChannels = async () => {
        const response = await this.api.v3({ uri: '/api/v3/channels' });
        let result = [];
        /** @namespace response.data **/
        response.data.forEach((channelData) => {
            result.push(new Channel(channelData));
        });
        return result;
    };
}
