import { QUERY_POST } from '@/src/api/Api';
import { ApiMixin } from '@/src/api/ApiMixin';

export class Authorization extends ApiMixin {
    /**
     * Получение SSO-токена по токену биллинга
     * @param {string} accessToken - billing access token
     * @param {string} region - identifier of the region to which the login belongs
     * @returns {Promise<string>}
     */
    getSSOTokenByAccessToken = async (accessToken, region) => {
        const response = await this.api.v2({
            uri: '/er/ssoauth/auth/by_access_token',
            params: {
                access_token: accessToken,
                region: region,
            },
            method: QUERY_POST,
            useHeaders: false,
        });
        return response['sso'];
    };

    /**
     * Получение SSO-токена по токену биллинга и id города биллинга
     * @param {string} accessToken
     * @param {string} city_id
     * @returns {Promise<string>}
     */
    getSSOTokenByAccessTokenAndCityId = async (accessToken, city_id) => {
        const response = await this.api.v2({
            uri: '/er/ssoauth/auth/by_access_token_city_id',
            params: {
                access_token: accessToken,
                city_id: city_id,
            },
            method: QUERY_POST,
            useHeaders: false,
        });
        return response['sso'];
    };

    /**
     * End-to-end authorization in by referal link
     * @param {Object} referalArgs json object with billing args
     * @returns {Promise<string>}
     */
    getSSOTokenByReferalLink = async (referalArgs) => {
        const response = await this.api.v2({
            uri: '/er/ssoauth/auth/end_to_end',
            params: { referal_args: JSON.stringify(referalArgs) },
            method: QUERY_POST,
            useHeaders: false,
        });
        return response['sso'];
    };

    /**
     * Getting a device token
     * For Interzet clients looks like - "mac:<mac-address>", for example, "mac:FF:7C:7D:EA:4D:11"
     * @param {string} [deviceId]
     * @returns {Promise<string>}
     */
    getDeviceToken = async (deviceId = '123') => {
        const response = await this.api.v2({
            uri: '/token/device',
            params: {
                client_id: 'er_ottweb_device',
                timestamp: Date.now(),
                device_id: deviceId,
            },
            useHeaders: false,
        });
        return response['token'];
    };

    /**
     * Get sso key by auth token from external system.
     * @param {string} authToken
     * @param {string} dealerId
     * @returns {Promise<string>}
     */
    getSSOAuthKeyByAuthToken = async (authToken, dealerId) => {
        const response = await this.api.v2({
            uri: '/er/ssoauth/auth/by_auth_token',
            params: {
                auth_token: authToken,
                dealer_id: dealerId,
            },
            method: QUERY_POST,
        });
        return response['sso'];
    };

    /**
     * Token refresh
     * @returns {Promise<string>}
     */
    tokenRefresh = async () => {
        const response = await this.api.v2({
            uri: '/token/refresh',
        });
        return response['token'];
    };

    /**
     * Get sso key by auth token from external system
     * @param {string} SSOKey - SSO-ключ полученный от системы авторизации
     * @param {string} [SSOSystem] - Идентификатор используемой системы SSO-авторизации
     * @returns {Promise<string>}
     */
    getTokenBySSOKey = async (SSOKey, SSOSystem = 'er') => {
        const response = await this.api.v2({
            uri: '/token/subscriber_device/by_sso',
            params: {
                sso_key: SSOKey,
                sso_system: SSOSystem,
            },
        });
        return response['token'];
    };

    /**
     * Sending a letter or an sms
     * @param {number} agreementId
     * @param {string} region
     * @param {string} sendType
     * @param {string} contactId
     * @returns {void}
     */
    sendAuthRecovery = async (agreementId, region, sendType, contactId) => {
        await this.api.v2({
            uri: '/er/ssoauth/recovery/send/',
            params: {
                agreement_id: agreementId,
                region: region,
                send_type: sendType,
                contact_id: contactId,
            },
            method: QUERY_POST,
        });
    };

    /**
     * Возвращает SSO ключ для авторизации
     * @param {string} username
     * @param {string} password
     * @param {string} region
     * @returns {Promise<string>}
     */
    getSSOKey = async (username, password, region) => {
        const response = await this.api.v2({
            uri: '/er/ssoauth/auth',
            params: {
                username: username,
                password: password,
                region: region,
            },
            method: QUERY_POST,
        });
        return response['sso'];
    };

    /**
     * Checking the SMS code and getting the subscriber token
     * @param {string} phone - The phone number specified in the agreement for which the SMS will be sent in the verification code
     * @param {string} region - Subscriber domain (region)
     * @param {number} agreementId - ID agreement. If the previous request returned more than one agreement tied to this phone number
     * @param {string} smsCode - SMS code received on the phone
     * @returns {Promise<Object>}
     * @see https://kblion.ertelecom.ru/display/BA/01.+Auth+by+SMS
     */
    checkAuthSMS = async (phone, region, agreementId, smsCode) => {
        return await this.api.v2({
            uri: '/er/sms/check',
            params: {
                phone: phone,
                region: region,
                sms_code: smsCode,
                ...(agreementId ? { agr_id: agreementId } : {}),
            },
            method: QUERY_POST,
        });
    };

    /**
     * @typedef {Object} Contract
     * @property {number} id
     * @property {string} title
     */

    /**
     * @typedef {Object} SendAuthSMSResponse
     * @property {boolean} SMSSent - the SMS is sent to the phone
     * @property {Contract[]} contracts
     * @property {string} SMSErrorText
     * @property {string} SMSErrorCode
     */

    /**
     * Sending sms code to phone
     * @param {string} phone - The phone number specified in the agreement for which the SMS will be sent in the verification code
     * @param {string} region - Subscriber domain (region)
     * @param {number} [contractId] - ID agreement.
     * If the previous request returned more than one agreement tied to this phone number
     * @returns {Promise<SendAuthSMSResponse>}
     * @see https://kblion.ertelecom.ru/display/BA/01.+Auth+by+SMS
     */
    sendAuthSMS = async (phone, region, contractId) => {
        let params = {
            phone: phone,
            region: region,
        };
        if (contractId) {
            params.agr_id = contractId;
        }
        const response = await this.api.v2({
            uri: '/er/sms/auth',
            params: params,
            method: QUERY_POST,
        });
        /** @namespace response.agreements **/
        const agreements = response.agreements;
        /** @type {Object} agreements **/
        /** @property {Object[]} agreements.agreement **/
        /** @property {number} agreements.send_sms **/
        /** @property {number} agreements.balance_sms **/
        /** @property {string} agreements.sms_error_code **/
        /** @property {string} agreements.sms_error_text **/
        /** @property {number} agreements.time_until_next **/
        return {
            SMSSent: agreements.send_sms === 1,
            contracts: agreements.agreement.map((agreement) => ({
                /** @type {Object} agreement **/
                /** @property {number} agreement.agr_id **/
                /** @property {string} agreement.agr_num **/
                /** @property {string} agreement.address **/
                id: agreement.agr_id,
                title: `${agreement.agr_num}, ${agreement.address}`,
            })),
            timeUntilNext: agreements.time_until_next,
            SMSErrorText: agreements.sms_error_text,
            SMSErrorCode: agreements.sms_error_code,
        };
    };

    /**
     * Obtaining contacts by agreement number and region
     * @param {string} clientContact
     * @param {string} region - Subscriber domain (region)
     * @returns {Promise<Object[]>}
     */
    getSSOAuthRecoveryContacts = async (clientContact, region) => {
        const response = await this.api.v2({
            uri: '/er/ssoauth/recovery/contacts/',
            params: {
                client_contact: clientContact,
                region: region,
            },
        });
        return response['contacts'];
    };
}
