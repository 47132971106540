export const URN_TO_URL_ALIASES = {
    'showcases/library/genres': 'genres',
    'showcases/library/collections': 'compilations',
    'showcases/library/movies': 'movies',
    'showcases/library/subscriptions': 'subscriptions',
    'showcases/library/bundles': 'bundles',
    'showcases/library/favorites': 'favorites',
    'showcases/library/serials': 'series',
    'showcases/library/continues': 'continues',
    'showcases/library/catchup': 'catchup',
    'showcases/library/channels': 'channels',
    'showcases/library/channel-packages': 'channel-packages',
    'showcases/library/tv-show-prod-ott': 'tv-shows',
    'showcases/library/movies-for-you-onlysub': 'recommendations',
    'showcases/library/my-movix-lt': 'pages/my-movix',
    'pages/bundle/9658082': 'movix-one',
};
