import { createContext } from 'react';
import { defaultModalContext, defaultModalDispatch } from '@/src/contexts/app/reducers/modalReducer';
import {
    defaultNotificationDispatch,
    defaultNotificationContext,
} from '@/src/contexts/app/reducers/notificationReducer';

// /**
//  * @property {Object} notifications
//  * @property {Notification[]} notifications.list
//  * @property {function} notifications.dispatch=defaultNotificationDispatch
//  * @property {Object} modal
//  * @property {Object} modal.current=defaultModalContext
//  * @property {function} modal.dispatch=dispatchModal
//  * */
const AppContext = createContext(getAppContext());
export default AppContext;

/**
 * @param {Notification[]} notifications
 * @param {function} dispatchNotifications
 * @param {Object} modal
 * @param {function} dispatchModal
 *
 * @property {Object} notifications
 * @property {Notification[]} notifications.list
 * @property {function} notifications.dispatch=defaultNotificationDispatch
 * @property {Object} modal
 * @property {Object} modal.current=defaultModalContext
 * @property {function} modal.dispatch=dispatchModal
 * @property {Object} parentalControl
 * */
export function getAppContext(
    notifications = defaultNotificationContext,
    dispatchNotifications = defaultNotificationDispatch,
    modal = defaultModalContext,
    dispatchModal = defaultModalDispatch
) {
    return {
        notifications: {
            list: notifications,
            dispatch: dispatchNotifications,
        },
        modal: {
            current: modal,
            dispatch: dispatchModal,
        },
    };
}
