import styles from './Button.module.scss';
import Link from 'next/link';
import Icon from '@/components/icon/Icon';
import Label from '@/components/label/Label';

function Content({
    innerClassName,
    image,
    imageProps,
    title,
    icon,
    textAtUpperLevel,
    iconClassName,
    isLoading,
    loaderClassName,
    label,
}) {
    return (
        <>
            <span className="button__content">
                {icon && !isLoading && (
                    <Icon id={icon} modifier={'button__icon' + (iconClassName ? ` ${iconClassName}` : '')} />
                )}
                {image && <img className="button__icon" src={image} alt={imageProps.alt || ''} {...imageProps} />}
                {title && (
                    <span className={'button__text' + (innerClassName ? ' ' + innerClassName : '')}>{title}</span>
                )}
                {label && <Label icon={label} modifier={'button__label label--light'} />}
                {isLoading && (
                    <span className={'button__loader' + (loaderClassName ? ' ' + loaderClassName : '')}>
                        <span className="button__text h-hidden--text">Загрузка</span>
                        <span className="button__loading">
                            <span className="button__loading-item"> </span>
                            <span className="button__loading-item"> </span>
                            <span className="button__loading-item"> </span>
                        </span>
                    </span>
                )}
            </span>
            <span className="button__border">
                <span className="button__cover" />
            </span>
            {textAtUpperLevel !== null && <span className="button__text">{textAtUpperLevel}</span>}
        </>
    );
}

export default function Button({
    type = 'text',
    submitting,
    title,
    className,
    innerClassName,
    loaderClassName,
    image,
    imageProps = {},
    icon,
    textAtUpperLevel,
    subtitle,
    iconClassName,
    buttonRef,
    label,
    ...props
}) {
    const content = (
        <Content
            key={submitting || textAtUpperLevel ? '' : title}
            innerClassName={innerClassName}
            image={image}
            imageProps={imageProps}
            title={submitting || textAtUpperLevel ? '' : title}
            icon={icon}
            textAtUpperLevel={textAtUpperLevel}
            iconClassName={iconClassName}
            loaderClassName={loaderClassName}
            isLoading={submitting}
            label={label}
        />
    );

    const buttonClassname =
        styles['button'] + (props.disabled ? ' ' + ButtonTypes.TYPE_DISABLED : '') + ' ' + className;

    const buttonProps = { ...props };
    if (props.disabled || submitting) {
        buttonProps.disabled = true;
    }

    switch (type) {
        case 'link': {
            if (props.href) {
                return (
                    <Link ref={buttonRef} href={props.href} className={buttonClassname} {...buttonProps}>
                        {content}
                    </Link>
                );
            } else {
                return (
                    <button ref={buttonRef} className={buttonClassname} {...buttonProps}>
                        {content}
                    </button>
                );
            }
        }
        case 'div':
            return (
                <div ref={buttonRef} className={buttonClassname} {...buttonProps}>
                    {content}
                </div>
            );
        case 'submit':
        case 'button':
        default:
            return (
                <button ref={buttonRef} className={buttonClassname} {...buttonProps}>
                    {subtitle ? (
                        <>
                            <span className="button__container">{content}</span>
                            <span className="button__sub-title">{subtitle}</span>
                        </>
                    ) : (
                        content
                    )}
                </button>
            );
    }
}

export function ButtonPrimary(props) {
    return <Button {...props} className={'button--large ' + (props.className || '')} />;
}

export function ButtonSecondary(props) {
    return <Button {...props} className={'button--secondary button--large ' + (props.className || '')} />;
}

export function ButtonClear(props) {
    return <Button {...props} className={'button--clear ' + (props.className || '')} />;
}

export function ButtonWide(props) {
    return <Button {...props} className={'button--wide button--large ' + (props.className || '')} />;
}

export const ButtonTypes = {
    TYPE_WARNING: 'button--warning',
    TYPE_PRIMARY: 'button--prime',
    TYPE_SECONDARY: 'button--secondary',
    TYPE_CLEAR: 'button--clear',
    TYPE_DISABLED: 'button--disabled',
    TYPE_WIDE: 'button--wide',
    TYPE_PRO: 'button--pro',
};
