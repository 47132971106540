/**
 * Модель статуса родительского контроля (2.0)
 */
export default class PinStatus {
    /** @type string */
    pinState;
    /** @type number */
    pinSessionTTL;
    /** @type Boolean */
    purchaseConfirmationEnabled;
    /** @type Boolean */
    inappropriateContentEnabled;
    /** @type Boolean */
    parentalControlEnabled;
    /** @type Number */
    level;
    /** @type array */
    channels = [];

    /**
     * PinStatus constructor.
     * @param {Object} object
     * @param {string} object.pin_state
     * @param {number} object.pin_session_ttl
     * @param {Boolean} object.purchase_confirmation_enabled
     * @param {Boolean} object.inappropriate_content_enabled
     * @param {Boolean} object.parental_control_enabled
     * @param {number} object.level
     * @param {Array} object.channels
     */
    constructor(object) {
        this.pinState = object.pin_state;
        this.pinSessionTTL = object.pin_session_ttl;
        this.purchaseConfirmationEnabled = object.purchase_confirmation_enabled;
        this.inappropriateContentEnabled = object.inappropriate_content_enabled;
        this.parentalControlEnabled = object.parental_control_enabled;
        this.level = object.level;
        this.channels = object.channels || [];
    }

    isEnabled() {
        return this.pinState === PIN_STATE.ENABLED;
    }

    isUnstated() {
        return this.pinState === PIN_STATE.UNSTATED;
    }
}

export const PIN_STATE = {
    UNSTATED: 'UNSTATED',
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
};
