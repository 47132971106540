export const SECONDS_IN_HOUR = 60 * 60;
export const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR;
export const MILLISECONDS_IN_DAY = SECONDS_IN_DAY * 1000;

/**
 * Возвращает дату с учетом временной зоны пользователя
 *
 * @param {number} timestamp
 * @param {number} timeShift
 * @returns {Date}
 */
export function userDate(timestamp, timeShift = 0) {
    let date = new Date(timestamp * 1000);
    date.setTime(date.getTime() + timeShift * 1000);
    return date;
}

const SECONDS_PER_SECOND = 1;
const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;

const designations = [
    ['H', SECONDS_PER_HOUR],
    ['M', SECONDS_PER_MINUTE],
    ['S', SECONDS_PER_SECOND],
];

export function secondsToDuration(seconds) {
    let duration = 'PT';
    let remainder = seconds;

    designations.forEach(([sign, seconds]) => {
        const value = Math.floor(remainder / seconds);

        remainder = remainder % seconds;

        if (value) {
            duration += `${value}${sign}`;
        }
    });

    if (duration === 'PT') {
        duration = 'PT0S';
    }

    return duration;
}
