import { useEffect, useRef, useState } from 'react';
import styles from './Header.module.scss';
import Logo from './logo/Logo';
import { getMenuItems } from './menu/Menu';
import Profile from '@/components/header/profile/Profile';
import { useRouter } from 'next/router';
import Nav from '@/components/header/nav/Nav';
import Button from '@/components/button/Button';
import { useApiStore, useAuthStore } from '@/src/stores/RootStoreProvider';
import useSubscriptions from '@/src/hooks/useSubscriptions';
import MenuDropdown from '@/components/header/menu-dropdown/MenuDropdown';
import ButtonSandwich from '@/components/button-sandwich/ButtonSandwitch';
import NavAccordion from '@/components/header/nav-accordion/NavAccordion';
import useChannelPackages from '@/src/hooks/useChannelPackages';
import { BACKEND_ENDPOINTS } from '@/src/api/Api';

const guestMenuItems = getMenuItems(true, null, null);

export default function Header({ marginRight }) {
    const { api } = useApiStore();
    const { isGuest, isME } = useAuthStore();
    const router = useRouter();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuItems, setMenuItems] = useState(guestMenuItems);
    const [activeMenuItemIndex, setActiveMenuItemIndex] = useState(-1);

    const fetchSubscriptions = useRef(false);
    fetchSubscriptions.current =
        fetchSubscriptions.current ||
        isMenuOpen ||
        activeMenuItemIndex === menuItems.findIndex((item) => item.name === 'Подписки');
    const subscriptions = useSubscriptions(false, true, fetchSubscriptions.current);

    const fetchChannelPackages = useRef(false);
    fetchChannelPackages.current =
        fetchChannelPackages.current ||
        isMenuOpen ||
        activeMenuItemIndex === menuItems.findIndex((item) => item.name === 'Телевидение');
    const channelPackages = useChannelPackages(false, true, fetchChannelPackages.current);

    useEffect(() => {
        setIsMenuOpen(false);
        setActiveMenuItemIndex(-1);
    }, [router.asPath]);

    useEffect(() => {
        setMenuItems(getMenuItems(isGuest, subscriptions, channelPackages, router.asPath));
    }, [isGuest, subscriptions, channelPackages, router.asPath]);

    const onIndexChange = (index) => {
        setActiveMenuItemIndex(index);
    };

    const leaveHandler = (isMenuOpen) => {
        if (isMenuOpen) {
            let activeIndex = 0;
            menuItems.find((item, i) => {
                if (item.active) {
                    activeIndex = i;
                }
                return item.active;
            });
            setActiveMenuItemIndex(activeIndex);
        } else {
            setActiveMenuItemIndex(-1);
        }
    };

    const onMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
        leaveHandler(!isMenuOpen);
    };

    return (
        <header
            className={
                styles.menu +
                (isMenuOpen || activeMenuItemIndex !== -1 ? ' ' + styles['menu--open'] : '') +
                ' menu--opaque'
            }
            style={{ marginRight: marginRight ? marginRight + 'px' : 0 }}
        >
            <div className="menu__bar">
                <div className="menu__home">
                    <Logo isAltLogo={api.endpoint !== BACKEND_ENDPOINTS[0]} />
                    <div className="menu__nav">
                        <Nav items={menuItems} onIndexChange={onIndexChange} isVertical={false} />
                    </div>
                </div>
                <div className="menu__control">
                    <div
                        className={
                            'menu__item menu__item--search' +
                            (router.asPath.includes('/search') ? ' menu__item--active' : '')
                        }
                    >
                        <Button
                            type={'link'}
                            icon={'loupe'}
                            iconClassName={'icon--medium menu__icon'}
                            className={'button--icon-only button--clear menu__action'}
                            title={'Поиск'}
                            href={'/search'}
                        />
                    </div>
                    <div className={'menu__item'}>
                        <Profile isActive={router.asPath.includes('/profile')} />
                    </div>
                    <div className="menu__item menu__item--pro">
                        <Button
                            title={isME ? 'Попробуйте 7 дней' : 'Подключить и смотреть'}
                            className="button--bundle"
                            href={'/movix-one'}
                            type="link"
                        />
                    </div>
                    <div className="menu__item menu__item--sandwich">
                        <ButtonSandwich
                            modifier={'menu__action'}
                            label="Управление основным меню"
                            onClick={onMenuClick}
                        />
                    </div>
                </div>
            </div>
            <div className="menu__promo">
                <Button
                    title={isME ? 'Попробуйте 7 дней' : 'Подключить и смотреть'}
                    className="button--bundle button--wide"
                    href={'/movix-one'}
                    type="link"
                />
            </div>
            <div className="menu__content" onMouseLeave={() => leaveHandler(isMenuOpen)}>
                <div className="menu__nav-vertical">
                    {isMenuOpen && (
                        <Nav
                            items={menuItems}
                            modifier={'nav--vertical'}
                            isVertical={true}
                            onIndexChange={onIndexChange}
                        />
                    )}
                </div>
                {menuItems.map(
                    (item, index) =>
                        item.submenu && (
                            <MenuDropdown
                                submenu={item.submenu}
                                key={index} // NOSONAR
                                modifier={'menu__dropdown' + (item.modifier ? ' ' + item.modifier : '')}
                                isActive={index === activeMenuItemIndex}
                            />
                        )
                )}
                <NavAccordion items={menuItems} modifier={'menu__accordion'} />
            </div>
        </header>
    );
}
