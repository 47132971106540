import BaseModel from '@/src/api/v3/models/BaseModel';
import Season from '@/src/api/v3/models/Season';
import Viewable from '@/src/api/v3/models/mixins/Viewable';
import Offers from '@/src/api/v3/models/mixins/Offers';
import Series from '@/src/api/v3/models/Series';

/**
 *  Эпизод сериала
 */

export default class Episode extends BaseModel {
    /** Название типа */
    typeName = 'Серия';
    CARD_IMAGE_SIZE = { width: 228, height: 124 };
    SLIDER_STUB_CLASS = 'cover--episodes';
    images = [
        '3_smarttv_episode_poster_video_library_blueprint',
        '3_smarttv_tvshow_poster_video_library_blueprint',
        'poster_blueprint',
    ];

    /** Номер серии
     *  @type {number} */
    number;
    /** Статус выпуска
     *  @type {string} */
    release;
    /** Сезон
     *  @type Season */
    season = null;
    /** Сериал
     *  @type Series */
    series = null;

    constructor(object) {
        super(object);
        this.number = object.number;
        this.release = object.release?.type;
        if (object.season) {
            this.season = new Season(object.season);
        }
        if (object.serial) {
            this.series = new Series(object.serial);
        }
        this.viewable = new Viewable(object);
        this.offers = new Offers(object);
    }
}
