import Common from '@/src/modules/Common';

/**
 * @param {string} userAgent
 * @returns {Boolean}
 */
export function isBot(userAgent) {
    const userAgentLowercase = userAgent.toLowerCase();
    return (
        userAgentLowercase.includes('bot') ||
        userAgentLowercase.includes('search') ||
        userAgentLowercase.includes('lighthouse')
    );
}

export default function keyEnterCheck(callback, ...params) {
    return (e) => {
        if (Common.isPressEnter(e)) {
            callback(...params);
        }
    };
}

export const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
};
