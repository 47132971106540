import styles from './CreditCard.module.scss';
import Input from '@/components/input/Input';
import { ButtonPrimary } from '@/components/button/Button';
import { useEffect, useRef, useState } from 'react';
import useNotifications from '@/src/hooks/useNotifications';

/**
 * @param {Offer} offer
 * @param {PaymentCard} card
 * @param {Object} params
 * @param {function} onPay
 */
export default function CreditCard({ card, offer, params, onPay }) {
    const { showError } = useNotifications();
    const [cvv, setCvv] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const cvvRef = useRef(null);

    function onChange(value) {
        setCvv(value.slice(0, 3));
    }

    useEffect(() => {
        // TODO: Реализовать фокус без setTimeout
        setTimeout(() => {
            cvvRef.current.focus();
        }, 100);
    }, []);

    const onFormSubmit = async (event) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);
        try {
            await onPay(cvv);
        } catch (e) {
            showError(e.message || 'Ошибка при оплате картой');
            setSubmitting(false);
        }
    };

    const price = params['use_tnb'] ? offer.stock?.priceWithDiscount : offer.price;

    return (
        <form className={styles['credit-card']} onSubmit={onFormSubmit}>
            <div className="credit-card__frame">
                <div className="credit-card__inner">
                    <div className="credit-card__logotype-wrap">
                        <div className="credit-card__logotype">
                            <img src="/assets/img/icon-visa.svg" alt="" />
                        </div>
                        <div className="credit-card__logotype">
                            <img src="/assets/img/icon-mastercard.svg" alt="" />
                        </div>
                        <div className="credit-card__logotype">
                            <img src="/assets/img/icon-mir.svg" alt="" />
                        </div>
                    </div>
                    <div className="credit-card__form">
                        <div className="credit-card__field">
                            <Input
                                title={'Номер карты'}
                                className={'input--card'}
                                value={card.getTitle()}
                                readOnly="readonly"
                                id="credit-card-number"
                            />
                        </div>
                        <div className="credit-card__field credit-card__field--row">
                            <Input
                                title={'ММ/ГГ'}
                                className={'input--card credit-card__input credit-card__input--date'}
                                value={card.getExpiredTitle()}
                                readOnly="readonly"
                                id="credit-card-date"
                            />
                            <Input
                                title={'CVV'}
                                className={'input--card credit-card__input credit-card__input--cvv'}
                                mask="999"
                                value={cvv}
                                id="credit-card-cvv"
                                onChange={onChange}
                                inputRef={cvvRef}
                            />
                            <div className="credit-card__help-input">Последние три цифры на&nbsp;обороте карты</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="credit-card__footer">
                <div className=" credit-card__footer-icon">
                    <img src="/assets/img/icon-lock-small.svg" alt="" />
                </div>
                <div className="credit-card__footer-text">
                    Интернет-платежи защищены сертификатом TLS и&nbsp;протоколом 3D&nbsp;Secure.
                    Мы&nbsp;не&nbsp;передаем сторонним лицам платежные данные, в&nbsp;том числе данные карты.
                </div>
            </div>
            <div className="credit-card__action">
                <ButtonPrimary
                    type="submit"
                    title={`Оплатить ${price} ₽`}
                    submitting={submitting}
                    disabled={cvv.length !== 3}
                />
            </div>
        </form>
    );
}
