import BaseModel from '@/src/api/v3/models/BaseModel';
import getModelByObject from '@/src/api/v3/models/helpers/ShowcaseHelper';

/**
 *  Подсказка (элемент)
 */
export default class SuggestAutocomplete extends BaseModel {
    /** @type {BaseModel} */
    model;

    constructor(object) {
        super(object);
        this.model = getModelByObject(object.data);
    }
}
