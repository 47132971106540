import BaseModel from '@/src/api/v3/models/BaseModel';
import Offers from '@/src/api/v3/models/mixins/Offers';
import { PartnerSubscriptions, Subscriptions } from '@/src/constants/subscriptions';
import { ShowcaseItemType } from '@/src/constants/types';

/**
 *  Подписка
 */
export default class Subscription extends BaseModel {
    URL_PREFIX = 'subscriptions';
    SLIDER_STUB_CLASS = 'cover--packages';
    EVENT_ITEM_CATEGORY = ShowcaseItemType.SUBSCRIPTION;

    /** Название типа
     *  @type {string} */
    typeName = 'Подписка';
    /** Описание
     * @type {?string} */
    description;
    /** Офферы
     * @type {?Offers} */
    offers;

    images = [
        'newdesign_package_poster_blueprint',
        '3_smarttv_package_poster_video_library_blueprint',
        'subscription_blueprint',
        'background_web_blueprint',
        '3_smarttv_package_background_video_library_blueprint',
        'badge_blueprint',
        'poster_blueprint',
    ];

    backgroundModifier = 'backbg--package';

    constructor(object) {
        super(object);
        this.description = object.description || null;
        this.offers = new Offers(object);
    }

    /**
     * Получить изборажение для фона
     * @returns null|string
     */
    getBackgroundSrc() {
        return this.resources.getResourceUrl([
            '3_smarttv_package_background_video_library_blueprint',
            'background_web_blueprint',
        ]);
    }

    /**
     * Получить изборажение для значка
     * @returns null|string
     */
    getBadgeSrc(options = null) {
        if (options === null) {
            options = { size: { width: '90', height: '26', func: 'contain' } };
        }
        return this.resources.getResourceUrl(['newdesign_partner_badge_blueprint', 'badge_blueprint'], options, null);
    }

    /**
     * Получить изборажение для нового оффера
     * @returns null|string
     */
    getCircleBadgeSrc(options = null) {
        if (options === null) {
            options = { size: { width: '32', height: '32', func: 'contain' } };
        }
        return this.resources.getResourceUrl(['circle_badge_blueprint'], options, null);
    }

    isPartner() {
        return [Subscriptions.IVI, Subscriptions.KINOPOISK].includes(this.id);
    }

    isIncludesKinopoisk() {
        return [Subscriptions.KINOPOISK, Subscriptions.MOVIX_ONE].includes(this.id);
    }

    getPartnerTitle() {
        return this.isPartner() ? PartnerSubscriptions[this.id].partnerTitle : null;
    }
}
