// noinspection JSUnusedGlobalSymbols

import { QUERY_POST } from '@/src/api/Api';
import { Vote } from '@/src/constants/types';
import { ApiMixin } from '@/src/api/ApiMixin';

/**
 * Trait Votes
 * @see https://kblion.ertelecom.ru/display/BA/v3-Estimates
 */

export class Votes extends ApiMixin {
    /** Create/update user content estimation mark
     * @param {number} id
     * @param {string} type
     * @param {string} vote
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    voteSet = async (id, type, vote) => {
        await this.api.v3({
            uri: '/api/v3/estimates',
            params: {
                id: id,
                type: type,
                estimate: vote,
            },
            method: QUERY_POST,
        });
    };

    /**
     * Поставить оценку "Не нравится"
     * @param {number} id
     * @param {string} type
     * @returns void
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    voteDislike = async (id, type) => {
        this.voteSet(id, type, Vote.DISLIKE);
    };

    /**
     * Поставить оценку "Нравится"
     * @param {number} id
     * @param {string} type
     * @returns void
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    voteLike = async (id, type) => {
        this.voteSet(id, type, Vote.LIKE);
    };

    /**
     * Поставить Нейтральную оценку
     * @param {number} id
     * @param {string} type
     * @returns void
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    voteNeutral = async (id, type) => {
        this.voteSet(id, type, Vote.NEUTRAL);
    };
}
