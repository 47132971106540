import { buildUrl } from '@/src/api/v3/APIv3';
import Page from '@/src/api/v3/models/Page';
import { ApiMixin } from '@/src/api/ApiMixin';

/**
 * Pages
 * @see https://kblion.ertelecom.ru/display/BA/v3-Pages
 */

export class Pages extends ApiMixin {
    /**
     * Загрузить страницу
     * @param {Object} param
     * @param {string} param.urn
     * @param {?string} [param.params]
     * @param {?number} [param.offset]
     * @param {?number} [param.limit]
     * @returns {Promise<Page>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    loadPage = async ({ urn, params = null, offset = null, limit = null }) => {
        const pageData = await this.loadPageData({ urn, params, offset, limit });
        return new Page(pageData);
    };

    /**
     * Загрузить страницу
     * @param {Object} param
     * @param {string} param.urn
     * @param {?string} [param.params]
     * @param {?number} [param.offset]
     * @param {?number} [param.limit]
     * @param {?number} [param.childrenItemLimit]
     * @returns {Promise<Object>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    loadPageData = async ({ urn, params = null, offset = null, limit = null, childrenItemLimit = null }) => {
        const url = buildUrl(urn, this.api.token, { params, offset, limit, childrenItemLimit });
        const response = await this.api.v3({ uri: url });
        return response['data'];
    };
}
