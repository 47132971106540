import headerStyles from '../Header.module.scss';
import styles from '../menu-popup/MenuPopup.module.scss';
import Link from 'next/link';
import PROFILE_ITEMS from '@/src/constants/profile-items';
import { useAuthStore } from '@/src/stores/RootStoreProvider';
import useAuthCheck from '@/src/hooks/useAuthCheck';
import ModalDialogExit from '@/components/modal/profile/ModalDialogExit';
import useModals from '@/src/hooks/useModals';
import Button from '@/components/button/Button';

export default function Profile({ isActive = false }) {
    const { openModal } = useModals();
    const { isGuest } = useAuthStore();
    const authCheck = useAuthCheck();

    const onActionClick = (action) => {
        if (action === 'logout') {
            openModal(<ModalDialogExit />);
        }
    };

    function getProfileSubmenu() {
        return (
            <>
                {PROFILE_ITEMS.map((item, index) =>
                    item.action ? (
                        <span
                            className={'menu-popup__item' + ' action'}
                            onKeyDown={() => onActionClick(item.action)}
                            onClick={() => onActionClick(item.action)}
                            key={index} // NOSONAR
                        >
                            {item.name}
                        </span>
                    ) : (
                        <Link
                            href={item.url}
                            className={'menu-popup__item'}
                            key={index} // NOSONAR
                            onClick={(e) => {
                                e.target.blur();
                            }}
                        >
                            {item.name}
                        </Link>
                    )
                )}
            </>
        );
    }

    if (isGuest) {
        return <Button title="Войти" className="button--clear menu__action" onClick={authCheck} />;
    } else {
        return (
            <>
                <Button
                    type={'link'}
                    className={
                        'button--icon-left button--clear button--profile menu__action' +
                        (isActive ? ' menu__item--active' : '')
                    }
                    href={'/profile/personal'}
                    icon={'profile'}
                    iconClassName={'icon--medium menu__icon'}
                    title={'Профиль'}
                    onClick={(e) => {
                        e.target.closest('.menu__action').blur();
                    }}
                />
                <div className={headerStyles['menu__popup'] + ' ' + styles['menu-popup']}>{getProfileSubmenu()}</div>
            </>
        );
    }
}
