import md5 from 'md5';
import * as DataHelper from '@/src/helpers/DataHelper';

/**
 *  Ресурс
 */
export default class Resource {
    /** ID
     *  @type {string} */
    id;
    /** Название
     *  @type {string} */
    type;

    /**
     * Resource constructor.
     * @param {Object} object
     * @param {string} object.id
     * @param {string} object.type
     * @param {string} object.category
     */
    constructor(object) {
        this.id = object.id;
        this.type = object.type === 'picture' ? object.category : object.type;
    }

    /**
     * Получить URL
     */
    getUrl(options = null) {
        if (this.type === 'trailer') {
            return this.getTrailerUrl();
        } else {
            return this.getImageUrl(options);
        }
    }

    /**
     * Получить URL изображения
     */
    getImageUrl(options) {
        return DataHelper.getImageUrlByResourceId(this.id, options);
    }

    /**
     * Получить URL трейлера
     * @returns string
     */
    getTrailerUrl() {
        return `https://er-cdn.ertelecom.ru/content/public/r${this.id}/playlist.m3u8`;
    }

    /**
     * Возвращает хеш ресурса
     * @returns string
     */
    getHash() {
        return md5(this.type + this.id);
    }
}
