import Asset from '@/src/api/v3/models/Asset';
import Children from '@/src/api/v3/models/mixins/Children';
import { RenderType } from '@/src/api/v3/models/Showcase';
import Episode from '@/src/api/v3/models/Episode';
import { ShowcaseItemType } from '@/src/constants/types';

/**
 *  Сериал
 */

export default class Series extends Asset {
    URL_PREFIX = 'series';
    EVENT_ITEM_CATEGORY = ShowcaseItemType.SERIAL;

    /** Название типа
     *  @type {string} */
    typeName = 'Сериал';
    /** @type Children Сезоны */
    seasons;
    /** @type Episode Эпизод */
    episode;

    constructor(object) {
        super(object);
        this.children = new Children(object.seasons);
        this.children.showcases.forEach((showcase) => {
            showcase.renderType = RenderType.TABS;
        });
        this.seasons = this.children;
        if (object.continues?.episode) {
            object.continues.episode.season = object.continues.season;
            this.episode = new Episode(object.continues.episode);
        }
    }

    /**
     * Получить логотип сериала
     * @returns null|string
     */
    getLogoSrc() {
        return this.resources.getResourceUrl('badge_blueprint');
    }

    /**
     * Получить изображения для фона подключения T&B
     * @returns null|string
     */
    getTNBBackgroundSrc(options = null) {
        if (options === null) {
            options = { size: { width: '605', height: '284' } };
        }
        return this.resources.getResourceUrl('3_smarttv_serial_background_video_library_blueprint', options);
    }

    /**
     * Получить изображения для фона
     * @returns null|string
     */
    getBackgroundSrc() {
        return this.resources.getResourceUrl('3_smarttv_serial_background_video_library_blueprint');
    }
}
