import { useRef, useEffect } from 'react';
import Head from 'next/head';
import Common from '@/src/modules/Common';
import styles from './Modal.module.scss';
import Icon from '@/components/icon/Icon';
import useModals from '@/src/hooks/useModals';

export default function ModalForm({
    children,
    pageUrl,
    pageTitle,
    onHide,
    onClose,
    onBack,
    closeButton = true,
    className,
    notHide = false,
    isAutoFocus = true,
}) {
    const { closeModal, isOpen } = useModals();
    const modalRef = useRef(null);

    useEffect(() => {
        if (modalRef.current && isAutoFocus) {
            modalRef.current.focus();
            const focusable = modalRef.current.querySelectorAll(
                '[href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            if (focusable.length) {
                setTimeout(() => {
                    focusable[0].focus();
                }, 0);
            }
        }
    }, [isAutoFocus, pageUrl]);

    const onWrapperKeyDown = (event) => {
        if (Common.isPressEscape(event)) {
            hide();
        }
    };

    const hide = () => {
        closeModal();

        if (onHide) {
            onHide.call();
        }

        if (!notHide) {
            // удаляем родителя (Suspense либо ModalForm, в зависимости от типа сборки)
            // ReactDOM.unmountComponentAtNode(document.getElementById(parentContainer));
        }
    };

    const onWrapperClick = (event) => {
        if (event.target.classList.contains(styles['modal']) && !notHide) {
            hide();
        }
    };

    return (
        isOpen && (
            <div
                className={styles['modal'] + ' modal--show' + (className ? ' ' + className : '')}
                tabIndex="-1"
                ref={modalRef}
                onKeyDown={onWrapperKeyDown}
                onClick={onWrapperClick}
            >
                <Head>
                    <title>{pageTitle}</title>
                </Head>
                <div className={styles['modal__content']}>
                    {onBack && typeof onBack === 'function' && <ModalBackButton onBack={onBack} />}
                    {closeButton && <ModalCloseButton onClose={onClose} />}
                    {children}
                </div>
            </div>
        )
    );
}

export function ModalCloseButton({ onClose }) {
    const { closeModal } = useModals();

    const closeHandler = () => {
        if (onClose) {
            onClose.call();
        }

        closeModal();
    };

    return (
        <button className="modal__close" style={{ zIndex: 10 }} type="button" onClick={closeHandler}>
            <span className="h-hidden--text">Закрыть модальное окно</span>
            <Icon id={'close'} modifier={'modal__close-icon'} />
        </button>
    );
}

function ModalBackButton({ onBack }) {
    return (
        <button className="modal__back" style={{ zIndex: 10 }} type="button" onClick={onBack}>
            <Icon id={'arrow-left-single'} modifier={'modal__back-icon'} />
            <span className="modal__back-text">Назад</span>
        </button>
    );
}
