export const LOCAL_STORAGE_KEY = 'notification';

/**
 * @param {Object[]} state
 * @param {Object} state
 * @param {string} state.id
 * @param {string} state.type,
 * @param {string} state.title,
 * @param {string} state.message,
 * @param {Object} action
 * @param {?string} action.id
 * @param {?boolean} action.remove
 * @param {?boolean} action.reply
 * @param {Object} action?.body
 * @param {string} action.body.id,
 * @param {string} action.body.type,
 * @param {string} action.body.title,
 * @param {string} action.body.message,
 * */
export default function notificationReducer(state, action) {
    if (action.remove) {
        if (state.indexOf((item) => item?.id === action.id)) {
            state = (state || []).filter((item) => item?.id !== action.id);
            return [...state];
        } else {
            return state;
        }
    }
    if (action.reply) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(action.body));
    }
    return [...state, action.body];
}

export const defaultNotificationContext = [];

// noinspection JSUnusedLocalSymbols
/**
 * @param {Object} action
 * @param {?string} action.id
 * @param {?boolean} action.remove
 * @param {?boolean} action.reply
 * @param {?Object} action?.body
 * @param {string} action.body.type,
 * @param {string} action.body.title,
 * @param {string} action.body.message,
 * */
export const defaultNotificationDispatch = (action) => {
    // This is intentional
};
