import Showcase, { RenderType, ShowcaseType } from '@/src/api/v3/models/Showcase';

export default class Page {
    /** Витрины
     *  @type {Showcase[]} */
    showcases = [];
    /** URN
     *  @type {string} */
    urn;
    /** Название
     *  @type {string} */
    title;
    /** Название страницы
     *  @type {string} */
    pageTitle;
    /** Описание страницы
     *  @type {string} */
    pageDescription;
    /** Всего элементов
     *  @type {number} */
    total;

    constructor(object) {
        this.title = object?.title ?? '';
        this.urn = object?.urn ?? '';
        this.total = object?.total ?? 0;
        let id = 0;
        if (object?.showcases && Array.isArray(object.showcases)) {
            object.showcases.forEach((showcaseObject) => {
                const showcase = new Showcase(showcaseObject);
                showcase.renderId = `${showcase.urn}${id++}`;
                this.showcases.push(showcase);
            });
        }
    }

    /**
     * Получить главный элемент страницы
     * @returns {?BaseModel}
     */
    getDetailsItem() {
        for (const showcase of this.showcases) {
            if (showcase.type === ShowcaseType.DETAILS) {
                return showcase.items ? showcase.items[0] : null;
            }
        }
        return null;
    }

    /**
     * Загрузить витрины
     * @param {Api} api
     * @param limit
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    async loadShowcases(api, limit) {
        let offset = 0;
        this.showcases.forEach((showcase) => {
            if (showcase.renderType !== RenderType.HTML) {
                offset++;
            }
        });
        const page = await api.pages.loadPage({ urn: this.urn, offset: offset, limit: limit });
        this.showcases = this.showcases.concat(page.showcases);
    }
}
