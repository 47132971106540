export default class Contact {
    static TYPE_EMAIL = 'email';
    static TYPE_PHONE = 'mobile';

    /** Number identifier of the contact in ER billing
     * @type {Number} */
    id;
    /** Contact confirmation status
     * @type {string} */
    status;
    /** Contact value
     * @type {string} */
    value;
    /** Type of contact
     * @type {string} */
    type;

    /**
     * Contact constructor.
     * @param {Object} data
     * @param {?number} data.id
     * @param {?string} data.status
     * @param {?string} data.contact_value
     * @param {?string} data.contact_type
     */
    constructor(data) {
        this.id = data.id;
        this.status = data.status;
        this.value = data.contact_value;
        this.type = data.contact_type;
        this.confirmed = this.isConfirm();
    }

    /**
     * Проверка подтвержден ли контакт
     *
     * @returns boolean
     */
    isConfirm() {
        return this.status === 'confirmed';
    }
}
