import BaseModel from '@/src/api/v3/models/BaseModel';
import { FavoriteStatus, Vote } from '@/src/constants/types';
import Viewable from '@/src/api/v3/models/mixins/Viewable';
import Offers from '@/src/api/v3/models/mixins/Offers';
import Showcase from '@/src/api/v3/models/Showcase';
import { slug } from '@/src/modules/Common';

/**
 *  Ассет (Фильм или Сериал)
 */
export default class Asset extends BaseModel {
    static CARD_IMAGE_SIZE = {
        width: 182,
        height: 269,
    };

    SLIDER_STUB_CLASS = 'cover--movies';

    /** Оценка
     *  @type {string} */
    estimate;
    /** @type {string} Описание */
    description;
    /** @type {number[]} Оценки */
    scores = [];
    /** @type {?Date} Дата выпуска */
    releasedAt;
    /** @type {string[]} Страны */
    countries = [];
    /** @type {string[]} Жанры */
    genres = [];

    /** @type {Viewable} Страны */
    viewable;

    /** @type {Showcase[]} Персоны */
    persons = [];

    /** @type {boolean} Входит ли в избранное */
    isFavorite;

    /**
     * Asset constructor.
     * @param {Object} object
     */
    constructor(object) {
        super(object);
        this.estimate = object.estimate?.type ?? Vote.NEUTRAL;
        this.description = object.description;

        this.scores = [];
        if (object.score) {
            object.score.forEach((score) => {
                this.scores[score.type] = score.value;
            });
        }

        this.countries = [];
        if (object.countries) {
            object.countries.forEach((country) => {
                this.countries.push(country.title);
            });
        }

        this.releasedAt = object.releasedAt ? new Date(object.releasedAt) : null;

        if (object.genres) {
            object.genres.forEach((genre) => {
                this.genres.push(genre.title);
            });
        }

        this.isFavorite = object.favorite?.type === FavoriteStatus.FAVORITE;

        this.viewable = new Viewable(object);
        this.offers = new Offers(object);

        if (object?.persons?.showcases) {
            object.persons.showcases.forEach((showcase_object) => {
                let showcase = new Showcase(showcase_object);
                this.persons.push(showcase);
            });
        }
    }

    /**
     * Получить изображения для фона
     * @returns null|string
     */
    getBackgroundSrc() {
        return this.resources.getResourceUrl('3_smarttv_asset_background_video_library_blueprint');
    }

    /**
     * Получить трейлер
     * @returns null|string
     */
    getTrailerSrc() {
        return this.resources.getResourceUrl('trailer', [], null);
    }

    /**
     * Получение изображения
     * @returns null|string
     * @param options
     */
    getImageSrc(options = null) {
        if (options === null) {
            options = { size: Asset.CARD_IMAGE_SIZE };
        }
        return this.resources.getResourceUrl('poster_blueprint', options);
    }

    /**
     * Получить изображения для фона подключения T&B
     * @returns null|string
     */
    getTNBBackgroundSrc(options = null) {
        if (options === null) {
            options = { size: { width: '605', height: '284' } };
        }
        return this.resources.getResourceUrl('3_smarttv_asset_background_video_library_blueprint', options);
    }

    /**
     * Получить изображение постера
     * @returns null|string
     * @param options
     */
    getPosterImageSrc(options = null) {
        if (options === null) {
            options = { size: { width: 284, height: 417 } };
        }
        return this.resources.getResourceUrl('poster_blueprint', options);
    }

    /**
     * Получение URL элемента
     * @returns string
     */
    getUrl() {
        const releasedYear = this.releasedAt ? '-' + this.releasedAt.getFullYear() : '';
        return (
            '/' +
            (this.URL_PREFIX ? this.URL_PREFIX : this.type + 's') +
            '/' +
            slug(this.title) +
            releasedYear +
            '-' +
            this.id
        );
    }
}
