export default class BundleInfo {
    /** Описание
     *  @type {string} */
    title;
    /** Период
     *  @type {number} */
    resourceId;

    /**
     * Offer constructor.
     * @param {Object} object
     */
    constructor(object) {
        this.title = object['title'];
        this.resourceId = object['iconResourceId'];
    }
}
