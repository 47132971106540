// noinspection JSUnusedGlobalSymbols

import { QUERY_POST } from '@/src/api/Api';
import { ApiMixin } from '@/src/api/ApiMixin';
import PaymentCard from '@/src/api/v2/models/PaymentCard';

/**
 * Payment
 * @see https://kblion.ertelecom.ru/display/BA/Trans+10.1+Pay
 */

export class Payment extends ApiMixin {
    /**
     * List of linked cards
     * @returns {Promise<PaymentCard[]>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getPaymentCards = async () => {
        let response = await this.api.v2({
            uri: '/er/billing/payment/bindings/',
        });
        let bindings = response['bindings'] || [];
        return bindings.map((card) => new PaymentCard(card));
    };

    /**
     * Linking a card for RUB 1
     * @param {string|null} returnUrl
     * @returns {Promise<Object>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    linkCard = async (returnUrl = null) => {
        let response = await this.api.v2({
            uri: '/er/billing/payment/pre_register/',
            params: returnUrl ? { return_url: returnUrl } : {},
            method: QUERY_POST,
        });
        return response['payment'];
    };

    /**
     * @typedef {Object} Payment
     * @property {number} pay_status - Статус платежа
     * @property {string} order_id
     * @property {string} order_number
     * @property {string} pay_url
     */

    /**
     * Pay with a new card
     * @param {number} amount
     * @param {string|null} returnUrl
     * @returns {Promise<Object>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    addCardWithPayment = async (amount, returnUrl = null) => {
        let params = {
            payment_value: amount,
            save_card: 1,
        };
        if (returnUrl) {
            params.return_url = returnUrl;
        }
        let response = await this.api.v2({
            uri: '/er/billing/payment/register/',
            params: params,
            method: QUERY_POST,
        });
        return response['payment'];
    };

    /**
     * @typedef {Object} PayByLinkedCardStatus
     * @property {number} pay_status - Статус платежа
     * @property {string} pay_status_name - Имя статуса
     * @property {string} pay_status_descr - Описание статуса
     * @property {string} last_update - Дата последнего платежа
     * @property {string} billing_transaction_id - ID транзакции биллинга
     * @property {string} gate_transaction_id - ID платежа
     * @property {number} amount - Сумма (стоимость этой покупки) в рублях
     * @property {number} partner_id - ID банка партнера
     * @property {number|string} agreement_number - Номер договора
     * @property {string} client_name - ФИО клиента
     * @property {string} binding_id - Ссылка на ID используемого при совершении покупки (когда происходит покупка бандла)
     * @property {string} autopay_enabled - Авто оплата была включена
     * @property {string} json_data
     * @property {string} action_code_descr
     * @property {string} acsUrl
     */

    /**
     * Linking a card for RUB 1
     * @param bindingId {Number}
     * @param cvv {string}
     * @param amount {number}
     * @param returnUrl {string}
     * @returns {Promise<PayByLinkedCardStatus>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    payByLinkedCard = async (bindingId, cvv, amount, returnUrl) => {
        let response = await this.api.v2({
            uri: '/er/billing/payment/register/binding/',
            params: {
                binding_id: bindingId,
                cvv: cvv,
                payment_value: amount,
                return_url: returnUrl,
            },
            method: QUERY_POST,
        });
        return response['status'];
    };

    /**
     * Unlink the card
     * @param bindingId {string}
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    unlinkCard = async (bindingId) => {
        await this.api.v2({
            uri: '/er/billing/payment/unbind/',
            params: { binding_id: bindingId },
            method: QUERY_POST,
        });
    };

    /**
     * Pay by card using order number
     * @param transactionId {string}
     * @param type {string}
     * @param assetId {number|null}
     * @param offerId {number|null}
     * @param {Object} params
     * @returns {Promise<number>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    payCard = async (transactionId, type, assetId = null, offerId = null, params = {}) => {
        const response = await this.api.v2({
            uri: '/er/pay/card',
            params: {
                transaction_id: transactionId,
                type: type,
                ...(assetId ? { asset_id: assetId } : {}),
                ...(offerId ? { offer_id: offerId } : {}),
                ...params,
            },
            method: QUERY_POST,
        });
        return response['status'];
    };

    /**
     * Get payment status
     * @param transactionId {string}
     * @returns {Promise<number>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getPayStatus = async (transactionId) => {
        let response = await this.api.v2({
            uri: '/er/pay/card',
            params: { transaction_id: transactionId },
        });
        return response['status'];
    };
}
