// noinspection JSUnusedGlobalSymbols

import { QUERY_POST } from '@/src/api/Api';
import ParentalControlLevel from '@/src/api/v2/models/ParentalControlLevel';
import ParentalControlStatus from '@/src/api/v2/models/ParentalControlStatus';
import PinStatus from '@/src/api/v2/models/PinStatus';
import { ApiMixin } from '@/src/api/ApiMixin';

/**
 * ParentControl
 * @see https://kblion.ertelecom.ru/display/BA/Trans+6.+Parent+control
 */

export class ParentalControl extends ApiMixin {
    /**
     * Receive the list of possible values of age limit level
     * @returns {Promise<ParentalControlLevel[]>}
     */
    getParentalControlLevels = async () => {
        const response = await this.api.v2({ uri: '/parental_control/levels' });
        const levels = response['levels'] || [];
        return levels.map((level) => new ParentalControlLevel(level));
    };

    /**
     * Get a status
     * @returns {Promise<ParentalControlStatus>}
     */
    getParentalControlStatus = async () => {
        const response = await this.api.v2({ uri: '/parental_control/status' });
        return new ParentalControlStatus(response['status']);
    };

    /**
     * Switch on parent control
     * @param {null|string} [pin] PIN-code
     * @returns {Promise<ParentalControlStatus>}
     */
    parentalControlEnable = async (pin = null) => {
        let params = {};
        if (pin) {
            params.pin = pin;
        }
        let response = await this.api.v2({
            uri: '/parental_control/enable',
            params: params,
            method: QUERY_POST,
        });
        return new ParentalControlStatus(response['status']);
    };

    /**
     * Switch off parent control
     * @param {null|string} [pin] PIN-code.
     * @returns {Promise<ParentalControlStatus>}
     */
    parentalControlDisable = async (pin = null) => {
        let params = {};
        if (pin) {
            params.pin = pin;
        }
        const response = await this.api.v2({
            uri: '/parental_control/disable',
            params: params,
            method: QUERY_POST,
        });
        return new ParentalControlStatus(response['status']);
    };

    /**
     * Change PIN-code
     * @param {string} newPin - New PIN-code
     * @param {string|null} [oldPin] Old PIN-code
     * @returns {void}
     */
    parentalControlChangePin = async (newPin, oldPin = null) => {
        let params = {
            new_pin: newPin,
        };
        if (oldPin) {
            params.old_pin = oldPin;
        }
        await this.api.v2({
            uri: '/parental_control/pin/change',
            params: params,
            method: QUERY_POST,
        });
    };

    /**
     * Change the age limit
     * @param {number} level
     * @param {string} pin
     * @returns {Promise<ParentalControlStatus>}
     */
    parentalControlChangeAgeLimit = async (level, pin) => {
        const response = await this.api.v2({
            uri: '/parental_control/level/change',
            params: {
                level: level,
                pin: pin,
            },
            method: QUERY_POST,
        });
        return new ParentalControlStatus(response['status']);
    };

    /**
     * Add/remove channels from the list of blocked channels
     * @param {array|string} channelId
     * @param {null|string} [pin]
     * @returns {Promise<ParentalControlStatus>}
     */
    parentalControlUpdateLockChannels = async (channelId, pin) => {
        let params = {
            channel_id: Array.isArray(channelId) ? channelId.join(',') : channelId,
        };
        if (pin) {
            params.pin = pin;
        }
        const response = await this.api.v2({
            uri: '/parental_control/channels',
            params: params,
            method: QUERY_POST,
        });
        return new ParentalControlStatus(response['status']);
    };

    /**
     * Check PIN-code
     * @param {string} pin - PIN-code.
     * @returns {void}
     */
    parentalControlCheckPin = async (pin) => {
        await this.api.v2({
            uri: '/parental_control/pin/validate',
            params: { pin: pin },
        });
    };

    /**
     * Enables parental control and setups pin with SMS confirm
     * @param {string} phone - Phone number. Correct format:  (+7|7|8[XXXXXXXXXX])
     * @param {string} pin - New PIN-code. Correct format: 4х sign number (XXXX)
     * @param {string} code - SMS code. Correct format: 4х sign number (XXXX)
     * @returns {Promise<ParentalControlStatus>}
     */
    parentalControlEnableSMS = async (phone, pin, code) => {
        const response = await this.api.v2({
            uri: '/er/billing/parental_control/enable',
            params: { phone: phone, code: code, pin: pin },
            method: QUERY_POST,
        });
        return new ParentalControlStatus(response['status']);
    };

    /**
     * Disable parental control with SMS confirm
     * @param {string} phone - Phone number. Correct format:  (+7|7|8[XXXXXXXXXX])
     * @param {string} code - SMS code. Correct format: 4х sign number (XXXX)
     * @returns {Promise<ParentalControlStatus>}
     */
    parentalControlDisableSMS = async (phone, code) => {
        const response = await this.api.v2({
            uri: '/er/billing/parental_control/disable',
            params: { phone: phone, code: code },
            method: QUERY_POST,
        });
        return new ParentalControlStatus(response['status']);
    };

    /**
     * Change pin code with SMS confirm
     * @param {string} phone - Phone number. Correct format:  (+7|7|8[XXXXXXXXXX])
     * @param {string} pin - New PIN-code. Correct format: 4х sign number (XXXX)
     * @param {string} code - SMS code. Correct format: 4х sign number (XXXX)
     * @returns {void}
     */
    parentalControlChangePinSMS = async (phone, pin, code) => {
        await this.api.v2({
            uri: '/er/billing/parental_control/pin/change',
            params: { phone: phone, code: code, pin: pin },
            method: QUERY_POST,
        });
    };

    /**
     * Get PIN status (2.0)
     * @returns {Promise<PinStatus>}
     */
    getPinStatus = async () => {
        const response = await this.api.v2({ uri: '/pin/status' });
        return new PinStatus(response['status']);
    };

    /**
     * Enable pin state and set new pin code
     * @param {string} contactId - Contact ID taken
     * @param {string} pin - New PIN-code. Correct format: 4х sign number (XXXX)
     * @param {string} code - SMS code. Correct format: 4х sign number (XXXX)
     * @returns {Promise<PinStatus>}
     */
    pinEnable = async (contactId, pin, code) => {
        const response = await this.api.v2({
            uri: '/pin/enable',
            params: { contact_id: contactId, code: code, pin: pin },
            method: QUERY_POST,
        });
        return new PinStatus(response['status']);
    };

    /**
     * Disable pin state
     * @param {string} contactId - Contact ID taken
     * @param {string} pin - New PIN-code. Correct format: 4х sign number (XXXX)
     * @param {string} code - SMS code. Correct format: 4х sign number (XXXX)
     * @returns {Promise<PinStatus>}
     */
    pinDisable = async (contactId, pin, code) => {
        const response = await this.api.v2({
            uri: '/pin/disable',
            params: { contact_id: contactId, code: code, pin: pin },
            method: QUERY_POST,
        });
        return new PinStatus(response['status']);
    };

    /**
     * Check pin
     * @param {string} pin - PIN-code.
     * @returns {void}
     */
    pinCheck = async (pin) => {
        await this.api.v2({
            uri: '/pin/check',
            params: { pin: pin },
            method: QUERY_POST,
        });
    };

    /**
     * Change pin states
     * @param {Boolean} [purchaseConfirmation] - Purchase confirmation enable status
     * @param {Boolean} [parentalControl] - Parent control enable status
     * @param {Boolean} [inappropriateContent] - Inappropriate content enable status
     * @param {Number} [contactId] - Contact ID taken from /er/misc/subscriber/contacts
     * @param {string} [code] - SMS code. Correct format: 4х sign number (XXXX)
     * @param {Number} [level] - Level of current limitation of parent control. Available values: 0, 6, 12, 16, 18
     * @param {Number[]} [channels] - List of blocked channels ID
     * @param {String} [pin] - New PIN-code. Correct format: 4х sign number (XXXX). For changing PIN-code required additional parameters contact_id and code
     * @returns {Promise<PinStatus>}
     */
    pinChange = async ({
        purchaseConfirmation,
        parentalControl,
        inappropriateContent,
        level,
        channels,
        contactId,
        pin,
        code,
    }) => {
        const channelsIds = channels?.length > 0 ? channels : 0;
        const response = await this.api.v2({
            uri: '/pin/change',
            params: {
                ...(purchaseConfirmation !== undefined ? { purchase_confirmation_enabled: purchaseConfirmation } : {}),
                ...(parentalControl !== undefined ? { parental_control_enabled: parentalControl } : {}),
                ...(inappropriateContent !== undefined ? { inappropriate_content_enabled: inappropriateContent } : {}),
                ...(level !== undefined ? { level: level } : {}),
                ...(channels !== undefined ? { channel_ids: channelsIds } : {}),
                ...(contactId !== undefined ? { contact_id: contactId } : {}),
                ...(code !== undefined ? { code: code } : {}),
                ...(pin !== undefined ? { pin: pin } : {}),
            },
            method: QUERY_POST,
        });
        return new PinStatus(response['status']);
    };
}
