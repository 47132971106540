/**
 * Class MultiscreenDevice
 * @package application\models\api
 * @see https://kblion.ertelecom.ru/display/BA/Trans+11.+Multiscreen
 */

export const DEVICE_PLATFORM = {
    IOS: 'ios',
    ANDROID: 'android',
    WEB: 'web',
    SMART_TV: 'smart_tv',
    TV_BOX: 'tv_box',
    CAM: 'cam',
    KTV: 'ktv',
    UNKNOWN: 'unknown',
};

const DISPLAY_TYPE = 'displayType';
const DEFAULT_NAME = 'defaultName';
const PLATFORM = 'platform';

const DeviceInfoAndroidIPTV = {
    [DISPLAY_TYPE]: 'ТВ-приставка',
    [DEFAULT_NAME]: 'Android IPTV',
    [PLATFORM]: DEVICE_PLATFORM.TV_BOX,
};

const DeviceInfoCAM = {
    [DISPLAY_TYPE]: 'Cam-модуль',
    [DEFAULT_NAME]: 'CAM',
    [PLATFORM]: DEVICE_PLATFORM.CAM,
};

const DeviceInfo = {
    ['stb']: {
        [DISPLAY_TYPE]: 'ТВ-приставка',
        [DEFAULT_NAME]: 'STB',
        [PLATFORM]: DEVICE_PLATFORM.TV_BOX,
    },
    ['ottweb']: {
        [DISPLAY_TYPE]: 'PC или Mac',
        [DEFAULT_NAME]: 'PC или Mac',
        [PLATFORM]: DEVICE_PLATFORM.WEB,
    },
    ['web']: {
        [DISPLAY_TYPE]: 'PC или Mac',
        [DEFAULT_NAME]: 'PC или Mac',
        [PLATFORM]: DEVICE_PLATFORM.WEB,
    },
    ['android']: {
        [DISPLAY_TYPE]: 'Android',
        [DEFAULT_NAME]: 'Android',
        [PLATFORM]: DEVICE_PLATFORM.ANDROID,
    },
    ['ios']: {
        [DISPLAY_TYPE]: 'iOS',
        [DEFAULT_NAME]: 'iOS',
        [PLATFORM]: DEVICE_PLATFORM.IOS,
    },
    ['humax7000']: {
        [DISPLAY_TYPE]: 'ТВ-приставка',
        [DEFAULT_NAME]: 'Humax 7000',
        [PLATFORM]: DEVICE_PLATFORM.TV_BOX,
    },
    ['smrttv']: {
        [DISPLAY_TYPE]: 'SmartTV',
        [DEFAULT_NAME]: 'SmartTV',
        [PLATFORM]: DEVICE_PLATFORM.SMART_TV,
    },
    ['iptv']: {
        [DISPLAY_TYPE]: 'ТВ-приставка',
        [DEFAULT_NAME]: 'IPTV',
        [PLATFORM]: DEVICE_PLATFORM.TV_BOX,
    },
    ['smarttv_samsung']: {
        [DISPLAY_TYPE]: 'SmartTV Samsung',
        [DEFAULT_NAME]: 'SmartTV Samsung',
        [PLATFORM]: DEVICE_PLATFORM.SMART_TV,
    },
    ['smarttv_lg']: {
        [DISPLAY_TYPE]: 'SmartTV LG',
        [DEFAULT_NAME]: 'SmartTV LG',
        [PLATFORM]: DEVICE_PLATFORM.SMART_TV,
    },
    ['stb30']: {
        [DISPLAY_TYPE]: 'ТВ-приставка',
        [DEFAULT_NAME]: 'STB30',
        [PLATFORM]: DEVICE_PLATFORM.TV_BOX,
    },
    ['android_iptv']: DeviceInfoAndroidIPTV,
    ['android_iptv_nag']: DeviceInfoAndroidIPTV,
    ['android_iptv_mobile']: DeviceInfoAndroidIPTV,
    ['androidtv_stb']: DeviceInfoAndroidIPTV,
    ['androidtv_stb_premium']: DeviceInfoAndroidIPTV,
    ['cnxcam21']: DeviceInfoCAM,
    ['cnxcam20']: DeviceInfoCAM,
    ['cnxold']: DeviceInfoCAM,
    ['ktv']: {
        [DISPLAY_TYPE]: 'ТВ-кабель',
        [DEFAULT_NAME]: 'KTV',
        [PLATFORM]: DEVICE_PLATFORM.KTV,
    },
};

export default class MultiscreenDevice {
    /** identifier of the device
     * @type {number}  */
    id;
    /** Unique external identifier of the device
     * @type {string} */
    extId;
    /** Flag showing whether this device is stb
     * @type {boolean} */
    isStb;
    /** Flag showing whether the device may perform DVR-recording
     *  @type {boolean} */
    isDvrCapable;
    /** Name of device, if any
     *  @type {string} */
    title;
    /** It is tv device (stb, smarttv, CAM or cable TV). If is_tv = true, with these device, you can view the channels from basic package
     * @type {boolean} */
    isTv;
    /** Device can not be unbound
     * @type {boolean} */
    isUnbindable;
    /** Smart_card identifier of the device
     * @type {number}  */
    smartCard;
    /** Time of device last bound
     * @type {?Date} */
    updateAt;
    /** External identifier of the platform
     * @type {string} */
    platform;
    /** Platform label
     * @type {string} */
    platformLabel;

    /**
     * MultiscreenDevice constructor.
     * @param {Object} object
     * @param {boolean} [object.is_dvr_capable]
     * @param {string} [object.title]
     * @param {string} [object.extid]
     * @param {boolean} [object.is_tv]
     * @param {boolean} [object.is_stb]
     * @param {number} object.id
     * @param {boolean} [object.is_unbindable]
     * @param {number} [object.smart_card]
     * @param {number} [object.update_at]
     * @param {string} [object.platform]
     */
    constructor(object) {
        this.isDvrCapable = object.is_dvr_capable;
        this.title = object.title;
        this.extId = object.extid;
        this.isTv = object.is_tv;
        this.isStb = object.is_stb;
        this.id = object.id;
        this.isUnbindable = object.is_unbindable;
        this.smartCard = object.smart_card;
        this.updateAt = object.update_at ? new Date(object.update_at) : null;

        const deviceInfo = DeviceInfo[object.platform];
        this.platform = deviceInfo ? deviceInfo[PLATFORM] : DEVICE_PLATFORM.UNKNOWN;
        this.platformLabel = deviceInfo ? deviceInfo[DISPLAY_TYPE] : 'Неизвестное устройство';
        this.title = this.title || (deviceInfo ? deviceInfo[PLATFORM] : 'Неизвестное устройство');
    }
}
