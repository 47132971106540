import { enableStaticRendering } from 'mobx-react';
import { createContext, useContext } from 'react';
import { RootStore, initStates } from '../stores/RootStore';

enableStaticRendering(typeof window === 'undefined');

let store;
const StoreContext = createContext(undefined);

export function useRootStore() {
    const context = useContext(StoreContext);
    if (context === undefined) {
        throw new Error('useRootStore must be used within RootStoreProvider');
    }
    return context;
}

/**
 * @returns {AuthStore}
 */
export function useAuthStore() {
    const { authStore } = useRootStore();
    return authStore;
}

/**
 * @returns {PinStore}
 */
export function usePinStore() {
    const { pinStore } = useRootStore();
    return pinStore;
}

/**
 * @returns {ApiStore}
 */
export function useApiStore() {
    const { apiStore } = useRootStore();
    return apiStore;
}

export function RootStoreProvider({ children, initialState }) {
    const store = initializeStore(initialState);
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}

export async function getInitialStoresState(ctx) {
    return initStates(ctx);
}

function initializeStore(initialData) {
    // For SSG and SSR always create a new store
    // Create the store once in the client
    if (typeof window === 'undefined' || !store) {
        store = new RootStore();
        if (initialData) {
            store.hydrate(initialData);
        }
    }
    return store;
}
