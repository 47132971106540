export default function Loader() {
    return (
        <span className="modal__loader">
            <span className="modal__text h-hidden--text">Загрузка</span>
            <span className="modal__loading">
                <span className="modal__loading-item"> </span>
                <span className="modal__loading-item"> </span>
                <span className="modal__loading-item"> </span>
            </span>
        </span>
    );
}
