import ModalForm from '@/components/modal/ModalForm';
import { useState } from 'react';
import { ButtonPrimary } from '@/components/button/Button';
import Pagination from '@/components/modal/onboarding/welcome/Pagination';
import useModals from '@/src/hooks/useModals';
import apiCache from '@/src/api/ApiCache';
import { useApiStore } from '@/src/stores/RootStoreProvider';

export default function ModalOnboardingWelcome({ items = [] }) {
    const { api } = useApiStore();
    const { closeModal } = useModals();
    const [currentIndex, setCurrentIndex] = useState(0);

    const onHide = async () => {
        const { welcomeId } = items[currentIndex];
        const count = currentIndex + 1;
        apiCache.remove('/api/v3/welcome');
        await api.welcomeItems.setWelcomeViewed(welcomeId, count);
    };

    const showNextItem = () => {
        if (currentIndex === items.length - 1) {
            closeModal();
            return onHide();
        }
        setCurrentIndex(currentIndex + 1);
    };

    if (!items[currentIndex]) {
        return;
    }
    const { title = '', description = '', resourceUrlDesktop = '' } = items[currentIndex] || {};
    const btnText = currentIndex === items.length - 1 ? 'Смотреть' : 'Продолжить';

    return (
        <ModalForm
            className={'modal--primary modal--onboarding'}
            pageUrl={'#welcome'}
            pageTitle={'Онлайн-кинотеатр Movix - фильмы, сериалы и мультфильмы смотреть онлайн в хорошем качестве'}
            onHide={onHide}
        >
            <div className="modal__img-wrapper">
                <img className="modal__img" src={resourceUrlDesktop || '/assets/img/stub_441-694.png'} alt="welcome" />
            </div>
            <div key={title} className="modal__content-title modal__content-title--center">
                {title}
            </div>
            <div key={description} className="modal__content-subtitle">
                {description}
            </div>
            <div className="modal__btnset">
                <div className="modal__wrapper-btn">
                    <ButtonPrimary title={btnText} onClick={showNextItem} />
                </div>
            </div>
            {items.length > 1 && <Pagination items={items} currentItemIndex={currentIndex} />}
        </ModalForm>
    );
}
