import { QUERY_POST } from '@/src/api/Api';
import { FavoriteStatus } from '@/src/constants/types';
import { ApiMixin } from '@/src/api/ApiMixin';

/**
 * Trait Favorites
 * @see https://kblion.ertelecom.ru/display/BA/v3-Favorites
 */

export class Favorites extends ApiMixin {
    /** Create/update user content estimation mark
     * @param {number} id
     * @param {string} type
     * @param {string} status
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    setFavoriteStatus = async (id, type, status) => {
        await this.api.v3({
            uri: '/api/v3/favorites',
            params: {
                id: id,
                type: type,
                favorite: status,
            },
            method: QUERY_POST,
        });
    };

    /**
     * Добавить в избранное
     * @param {number} id
     * @param {string} type
     * @returns {Promise<void>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    addToFavorites = async (id, type) => {
        this.setFavoriteStatus(id, type, FavoriteStatus.FAVORITE);
    };

    /**
     * Убрать из избранного
     * @param {number} id
     * @param {string} type
     * @returns {Promise<void>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    removeFromFavorites = async (id, type) => {
        this.setFavoriteStatus(id, type, FavoriteStatus.NOT_FAVORITE);
    };
}
