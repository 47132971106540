import { action, computed, makeObservable, observable } from 'mobx';
import ApiToken from '@/src/models/ApiToken';
import { COOKIES } from '@/src/constants/cookies';
import {Api } from '@/src/api/Api';
import {MILLISECONDS_IN_DAY} from '@/src/helpers/TimeHelper';
import {getApi} from "@/src/helpers/ApiHelper";
import {slug} from "@/src/modules/Common";

export class AuthStore {
    root;

    @observable token = '';

    constructor(root) {
        this.root = root;
        makeObservable(this);
    }

    @action
    hydrate(data) {
        if (data) {
            this.token = data.token;
        }
    }

    @computed
    get isGuest() {
        if (!this.token) {
            return true;
        }
        const apiToken = new ApiToken(this.token);
        return apiToken.isGuest();
    }

    @computed
    get contractNumber() {
        if (!this.token) {
            return '';
        }
        const apiToken = new ApiToken(this.token);
        return apiToken.getContractNumber();
    }

    @computed
    get isOTT() {
        if (!this.token) {
            return false;
        }
        const apiToken = new ApiToken(this.token);
        return apiToken.isOTT();
    }

    @computed
    get isME() {
        if (!this.token) {
            return true;
        }
        const apiToken = new ApiToken(this.token);
        return apiToken.isME();
    }
}

const getTokenIfValid = (token) => {
    try {
        new ApiToken(token);
        return token;
    } catch (e) {
        // Nothing
    }
    return null;
}

/**
 * @param {String} token
 * @param {String} endpoint
 */
const refreshTokenIfExpireSoon = async (token, endpoint) => {
    let newToken = token;
    try {
        const apiToken = new ApiToken(token, endpoint);
        const timeToExpire = apiToken.getExpires() - new Date();
        if (timeToExpire < MILLISECONDS_IN_DAY) {
            const tempApi = new Api(token);
            newToken = await tempApi.authorization.tokenRefresh();
        }
    } catch (e) {
        // NoThing;
    }
    return newToken;
}

export async function getInitialStoreState(ctx) {
    let token;
    const tempApi = new Api(null, ctx.req.cookies[COOKIES.API_ENDPOINT]);
    // Сквозная авторизация
    try {
        const utmSource = ctx.query.utm_source;
        let ssoKey;
        let accessToken;
        switch (utmSource) {
            case 'email': {
                const agreement = ctx.query.agreement;
                const params = {
                    "agreement": agreement,
                    "client_id": ctx.query.clientId,
                    "timestamp": ctx.query.timestamp,
                    "secret": ctx.query.secret,
                    "grant_type": ctx.query.grantType,
                    "city_id": ctx.query.city_id,
                    "utm_source": utmSource,
                    "utm_medium": ctx.query.utm_medium,
                    "utm_campaign": ctx.query.utm_campaign,
                }
                if (agreement) {
                    ssoKey = await tempApi.authorization.getSSOTokenByReferalLink(params);
                }
                break;
            }
            case 'equila':
                accessToken = ctx.query.token;
                if (accessToken) {
                    ssoKey = await tempApi.authorization.getSSOTokenByAccessTokenAndCityId(accessToken, ctx.query.city_id);
                }
                break;
            default:
                accessToken = ctx.query.access_token;
                if (accessToken) {
                    ssoKey = await tempApi.authorization.getSSOTokenByAccessToken(accessToken, ctx.query.region);
                }
        }
        if (ssoKey) {
            const api = await getApi({ query: {}, req: ctx.req});
            token = await api.authorization.getTokenBySSOKey(ssoKey);
        }
    } catch (e) {
        // Nothing
    }
    // Берем token из адресной строки или из Cookie
    if (!token) {
        // TODO: Если куки - пустые, то вызовется 2 раза
        token = getTokenIfValid(ctx.query.token) || getTokenIfValid(ctx.req.cookies[COOKIES.API_TOKEN]);
    }
    if (token) {
        token = await refreshTokenIfExpireSoon(token, tempApi.endpoint);
    }
    // Если токена нет, то берем гостевой токен
    if (!token) {
        const guestToken = process.env[guestTokenEnvName(tempApi.endpoint)];
        if (guestToken) {
            token = await refreshTokenIfExpireSoon(guestToken, tempApi.endpoint);
            if (token !== guestToken) {
                process.env[guestTokenEnvName(tempApi.endpoint)] = token;
            }
        }
    }
    // Если нет и гостевого токена, то запрашиваем его
    if (!token) {
        token = await tempApi.authorization.getDeviceToken();
        process.env[guestTokenEnvName(tempApi.endpoint)] = token;
    }
    return {
        token: token,
        endpoint: tempApi.endpoint,
    };
}

const guestTokenEnvName = (endpoint) => {
    return `MOVIX_GUEST_TOKEN_${slug(endpoint)}`;
}
