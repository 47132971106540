import { PurchaseType } from '@/src/constants/purchase-type';

export const SECONDS_IN_DAY = 24 * 60 * 60;

/**
 * Настройки кешей для Api
 * @see \Api
 */
export const CACHE_CONFIG = {
    // Global Cache

    // GeneralInformation
    ['/er/misc/subscriber/timezone_list']: {
        anyToken: true,
    },
    ['/parental_control/levels']: {
        anyToken: true,
    },
    ['/er/misc/domains']: {
        anyToken: true,
    },
    ['/token/device']: {
        invalidationTime: 30 * SECONDS_IN_DAY,
        anyToken: true,
    },

    // User Cache
    ['/er/billing/channel_list/url']: {
        invalidationTime: SECONDS_IN_DAY,
    },

    // Billing
    ['/api/er/billing/balance']: {},
    ['/api/er/billing/bonus_balance']: {},
    ['/er/billing/devices']: {},
    ['/er/billing/acknowledge_purchase']: {
        dependencies: ['/api/er/billing/balance'],
    },
    ['/er/billing/purchase']: {
        dependencies: ['/api/er/billing/balance'],
    },
    ['/er/billing/bundle/activate']: {
        dependencies: [
            '/er/catchup/status',
            '/er/watch_everywhere/status',
            '/er/multiscreen/status',
            '/api/er/billing/balance',
            '/er/billing/devices',
            '/er/multiscreen/devices',
        ],
    },
    ['/er/billing/activate_channel_package']: {
        dependencies: ['/api/er/billing/balance'],
    },
    ['/er/billing/promocode/activate']: {
        dependencies: ['/api/er/billing/bonus_balance'],
    },

    // Catchup
    ['/er/catchup/status']: {},
    ['er/catchup/activate']: {
        dependencies: ['/er/catchup/status', '/api/er/billing/balance'],
    },
    ['er/catchup/deactivate']: {
        dependencies: ['/er/catchup/status'],
    },

    // GeneralInformation
    ['/er/misc/subscriber/support_phone']: {
        invalidationTime: 7 * SECONDS_IN_DAY,
    },
    ['/er/misc/subscriber/contacts']: {},
    ['/er/misc/subscriber/contact/permit']: {},
    ['/er/misc/subscriber/get_fio']: {},
    ['/er/misc/subscriber/timezone']: {},
    ['/er/misc/subscriber/timezone/change']: {
        dependencies: ['/er/misc/subscriber/timezone'],
    },
    ['/er/misc/subscriber/contact/phone/add']: {
        dependencies: ['/er/misc/subscriber/contacts', '/er/misc/subscriber/contact/permit'],
    },
    ['/er/misc/subscriber/contact/email/add']: {
        dependencies: ['/er/misc/subscriber/contacts', '/er/misc/subscriber/contact/permit'],
    },
    ['/er/misc/subscriber/contact/email/confirm']: {
        dependencies: ['/er/misc/subscriber/contacts', '/er/misc/subscriber/contact/permit'],
    },
    ['/er/misc/subscriber/contact/email/remove']: {
        dependencies: ['/er/misc/subscriber/contacts', '/er/misc/subscriber/contact/permit'],
    },
    ['/er/misc/subscriber/contact/phone/remove']: {
        dependencies: ['/er/misc/subscriber/contacts', '/er/misc/subscriber/contact/permit'],
    },

    // Multiscreen
    ['/er/multiscreen/status']: {},
    ['/er/multiscreen/devices']: {
        invalidationTime: 7 * SECONDS_IN_DAY,
    },
    ['/er/multiscreen/device/bind']: {
        dependencies: ['/er/multiscreen/status', '/er/multiscreen/devices', '/er/watch_everywhere/status'],
    },
    ['/er/multiscreen/device/unbind']: {
        dependencies: ['/er/multiscreen/status', '/er/multiscreen/devices', '/er/watch_everywhere/status'],
    },
    ['/er/multiscreen/device/rename']: {
        dependencies: ['/er/multiscreen/devices'],
    },

    // Payment
    ['/er/billing/payment/bindings/']: {},
    ['/er/billing/payment/pre_register/']: {
        dependencies: ['/er/billing/payment/bindings/'],
    },
    ['/er/billing/payment/register/']: {
        dependencies: ['/er/billing/payment/bindings/', '/api/er/billing/balance'],
    },
    ['/er/billing/payment/register/binding/']: {
        dependencies: ['/er/billing/payment/bindings/', '/api/er/billing/balance'],
    },
    ['/er/billing/payment/unbind/']: {
        dependencies: ['/er/billing/payment/bindings/'],
    },
    ['/er/pay/card']: {
        dependencies: ['/api/er/billing/balance'],
        conditionalDependencies: [
            {
                params: {
                    key: 'type',
                    value: PurchaseType.CATCHUP,
                },
                key: '/er/catchup/status',
            },
        ],
    },

    // Parental Control
    ['/parental_control/status']: {},
    ['/parental_control/enable']: {
        dependencies: ['/parental_control/status'],
    },
    ['/parental_control/disable']: {
        dependencies: ['/parental_control/status'],
    },
    ['/parental_control/level/change']: {
        dependencies: ['/parental_control/status'],
    },
    ['/parental_control/channels']: {
        dependencies: ['/parental_control/status'],
    },
    ['/er/billing/parental_control/enable']: {
        dependencies: ['/parental_control/status'],
    },
    ['/er/billing/parental_control/disable']: {
        dependencies: ['/parental_control/status'],
    },
    ['/er/billing/parental_control/pin/change']: {
        dependencies: ['/parental_control/status'],
    },
    ['/pin/status']: {},
    ['/pin/enable']: {
        dependencies: ['/pin/status'],
    },
    ['/pin/disable']: {
        dependencies: ['/pin/status'],
    },
    ['/pin/change']: {
        dependencies: ['/pin/status'],
    },

    // WatchEverywhere
    ['/er/watch_everywhere/status']: {},
    ['/er/watch_everywhere/activate']: {
        dependencies: [
            '/er/watch_everywhere/status',
            '/er/multiscreen/status',
            '/api/er/billing/balance',
            '/er/billing/devices',
            '/er/multiscreen/devices',
        ],
    },
    ['/er/watch_everywhere/deactivate']: {
        dependencies: ['/er/watch_everywhere/status'],
    },

    // v3
    ['/api/v3/channels']: {},
    ['/api/v3/welcome']: {},

    // Showcases
    ['/api/v3/showcases/library/subscriptions?adult=ADULT,NOT_ADULT&limit=48']: {},
    ['/api/v3/showcases/library/channel-packages?adult=ADULT,NOT_ADULT&limit=48']: {},
    ['/api/v3/showcases/library/slider-top?adult=ADULT,NOT_ADULT&offset=10&limit=48']: {
        invalidationTime: 3600,
    },

    // Guest Showcases
    ['/api/v3/showcases/library/subscriptions?adult=NOT_ADULT&limit=48']: {},
    ['/api/v3/showcases/library/channel-packages?adult=NOT_ADULT&limit=48']: {},

    // Server-Side Caches
    ['/api/v3/pages/library?adult=NOT_ADULT&limit=1']: {
        invalidationTime: 600,
        storeOnServerOnly: true,
    },
};
