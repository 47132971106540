import Showcase from '@/src/api/v3/models/Showcase';
import { ApiMixin } from '@/src/api/ApiMixin';
import { buildUrl } from '@/src/api/v3/APIv3';

export class Showcases extends ApiMixin {
    /**
     * Загрузить витрину
     * @param {Object} props
     * @param {string} props.urn
     * @param {?string} [props.params]
     * @param {?number} [props.offset]
     * @param {?number} [limit]
     * @param {?Object} [cacheConfig]
     * @param {?string} [cacheConfig.key]
     * @param {?number} [cacheConfig.invalidationTime]
     * @returns {Promise<Showcase>}
     */
    loadShowcase = async ({ urn, params = null, offset = null, limit = null }, cacheConfig) => {
        const url = buildUrl(urn, this.api.token, { params, offset, limit });
        const response = await this.api.setCacheConfig(cacheConfig).v3({ uri: url });
        return new Showcase(response['data']);
    };
}
