import { ApiMixin } from '@/src/api/ApiMixin';

export class Resources extends ApiMixin {
    /**
     * Receiving link on HLS stream
     * @param {number} assetId
     * @param {number} resourceId
     * @param {?number} drmId
     * @returns {Promise<JSON>}
     * @throws {ApiBackendException}
     */
    getResourceUrl = async (assetId, resourceId, drmId = null) => {
        return this.api.v2({
            uri: `/resource/get_url/${assetId}/${resourceId}/`,
            params: drmId ? { drm_id: drmId } : null,
        });
    };

    /**
     * Receiving link on preview HLS stream
     * @param {number} assetId
     * @param {number} resourceId
     * @returns {Promise<JSON>}
     * @throws {ApiBackendException}
     */
    getResourcePreviewUrl = async (assetId, resourceId) => {
        return this.api.v2({
            uri: `/resource/get_preview_url/${assetId}/${resourceId}/`,
        });
    };

    /**
     * Receiving link on preview HLS stream
     * @param {number} assetId
     * @returns {Promise<Object>}
     * @throws {ApiBackendException}
     */
    getResourcePreviewAvailable = async (assetId) => {
        const result = await this.api.v2({
            uri: `/resource/get_preview_available/${assetId}`,
        });
        return result['status'] || { state: 0 };
    };
}
