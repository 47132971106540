/**
 * Склонение числительных (для русского языка)
 *
 * Принимает число и выбирает соответствующее склонение числительного. Всего 3 варианта, которые
 * соответствуют числам 1, 2 и 5.
 *
 * @returns string
 * @param {number} number
 * @param {string} one
 * @param {string} two
 * @param {string} five
 */
export function plural(number, one, two, five) {
    let result;
    if ((number - (number % 10)) % 100 !== 10) {
        if (number % 10 === 1) {
            result = one;
        } else if (number % 10 >= 2 && number % 10 <= 4) {
            result = two;
        } else {
            result = five;
        }
    } else {
        result = five;
    }
    return result;
}

/**
 * Склонение количества минут
 * @param minutes
 * @returns string
 */
export function minutes(minutes) {
    return plural(minutes, 'минута', 'минуты', 'минут');
}
