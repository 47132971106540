export const LIMIT_RESULTS = 48;

export function getImageUrlByResourceId(id, options = null) {
    const host = 'https://er-cdn.ertelecom.ru';
    let optionUri = '';
    let size = options?.['size'];
    if (size) {
        const func = size['func'] ?? 'crop';
        optionUri = `/${size['width']}x${size['height']}:${func}`;
    }
    let uri = `content/public/r${id}`;
    uri += optionUri;
    return host + '/' + uri;
}

/**
 * Получить список пакетов каналов
 * @param {Api} api
 * @param [isPurchasedFilter] {boolean}
 * @returns {Promise<ChannelPackage[]>}
 */
export async function getChannelPackages(api, isPurchasedFilter = false) {
    const showcase = await api.showcases.loadShowcase({
        urn: 'showcases/library/channel-packages',
        limit: LIMIT_RESULTS,
    });
    const items = /** @type {ChannelPackage[]} */ showcase.items;
    return items.filter((item) => !isPurchasedFilter || item.offers.getPurchaseDate());
}

/**
 * Получить список пакетов каналов
 * @param {Api} api
 * @param [isPurchasedFilter] {Boolean}
 * @param [isNotAdultFilter] {Boolean}
 * @returns {Promise<Subscription[]>}
 */
export async function getSubscriptions(api, isPurchasedFilter = false, isNotAdultFilter = false) {
    const showcase = await api.showcases.loadShowcase({
        urn: 'showcases/library/subscriptions',
        limit: LIMIT_RESULTS,
    });
    const items = /** @type {Subscription[]} */ showcase.items;
    return items.filter(
        (item) => (!isPurchasedFilter || item.offers.getPurchaseDate()) && (!isNotAdultFilter || !item.isAdult())
    );
}

export const FILTER_ALL = '14'; // Все каналы
export const FILTER_FAVORITE = '15'; // Избранное
export const FILTER_AVAILABLE = '16'; // Доступные
export const FILTER_FREE_FEDERAL = '1'; // Федеральные
export const FILTER_CHANNEL_PACKAGES = 'channel-packages';

export const CHANNELS_FILTER_LOCAL_STORAGE_KEY = 'channels_filter';
export const CHANNELS_FILTER_PACKAGES_LOCAL_STORAGE_KEY = 'channels_filter_packages';
export const CHANNELS_FOLDER_LOCAL_STORAGE_KEY = 'channels_folder';
export const CHANNELS_CATEGORY_LOCAL_STORAGE_KEY = 'channels_category';

/**
 * Получить категории каналов
 * @param {Api} api
 * @param {boolean} [isGuest]
 * @returns Promise<array>
 */
export async function getCategories(api, isGuest) {
    /** @typedef Option */
    /** @type Array.<{value: String, label: String, checked: boolean = false, available: boolean = false}> */
    let options = await getChannelsFilters(api, []);
    /** @type Array.<{title: String, options: Option[]}> */
    let categories = [{ title: null, options: options }];
    try {
        // /** @type Array.<{id: int, title: String, available: Object}> */
        let channelPackages = await getChannelPackages(api);
        /** @type Option[] */
        let categoriesChannelPackages = [];
        categoriesChannelPackages.push(
            ...channelPackages.map((channelPackage) => {
                return {
                    value: String(channelPackage.id),
                    label: channelPackage.title,
                    available: channelPackage.isAvailable(),
                    isChannelPackage: true,
                };
            })
        );
        categories.push({ title: 'Пакеты каналов', options: categoriesChannelPackages });
    } catch (error) {
        // Nothing
    }
    return categories;
}

/** @typedef ChannelFilter */
/** @type Array.<{value: String, label: String, checked: boolean = false, available: boolean = false}> */

/**
 * Получить фильтры (категории) каналов из каналов
 * @returns <ChannelFilter[]>
 */
export function getFiltersFromChannels(channels, excludeFilters = []) {
    let filters = {};
    channels.forEach((channel) => {
        channel.categories.forEach((category) => {
            if (!filters[category.weight] && !excludeFilters.includes(category.id.toString())) {
                filters[category.weight] = { value: category.id.toString(), label: category.title };
            }
        });
    });
    return Object.values(filters);
}

/**
 * Получить фильтры (категории) каналов
 * @param {Api} api
 * @param excludeFilters
 * @returns Promise<ChannelFilter[]>
 */
export async function getChannelsFilters(api, excludeFilters = [FILTER_ALL, FILTER_FAVORITE]) {
    const channels = await api.channels.getChannels();
    return getFiltersFromChannels(channels, excludeFilters);
}

/**
 * Получить папки каналов
 * @param {boolean} [isGuest]
 * @returns Array
 */
export function getChannelsFolders(isGuest) {
    return isGuest
        ? [
              { value: FILTER_ALL, label: 'Все каналы', icon: 'channels' },
              { value: FILTER_CHANNEL_PACKAGES, label: 'Пакеты каналов', icon: 'folders' },
          ]
        : [
              { value: FILTER_ALL, label: 'Все каналы', icon: 'channels' },
              { value: FILTER_FAVORITE, label: 'Избранные', icon: 'love' },
              { value: FILTER_CHANNEL_PACKAGES, label: 'Пакеты каналов', icon: 'folders' },
          ];
}
