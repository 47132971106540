import { AuthStore, getInitialStoreState as getInitialAuthStoreState } from '@/src/stores/AuthStore';
import { PinStore, getInitialStoreState as getInitialPinStoreState } from '@/src/stores/PinStore';
import { ApiStore } from '@/src/stores/ApiStore';

export class RootStore {
    /** @type {AuthStore} */
    authStore;
    /** @type {PinStore} */
    pinStore;
    /** @type {ApiStore} */
    apiStore;

    constructor() {
        this.authStore = new AuthStore(this);
        this.pinStore = new PinStore(this);
        this.apiStore = new ApiStore(this);
    }

    hydrate(data) {
        if (data.authStore) {
            this.authStore.hydrate(data.authStore);
            this.apiStore.hydrate(data.authStore);
        }
        if (data.pinStore) {
            this.pinStore.hydrate(data.pinStore);
        }
    }
}

export async function initStates(ctx) {
    return {
        authStore: await getInitialAuthStoreState(ctx),
        pinStore: getInitialPinStoreState(ctx),
        apiStore: {},
    };
}
