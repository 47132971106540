import BaseModel from '@/src/api/v3/models/BaseModel';
import Children from '@/src/api/v3/models/mixins/Children';
import { ShowcaseItemType } from '@/src/constants/types';

/**
 *  Подборка
 */
export default class Compilation extends BaseModel {
    SLIDER_STUB_CLASS = 'cover--compilations';
    URL_PREFIX = 'compilations';
    EVENT_ITEM_CATEGORY = ShowcaseItemType.COLLECTION;

    /** Описание
     *  @type {string} */
    description;

    images = [
        '3_smarttv_package_poster_video_library_blueprint',
        '3_smarttv_catalog_poster_video_library_blueprint',
        'poster_blueprint',
    ];
    backgroundModifier = 'backbg--package';

    /**
     * Compilation constructor.
     * @param {Object} object
     */
    constructor(object) {
        super(object);
        this.children = object.children ? new Children(object.children) : null;
        this.description = object.description;
    }

    /**
     * Получить изборажение для фона
     * @returns null|string
     */
    getBackgroundSrc() {
        return this.resources.getResourceUrl('3_smarttv_package_background_video_library_blueprint', {
            size: { width: 1775, height: 623 },
        });
    }
}
