import { CACHE_CONFIG } from '@/src/api/cache.config';
import APIv2 from '@/src/api/v2/APIv2';
import APIv3 from '@/src/api/v3/APIv3';
import { humanFileSize } from '@/src/helpers/FormatHelper';
import { logout } from '@/src/helpers/AuthHelper';
import { logDev } from '@/src/modules/Common';
import apiCache from '@/src/api/ApiCache';
import { Billing } from '@/src/api/v2/Billing';
import { GeneralInformation } from '@/src/api/v2/GeneralInformation';
import { Payment } from '@/src/api/v2/Payment';
import { Authorization } from '@/src/api/v2/Authorization';
import { Catchup } from '@/src/api/v2/Catchup';
import { Epg } from '@/src/api/v2/Epg';
import { Multiscreen } from '@/src/api/v2/Multiscreen';
import { OTT } from '@/src/api/v2/OTT';
import { ParentalControl } from '@/src/api/v2/ParentalControl';
import { Resources } from '@/src/api/v2/Resources';
import { Undocumented } from '@/src/api/v2/Undocumented';
import { WatchEverywhere } from '@/src/api/v2/WatchEverywhere';
import { Channels } from '@/src/api/v3/Channels';
import { Favorites } from '@/src/api/v3/Favorites';
import { Pages } from '@/src/api/v3/Pages';
import { Showcases } from '@/src/api/v3/Showcases';
import { ViewStatus } from '@/src/api/v3/ViewStatus';
import { Votes } from '@/src/api/v3/Votes';
import { WelcomeItems } from '@/src/api/v3/WelcomeItems';
import { Game } from '@/src/api/v3/Game';
import { Search } from '@/src/api/v3/Search';
import { COOKIES } from '@/src/constants/cookies';
import Cookies from 'js-cookie';
import { replaceAll } from '@/src/helpers/PolyfillHelper';

export const QUERY_POST = 'POST';
export const QUERY_GET = 'GET';

export const BACKEND_ENDPOINTS = [process.env.API_V3_ENDPOINT_URL, 'https://discovery-preprod.ertelecom.ru'];
export const APP_VERSION = replaceAll(
    (process.env.NEXT_PUBLIC_VERSION || process.env.APP_VERSION).replace('v', ''),
    '-',
    '.'
);

export class Api {
    /** @type {string} */
    token;
    /** @type {Object} */
    cacheConfig;
    /** @type {Boolean} */
    tokenUpdated;
    /** @type {String} */
    endpoint;

    constructor(token, endpoint = BACKEND_ENDPOINTS[0]) {
        this.token = token;
        this.endpoint = BACKEND_ENDPOINTS.includes(endpoint) ? endpoint : BACKEND_ENDPOINTS[0];
        this.tokenUpdated = false;
    }

    setToken(token) {
        if (this.token !== token) {
            this.tokenUpdated = true;
        }
        if (typeof window !== 'undefined') {
            Cookies.set(COOKIES.API_TOKEN, token, { expires: 365 });
        }
        this.token = token;
    }

    setEndpoint(endpoint) {
        this.endpoint = endpoint;
        if (typeof window !== 'undefined') {
            Cookies.set(COOKIES.API_ENDPOINT, endpoint, { expires: 365 });
        }
    }

    /** @param {Object} cacheConfig */
    setCacheConfig(cacheConfig) {
        this.cacheConfig = cacheConfig;
        return this;
    }

    /**
     * Вызов API v2
     * @param {string} uri
     * @param {?Object} params
     * @param {string} method
     * @param {boolean} useHeaders
     * @returns {Promise<JSON>}
     */
    async v2({ uri, params = null, method = QUERY_GET, useHeaders = true }) {
        return this.call({ apiObject: APIv2, uri, params, method, useHeaders });
    }

    /**
     * Вызов API v3
     * @param {string} uri
     * @param {?Object} params
     * @param {string} method
     * @param {boolean} useHeaders
     * @returns {Promise<JSON>}
     */
    async v3({ uri, params = null, method = QUERY_GET, useHeaders = true }) {
        return this.call({ apiObject: APIv3, uri, params, method, useHeaders });
    }

    /**
     * Проверка токена на истечение\валидность. В случае невалидности, происходит разлогинивание с уничтожением сессии
     * @param {string} code
     */
    async validateToken(code) {
        if (code === 'token expired') {
            // TODO: Временный фикс, переделать API
            const newToken = await this.authorization.tokenRefresh();
            this.setToken(newToken);
        }
        if (code === 'invalid token') {
            logout();
        }
    }

    /**
     * Вызов API
     * @param {Object} apiObject
     * @param {string} uri
     * @param {?Object} params
     * @param {string} method
     * @param {boolean} useHeaders
     * @returns {Promise<JSON>}
     */
    async call({ apiObject, uri, params = null, method = QUERY_GET, useHeaders = true }) {
        let cacheConfig = this.cacheConfig || CACHE_CONFIG[uri];
        this.cacheConfig = null;
        // In-Memory Cache for Pages
        if (!cacheConfig && typeof window !== 'undefined' && uri.startsWith('/api/v3/pages/')) {
            cacheConfig = {
                invalidationTime: 600,
                storeInMemory: true,
            };
        }
        if (cacheConfig && !cacheConfig.dependencies && method === QUERY_GET) {
            if (!cacheConfig.key) {
                cacheConfig.key = uri;
            }
            const cache = apiCache.get(cacheConfig, this.token);
            if (cache) {
                logDev(`[Api] ${uri} [cache] (${humanFileSize(JSON.stringify(cache).length)})`);
                return cache;
            }
        }

        const url = `${this.endpoint}${uri}`;
        const requestStartTime = Date.now();
        const response = await apiObject.httpQuery(url, this.token, params, method, useHeaders);
        const requestDuration = Date.now() - requestStartTime;
        if (!response.ok) {
            logDev(`[Api] ${uri} [${requestDuration} ms], Network error: ${response.statusText}`);
            throw new Error(response.statusText || response.status); // Network Error
        }
        const responseJson = method === QUERY_POST && apiObject.isPostResponseEmpty ? {} : await response.json();
        logDev(`[Api] ${uri} [${requestDuration} ms] (${humanFileSize(JSON.stringify(responseJson).length)})`);
        await this.validateToken(responseJson.error?.exception?.code || responseJson.error?.message);
        apiObject.validateJson(url, responseJson);
        if (cacheConfig?.dependencies) {
            cacheConfig.dependencies.map((key) => apiCache.remove(key));
        }
        if (params && cacheConfig?.conditionalDependencies) {
            cacheConfig.conditionalDependencies.map((dependency) => {
                if (params[dependency.params.key] === dependency.params.value) {
                    apiCache.remove(dependency.key);
                }
            });
        }
        if (cacheConfig?.key) {
            apiCache.set(cacheConfig, responseJson, this.token);
        }
        if (method === QUERY_POST) {
            apiCache.clearMemoryCache();
        }
        return responseJson;
    }

    // v2 API
    authorization = new Authorization(this);
    billing = new Billing(this);
    catchup = new Catchup(this);
    epg = new Epg(this);
    generalInformation = new GeneralInformation(this);
    multiscreen = new Multiscreen(this);
    ott = new OTT(this);
    parentalControl = new ParentalControl(this);
    payment = new Payment(this);
    resources = new Resources(this);
    undocumented = new Undocumented(this);
    watchEverywhere = new WatchEverywhere(this);
    // v3 API
    channels = new Channels(this);
    favorites = new Favorites(this);
    pages = new Pages(this);
    showcases = new Showcases(this);
    viewStatus = new ViewStatus(this);
    votes = new Votes(this);
    welcomeItems = new WelcomeItems(this);
    game = new Game(this);
    search = new Search(this);
}
