import styles from './Social.module.scss';
import Button from '@/components/button/Button';

export default function Social() {
    return (
        <ul className={styles.social}>
            <li className="social__item">
                <Button
                    type={'link'}
                    image={'/assets/img/icon-ok.svg'}
                    imageProps={{ width: 17, height: 28 }}
                    className={'button--icon-only button--secondary button--simple social__link'}
                    href={'https://ok.ru/domru'}
                    aria-label={'Одноклассники'}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                />
            </li>
            <li className="social__item">
                <Button
                    type={'link'}
                    image={'/assets/img/icon-vk.svg'}
                    imageProps={{ width: 28, height: 16 }}
                    className={'button--icon-only button--secondary button--simple social__link'}
                    href={'https://vk.com/domru'}
                    aria-label={'Вконтакте'}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                />
            </li>
        </ul>
    );
}
