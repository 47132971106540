import AnalyticLink from '@/components/header/AnalyticLink';
import styles from './MenuGroup.module.scss';
import CardNav from '@/components/card-nav/CardNav';
import Icon from '@/components/icon/Icon';

export default function MenuGroup({ group, title, url, modifier, grid = false }) {
    return (
        <div className={styles['menu-group'] + (modifier ? ' ' + modifier : '')}>
            {title && (
                <AnalyticLink className="menu-group__label" href={url}>
                    {title}
                    <Icon id={'arrow-right'} modifier={'icon--small menu-group__icon'} />
                </AnalyticLink>
            )}
            <div className={'menu-group__list'}>
                {grid ? (
                    group.map(
                        (item, index) =>
                            item && (
                                <CardNav
                                    key={index} // NOSONAR
                                    href={item.url}
                                    {...item}
                                />
                            )
                    )
                ) : (
                    <div className="menu-group__col">
                        {group.map(
                            (item, index) =>
                                item && (
                                    <AnalyticLink
                                        className={'menu-group__item' + (item.modifier ? ' ' + item.modifier : '')}
                                        href={item.url}
                                        key={index} // NOSONAR
                                    >
                                        {item.name}
                                    </AnalyticLink>
                                )
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
