import { Quality, ShowcaseItemType } from '@/src/constants/types';
import Subscription from '@/src/api/v3/models/Subscription';
import { RenderType } from '@/src/api/v3/models/Showcase';
import Children from '@/src/api/v3/models/mixins/Children';

/**
 *  Пакет каналов
 */
export default class ChannelPackage extends Subscription {
    URL_PREFIX = 'channel-packages';
    EVENT_ITEM_CATEGORY = ShowcaseItemType.CHANNEL_PACKAGE;

    /** Название типа
     *  @type {string} */
    typeName = 'Пакет каналов';
    /** Количество SD-каналов
     *  @type {number} */
    sdChannels = 0;
    /** Количество HD-каналов
     *  @type {number} */
    hdChannels = 0;
    /** Каналы
     *  @type {Children} */
    children;

    /**
     * Создать элемент из объекта
     * @param object
     * @returns mixed
     * @throws Exception
     */
    constructor(object) {
        super(object);
        if (object.quality) {
            object.quality.forEach((quality) => {
                if (quality.type === Quality.SD) {
                    this.sdChannels = quality.count;
                }
                if (quality.type === Quality.HD) {
                    this.hdChannels = quality.count;
                }
            });
        }
        this.children = new Children(object.children);
        if (this.children) {
            let showcase = this.children.showcases[0] ?? null;
            if (showcase) {
                showcase.title = this.getInfoShowcase();
                showcase.renderType = RenderType.PLAIN;
            }
        }
    }

    /** Получить информацию о пакете каналов для витрины
     * @returns string
     */
    getInfoShowcase() {
        const channelInfo = [];
        if (this.sdChannels) {
            channelInfo.push(`${this.sdChannels} SD`);
        }
        if (this.hdChannels) {
            channelInfo.push(`${this.hdChannels} HD`);
        }
        return channelInfo.length ? `Каналы в пакете — ${channelInfo.join(', ')}` : 'Каналы в пакете';
    }
}
