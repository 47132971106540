import Link from 'next/link';
import styles from './Logo.module.scss';

export default function Logo({ isAltLogo }) {
    return (
        <Link className={styles['logo'] + ' menu__logotype'} href="/">
            <img
                className={'logo__img' + (isAltLogo ? ' logo__alt' : '')}
                src="/assets/img/logotype.svg"
                width="116"
                height="28"
                alt="Дом ру Movix"
            />
            <span>Видеосервис от Дом.ру</span>
        </Link>
    );
}
