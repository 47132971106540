import Subscription from '@/src/api/v3/models/Subscription';
import BundleInfo from '@/src/api/v3/models/BundleInfo';
import { ShowcaseItemType } from '@/src/constants/types';

/**
 *  Бандл
 */
export default class Bundle extends Subscription {
    URL_PREFIX = 'bundles';
    EVENT_ITEM_CATEGORY = ShowcaseItemType.BUNDLE;
    /** @type {BundleInfo[]} Доп. информация */
    bundleInfo = [];

    /**
     * Создать элемент из объекта
     * @param object
     * @returns Bundle
     * @throws Exception
     */
    constructor(object) {
        super(object);
        if (object['subscriptions']) {
            object['subscriptions'].forEach((infoObject) => {
                this.bundleInfo.push(new BundleInfo(infoObject));
            });
        }
        if (object['services']) {
            object['services'].forEach((infoObject) => {
                this.bundleInfo.push(new BundleInfo(infoObject));
            });
        }
        this.partnerTitle = 'партнерские сервисы';
    }
}
