import { action, makeObservable, observable } from 'mobx';
import  {Api} from '@/src/api/Api';
import clientApi from "@/src/helpers/ApiHelper";

export class ApiStore {
    root;

    @observable /** @type {Api} */ api;

    constructor(root) {
        this.root = root;
        this.api = new Api('');
        makeObservable(this);
    }

    @action
    hydrate(data) {
        if (data) {
            this.api.setToken(data.token);
            this.api.setEndpoint(data.endpoint);
            if (typeof window !== 'undefined') {
                clientApi.setToken(data.token);
                clientApi.setEndpoint(data.endpoint);
            }
        }
    }
}