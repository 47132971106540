import styles from './NavAccordion.module.scss';
import Icon from '@/components/icon/Icon';
import AnalyticLink from '@/components/header/AnalyticLink';
import MenuGroup from '@/components/header/menu-group/MenuGroup';
import { useState } from 'react';
import BannerTv from '@/components/banner-tv/BannerTv';
import { LANDING_URL } from '@/src/constants/global';

export default function NavAccordion({ items, modifier }) {
    const [activeMenuItemIndex, setActiveMenuItemIndex] = useState(-1);

    return (
        <div className={styles['nav-accordion'] + (modifier ? ' ' + modifier : '')}>
            {items.map((item, index) => (
                <div className="nav-accordion__item" key={index /* NOSONAR */}>
                    {item.submenu ? (
                        <button
                            className={
                                'nav-accordion__action' +
                                (index === activeMenuItemIndex ? ' nav-accordion__action--active' : '')
                            }
                            type="button"
                            onClick={() => {
                                setActiveMenuItemIndex(index === activeMenuItemIndex ? -1 : index);
                            }}
                        >
                            <Icon id={'arrow-down'} modifier={'nav-accordion__icon'} />
                        </button>
                    ) : (
                        ''
                    )}
                    <AnalyticLink className="nav-accordion__link" href={item.url}>
                        <div className="nav-accordion__title">{item.name}</div>
                    </AnalyticLink>
                    <div className="nav-accordion__content">
                        {item.submenu?.submenu && item.submenu.submenu.length > 0 && (
                            <div className="nav-accordion__submenu">
                                {item.submenu.submenu.map(
                                    (item, index) =>
                                        item && (
                                            <MenuGroup
                                                title={item.title}
                                                url={item.url}
                                                group={item.list}
                                                modifier={'nav-accordion__group'}
                                                key={index} // NOSONAR
                                            />
                                        )
                                )}
                            </div>
                        )}
                        {item.submenu?.cards && (
                            <div className="nav-accordion__card">
                                <MenuGroup
                                    title={item.submenu.cards.title}
                                    url={item.submenu.cards.url}
                                    group={item.submenu.cards.list}
                                    modifier={'menu-group--slider'}
                                    grid={true}
                                />
                            </div>
                        )}
                        {item.submenu?.banner && (
                            <div className="nav-accordion__banner">
                                <BannerTv
                                    modifier={'banner-tv--small'}
                                    title={'Приставка Movix превратит ТВ в мощный Smart TV'}
                                    description={<>Кино, сериалы, видеоблоги, ТВ-каналы, игры и&nbsp;приложения</>}
                                    infoButton={{
                                        title: 'Подробнее',
                                        href: LANDING_URL,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}
