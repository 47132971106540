import Cookies from 'js-cookie';
import { COOKIES } from '@/src/constants/cookies';
import { getInitialStoreState as getInitialAuthStoreState } from '@/src/stores/AuthStore';
import { Api } from '@/src/api/Api';

const clientApi = new Api(Cookies.get(COOKIES.API_TOKEN));

export async function getApi(context) {
    // Для SSR нужно заполнить token в API
    if (typeof window === 'undefined') {
        const { token, endpoint } = await getInitialAuthStoreState(context);
        return new Api(token, endpoint);
    }
    return clientApi;
}

export default clientApi;
