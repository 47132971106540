import { nanoid } from 'nanoid';

export const TYPE_SUCCESS = 'success';
export const TYPE_ERROR = 'error';
export const TYPE_FAVORITE = 'favorite';
export const TYPE_SMOKE = 'smoke';

const SHOW_TIMEOUT_MILLISECONDS = 4000;

export default function Notification() {
    this.body = {
        id: nanoid(),
        type: TYPE_SUCCESS,
        title: '',
        message: '',
        subMessage: '',
        style: {},
        showTimeout: SHOW_TIMEOUT_MILLISECONDS,
        closeButton: true,
    };
    this.remove = false;
    this.reply = false;

    this.setTitle = (title) => {
        this.body.title = title;
        return this;
    };
    this.setMessage = (message) => {
        this.body.message = message;
        return this;
    };
    this.setSubMessage = (subMessage) => {
        this.body.subMessage = subMessage;
        return this;
    };
    this.success = () => {
        this.body.type = TYPE_SUCCESS;
        return this;
    };
    this.smoke = () => {
        this.body = {
            id: nanoid(),
            type: TYPE_SMOKE,
            showTimeout: 3000,
            title: 'Контент может содержать сцены потребления табака.\nКурение вредит вашему здоровью.',
            message: '',
            closeButton: false,
        };
        return this;
    };
    this.error = () => {
        this.body.message = 'Ошибка';
        this.body.type = TYPE_ERROR;
        return this;
    };
    this.showAfterReload = () => {
        this.reply = true;
    };

    return this;
}
