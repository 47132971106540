import ApiException from '@/src/api/ApiException';
import ApiError from '@/src/api/ApiError';

export default class ApiBackendException extends ApiException {
    /** @type {string} */
    name = 'ApiBackendException';
    /** @type {ApiError} */
    apiError;
    /** @type {string} */
    code;
    /** @type {number} status */
    status = 0;

    /**
     * ApiException constructor.
     * @param {string} message
     * @param {string} code
     * @param {Object|null} error
     */
    constructor(message = '', code = '', error = null) {
        super(message);
        this.code = code;
        this.apiError = error ? new ApiError(error) : null;
    }
}
