import styles from './Footer.module.scss';
import Social from './social/Social';
import { useApi } from '@/src/hooks/useApi';
import Button from '@/components/button/Button';
import { replaceAll } from '@/src/helpers/PolyfillHelper';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import { observer } from 'mobx-react';
import { useInView } from 'react-intersection-observer';
import { emptyBackground } from '@/src/modules/Common';
import { useRouter } from 'next/router';
import { LANDING_URL } from '@/src/constants/global';

function Footer() {
    const { api } = useApiStore();
    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    const router = useRouter();

    const supportPhone = /** @type string */ useApi(api.generalInformation.getSupportPhone, inView);
    return (
        <footer className={styles['footer']}>
            <div className="footer__container container container--wide">
                <div className="footer__social">
                    <Social />
                    <div className="footer__info">
                        <a
                            className="footer__link"
                            href="https://www.ertelecom.ru"
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        >
                            АО «ЭР-Телеком Холдинг»
                        </a>
                    </div>
                    {router.asPath.includes('/game') && (
                        <div className="footer__info" style={{ marginTop: 0 }}>
                            <a className="footer__link" href="/game_rules" target="_blank" rel="nofollow noreferrer">
                                Правила конкурса
                            </a>
                        </div>
                    )}
                    <div className="footer__info" style={{ marginTop: 0 }}>
                        <a className="footer__link" href="/offer" target="_blank" rel="nofollow noreferrer">
                            Условия оферты
                        </a>
                        <a className="footer__link" type={'link'} href={'mailto:help@domru.ru'}>
                            help@domru.ru
                        </a>
                    </div>
                    <div className="footer__info" style={{ marginTop: 0 }}>
                        <a className="footer__link" href="/personal_data_policy" target="_blank">
                            Политика об обработке персональных данных
                        </a>
                    </div>
                    <div className="footer__info" style={{ marginTop: 0 }}>
                        <a className="footer__link" href="/recommendation_rules" target="_blank">
                            На Movix применяются рекомендательные технологии
                        </a>
                    </div>
                    <div className="footer__info" style={{ marginTop: 0 }}>
                        <span suppressHydrationWarning={true}>2011—{new Date().getFullYear()}, 18+</span>
                    </div>
                </div>
                <div className="footer__app" ref={ref}>
                    <div className="footer__app-item">
                        <Button
                            type={'link'}
                            image={inView ? '/assets/img/icon-appstore.svg' : emptyBackground}
                            imageProps={{ width: 109, height: 30, alt: 'AppStore' }}
                            className={'button--rect button--secondary button--simple footer__app-link button--app'}
                            href={'https://itunes.apple.com/ru/app/dom.ru-tv/id825552263?mt=8&uo=4'}
                            aria-label={'Доступно в AppStore'}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        />
                    </div>
                    <div className="footer__app-item">
                        <Button
                            type={'link'}
                            image={inView ? '/assets/img/icon-googleplay.svg' : emptyBackground}
                            imageProps={{ width: 116, height: 30, alt: 'Google Play' }}
                            className={'button--rect button--secondary button--simple footer__app-link button--app'}
                            href={
                                'https://play.google.com/store/apps/details?id=com.ertelecom.domrutv&referrer=utm_source%3DWebsite%26utm_medium%3DMovixru%26utm_term%3DMovixru'
                            }
                            aria-label={'Загрузить на Google Play'}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        />
                    </div>
                    <div className="footer__app-item">
                        <Button
                            type={'link'}
                            image={inView ? '/assets/img/icon-smarttv.svg' : emptyBackground}
                            imageProps={{ width: 112, height: 26, alt: 'SmartTV' }}
                            className={'button--rect button--secondary button--simple footer__app-link button--app'}
                            href={'https://domru.ru/smart-tv'}
                            aria-label={'Доступно на SmartTV'}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        />
                    </div>
                    <div className="footer__app-item">
                        <Button
                            type={'link'}
                            image={inView ? '/assets/img/icon-androidtv.svg' : emptyBackground}
                            imageProps={{ width: 128, height: 24, alt: 'Android TV' }}
                            className={'button--rect button--secondary button--simple footer__app-link button--app'}
                            href={'https://play.google.com/store/apps/details?id=com.ertelecom.domrutvstb'}
                            aria-label={'Доступно на Android TV'}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        />
                    </div>
                    <div className="footer__app-item">
                        <Button
                            type={'link'}
                            image={inView ? '/assets/img/icon-appmovix.svg' : emptyBackground}
                            imageProps={{ width: 97, height: 26, alt: 'Movix' }}
                            className={'button--rect button--secondary button--simple footer__app-link button--app'}
                            href={LANDING_URL}
                            aria-label={'На приставке Movix'}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        />
                    </div>
                    <div className="footer__app-item">
                        <Button
                            type={'link'}
                            image={inView ? '/assets/img/icon-appgallery.svg' : emptyBackground}
                            imageProps={{ width: 121, height: 30, alt: 'AppGallery' }}
                            className={'button--rect button--secondary button--simple footer__app-link button--app'}
                            href={'https://appgallery8.huawei.com/#/app/C101892809'}
                            aria-label={'Откройте в AppGallery'}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        />
                    </div>
                    <div className="footer__app-item">
                        <Button
                            type={'link'}
                            image={inView ? '/assets/img/icon-rustore.svg' : emptyBackground}
                            imageProps={{ width: 103, height: 29, alt: 'RuStore' }}
                            className={'button--rect button--secondary button--simple footer__app-link button--app'}
                            href={'https://apps.rustore.ru/app/com.ertelecom.domrutv'}
                            aria-label={'Откройте в RuStore'}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        />
                    </div>
                    <div className="footer__app-item">
                        <Button
                            type={'link'}
                            image={inView ? '/assets/img/icon-nashstore.svg' : emptyBackground}
                            imageProps={{ width: 126, height: 19, alt: 'NASHSTORE' }}
                            className={'button--rect button--secondary button--simple footer__app-link button--app'}
                            href={'https://store.nashstore.ru/store/6283e4be4891a536bcaad3b0'}
                            aria-label={'Откройте в NASHSTORE'}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        />
                    </div>
                </div>
                <div className="footer__contact">
                    <a className="footer__link footer__phone" href={`tel:${supportPhone}`}>
                        {replaceAll(supportPhone, '-', ' ')}
                    </a>
                    <div className="footer__help">
                        <Button
                            title={'Задать вопрос'}
                            type={'link'}
                            className={'button--secondary button--content button--x-small'}
                            href={'/feedback'}
                        />
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default observer(Footer);
