import md5 from 'md5';
import { nanoid } from 'nanoid';
import { AdultState, AvailableState } from '@/src/constants/types';
import Resources from '@/src/api/v3/models/mixins/Resources';
import { slug } from '@/src/modules/Common';
import { PinReason } from '@/src/constants/pin-reason';
import { URN_TO_URL_ALIASES } from '@/src/constants/aliases.config';

/**
 *  Базовый класс для API-v3 элементов
 */

export const Images = {
    BACKGROUND_WEB_BLUEPRINT: 'background_web_blueprint',
    SMARTTV_ASSET_BACKGROUND_VIDEO_LIBRARY_BLUEPRINT: '3_smarttv_asset_background_video_library_blueprint',
    SMARTTV_PACKAGE_BACKGROUND_VIDEO_LIBRARY_BLUEPRINT: '3_smarttv_package_background_video_library_blueprint',
    SMARTTV_PERSON_POSTER_VIDEO_LIBRARY_BLUEPRINT: '3_smarttv_person_poster_video_library_blueprint',
};

export default class BaseModel {
    CARD_IMAGE_SIZE = {
        width: 380,
        height: 222,
    };
    SLIDER_STUB_CLASS = '';
    URL_PREFIX = '';
    EVENT_ITEM_CATEGORY = '';

    /** ID
     * @type {Number} */
    id;
    /** Тип
     * @type {string} */
    type;
    /** URN
     * @type {string} */
    urn;
    /** Название
     *  @type {string} */
    title;
    /**
     * Куплен ли элемент
     * @type {string} */
    available;
    /** Название типа
     *  @type {string} */
    typeName = '';
    /** Для взрослых
     *  @type {string} */
    adult;
    /** Возрастной рейтинг
     *  @type {?number} */
    age;
    /** Параметр витрины для системы рекомендаций
     *  @type {string} */
    recommendationId;
    /** Уникальный ID элемента
     *  @type {string} */
    uniqueId;
    /** Модификатор для фона
     * @type {string}  */
    backgroundModifier = '';
    /** @type {?Children} Дочерний элемент */
    children;
    /** Название для заголовка страницы
     *  @type {string} */
    pageTitle;
    /** Ссылка для просмотра партнерского контента
     *  @type {string} */
    shareLink;
    /** Название партнерского сервиса
     *  @type {string} */
    partnerTitle;

    images = [
        Images.SMARTTV_ASSET_BACKGROUND_VIDEO_LIBRARY_BLUEPRINT,
        Images.BACKGROUND_WEB_BLUEPRINT,
        'subscription_blueprint',
        Images.SMARTTV_PACKAGE_BACKGROUND_VIDEO_LIBRARY_BLUEPRINT,
        '3_smarttv_asset_poster_asset_card_blueprint',
        'poster_blueprint',
        Images.SMARTTV_PERSON_POSTER_VIDEO_LIBRARY_BLUEPRINT,
    ];

    /**
     * Создать модель из объекта
     * @param {Object} object
     */
    constructor(object) {
        if (object instanceof BaseModel) {
            this.id = object.id;
            this.type = object.type;
            this.urn = object.urn;
            this.title = object.title;
            this.shareLink = object.shareLink;
            this.available = object.available;
            this.adult = object.adult;
            this.age = object.age;
            this.recommendationId = object.recommendationId;
            this.uniqueId = object.uniqueId;
            this.resources = object.resources;
            this.partnerTitle = object.partnerTitle;
        } else {
            this.id = object.id;
            this.type = object.type;
            this.urn = object.urn;
            this.title = object.title;
            this.partnerTitle = object.title;
            this.shareLink = object.shareLink;
            this.available = object.available?.type;
            this.adult = object.adult?.type;
            /** @namespace object.rating **/
            this.age = object.rating?.age;
            if (!this.age) {
                this.age = object.rating?.type?.substring(1);
            }
            this.recommendationId = object.recommendationId;
            this.uniqueId = nanoid();
            this.resources = new Resources();
            this.resources.loadResources(object);
        }
    }

    /**
     * Возвращает хеш элемента
     * @returns string
     */
    getHash() {
        return md5(this.type + this.id + this.urn + this.title + this.resources.getResourcesHash());
    }

    /**
     * Получение URL элемента
     * @returns string
     */
    getUrl() {
        const alias = URN_TO_URL_ALIASES[this.urn];
        if (alias) {
            return `/${alias}`;
        } else if (this.URL_PREFIX) {
            return `/${this.URL_PREFIX}/${this.title ? slug(this.title) + '-' : ''}${this.id}`;
        } else {
            return `/${this.urn}`;
        }
    }

    /**
     * Доступен ли элемент
     * @returns boolean
     */
    isAvailable() {
        return [
            AvailableState.AVAILABLE,
            AvailableState.CANCELING_PURCHASE,
            AvailableState.FREE,
            AvailableState.TRIAL,
            AvailableState.SUSPEND_BY_USER,
            AvailableState.GIFT,
        ].includes(this.available);
    }

    /**
     * Элемент в процессе оплаты
     * @returns boolean
     */
    inPurchase() {
        return [AvailableState.IN_PURCHASE, AvailableState.PENDING_BOUGHT].includes(this.available);
    }

    /**
     * Получение изображения
     * @returns string
     */
    getImageSrc(options = null) {
        if (options === null) {
            options = { size: this.CARD_IMAGE_SIZE };
        }
        return this.resources.getResourceUrl(this.images, options);
    }

    /**
     * Получить изображения для фона
     * @returns null|string
     */
    getBackgroundSrc() {
        return this.resources.getResourceUrl([
            Images.SMARTTV_ASSET_BACKGROUND_VIDEO_LIBRARY_BLUEPRINT,
            Images.SMARTTV_PACKAGE_BACKGROUND_VIDEO_LIBRARY_BLUEPRINT,
            Images.BACKGROUND_WEB_BLUEPRINT,
        ]);
    }

    /**
     * Получить изображения для слайдера
     * @returns null|string
     * @param options
     */
    getSliderSrc(options = null) {
        return this.resources.getResourceUrl(
            [
                'web_desktop_slider_image',
                'video_library_slider_image',
                '3_smarttv_asset_poster_banner_showcase_blueprint',
                Images.SMARTTV_ASSET_BACKGROUND_VIDEO_LIBRARY_BLUEPRINT,
                Images.SMARTTV_PACKAGE_BACKGROUND_VIDEO_LIBRARY_BLUEPRINT,
                Images.BACKGROUND_WEB_BLUEPRINT,
                '3_smarttv_asset_poster_asset_card_blueprint',
                'poster_blueprint',
            ],
            options
        );
    }

    /**
     * Получить изображения для слайдера
     * @returns null|string
     * @param options
     */
    getSliderMobileSrc(options = null) {
        return this.resources.getResourceUrl(
            [
                '3_smarttv_asset_poster_banner_showcase_blueprint',
                'video_library_slider_image',
                Images.SMARTTV_ASSET_BACKGROUND_VIDEO_LIBRARY_BLUEPRINT,
                Images.SMARTTV_PACKAGE_BACKGROUND_VIDEO_LIBRARY_BLUEPRINT,
                Images.BACKGROUND_WEB_BLUEPRINT,
                '3_smarttv_asset_poster_asset_card_blueprint',
                'poster_blueprint',
            ],
            options
        );
    }

    /**
     * Получить изображения для фона подключения T&B
     * @returns null|string
     */
    getTNBBackgroundSrc(options = null) {
        if (options === null) {
            options = { size: { width: '605', height: '284' } };
        }
        return this.resources.getResourceUrl('3_smarttv_package_background_video_library_blueprint', options);
    }

    /**
     * Получение изображения для саджеста
     * @returns null|string
     */
    getSuggestSrc() {
        return null;
    }

    /**
     * Для взрослых
     * @returns boolean
     */
    isAdult() {
        return this.adult === AdultState.ADULT;
    }

    /**
     * Проверяет нужен ли ПИН для показа карточки модели
     * @param {?PinStatus} pinStatus
     * @param {Boolean} [isTvAdultSession]
     * @param {Boolean} [isTvSession]
     * @param {Boolean} [isAdultSession]
     * @returns ?string
     */
    getPinReason(pinStatus, isTvAdultSession, isTvSession, isAdultSession) {
        if (pinStatus) {
            if (this.isAdult() && !isAdultSession) {
                return PinReason.ADULT;
            }
        } else if (this.isAdult()) {
            return PinReason.ADULT;
        }
        return null;
    }

    /**
     * Проверяет нужен ли ПИН для просмотра контента модели
     * @param {?PinStatus} pinStatus
     * @param {Boolean} isPinExpired
     * @returns ?string
     */
    getViewPinReason(pinStatus, isPinExpired) {
        if (pinStatus) {
            const isAdultReason = () => {
                return this.isAdult();
            };
            const isInappropriateContentReason = () => {
                return (
                    (pinStatus.isUnstated() && this.age >= 18) ||
                    (pinStatus.isEnabled() && pinStatus.inappropriateContentEnabled && this.age >= 18 && isPinExpired)
                );
            };
            const isParentalControlReason = () => {
                return (
                    pinStatus.isEnabled() &&
                    pinStatus.parentalControlEnabled &&
                    this.age !== undefined &&
                    this.age >= pinStatus.level
                );
            };
            switch (true) {
                case isAdultReason():
                    return PinReason.ADULT;
                case isInappropriateContentReason():
                    return PinReason.INAPPROPRIATE_CONTENT;
                case isParentalControlReason():
                    return PinReason.PARENTAL_CONTROL;
            }
        } else {
            if (this.isAdult()) {
                return PinReason.ADULT;
            }
            if (this.age >= 18) {
                return PinReason.INAPPROPRIATE_CONTENT;
            }
        }
        return null;
    }
}
