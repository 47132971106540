export default class MultiscreenStatus {
    /** Maximum number of devices available for linking.
     * @type {Number} */
    slotCount;
    /** Number of opened multiscreen sessions.
     * @type {Number} */
    openedOttwebSessionCount;
    /** Maximum number of devices that may be connected.
     * @type {Number} */
    maxSlotCount;
    /** State of multiscreen service: 0 - inactive, 1 - active, 2 - blocked.
     * @type {Number} */
    state;
    /** Flag shows whether the device specified in the token is linked to the subscriber.
     * @type {Boolean} */
    isBound;

    /**
     * MultiscreenStatus constructor.
     * @param {Object} object
     */
    constructor(object) {
        this.slotCount = object['slot_count'];
        this.openedOttwebSessionCount = object['opened_ottweb_session_count'];
        this.maxSlotCount = object['max_slot_count'];
        this.state = object['state'];
        this.isBound = object['is_bound'];
    }
}
