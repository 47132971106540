export default class ApiError {
    /** Заголовок ошибки для отображения пользователю
     * @type {string}  */
    message;
    /** @type {string}
     *  Описание на русском ошибки для отображения пользователю */
    messageRus;
    /** @type {string}
     *  Заголовок ошибки для отображения пользователю */
    headerUI;
    /** @type {string}
     *  Код ошибки для отображения пользователю */
    codeUI;
    /** @type {string}
     *  Тег ошибки */
    tag;
    /** @type {string} */
    reason;

    /**
     * BackendError constructor.
     * @param {Object} object
     */
    constructor(object) {
        this.headerUI = object.header_ui;
        this.message = object.message;
        this.messageRus = object.message_rus;
        this.tag = object.tag;
        this.codeUI = object.code_ui;
        this.reason = object.reason;
    }
}
