import Stock from '@/src/api/v3/models/Stock';

/**
 * Class CatchupStatus
 */
export default class CatchupStatus {
    /** is Catchup Active.
     *  @type {boolean} */
    isActive;
    /** ID
    1000794 - GooglePlayДом.ру TV,
    1000795 - AppStore Дом.ру TV
    0 - лицевой счёт Биллинга
        @type {number} */
    partnerId;
    /** Date of service activation
     *  @type {?Date} */
    dateFrom;
    /** Date when service will be disabled
     *  @type {?Date} */
    dateTo;
    /** Original price excluding discounts
     *  @type {number} */
    price;
    /** Current value including promotion regardless of the activity of the service on the contract
     *  @type {number} */
    currentPrice;
    /** Promotion Duration
     *  @type {number}  */
    duration;
    /** Type duration:
    1 - number of months
    2 - until full use of the amount
    3 - until date
    4 - until the end of the calendar month
    5 - number of days
        @type {number} */
    durationTypeId;
    /** Promotion date
     *  @type {number} */
    actDateFrom;
    /** End date promotion (dates older than 10 years will be blank)
     *  @type {number} */
    actDateTo;
    /** By which active promotion current_price is calculated (1 - current promotion, 0 - other active promotion)
     *  @type {number} */
    applyInCurrentPrice;
    /** Акция
     * @type {?Stock} */
    stock;
    /** is Active In Bundle.
     *  @type {boolean} */
    isActiveInBundle;
    /** @type {Object} */
    availableMethods = {};

    /**
     * CatchupStatus constructor.
     * @param {Object} object
     * @param {number} object.state
     * @param {number} object.partner_id
     * @param {number} object.date_from
     * @param {number} object.date_to
     * @param {number} object.price
     * @param {number} object.current_price
     * @param {number} object.duration
     * @param {number} object.dur_type_id
     * @param {number} object.act_date_from
     * @param {number} object.act_date_to
     * @param {number} object.apply_in_current_price
     * @param {Object} object.stocks
     */
    constructor(object) {
        this.isActive = object.state === 1;
        this.partnerId = object.partner_id;
        this.dateFrom = object.date_from ? new Date(1000 * object.date_from) : null;
        this.dateTo = object.date_to ? new Date(1000 * object.date_to) : null;
        this.price = object.price;
        this.currentPrice = object.current_price;
        this.duration = object.duration;
        this.durationTypeId = object.dur_type_id;
        this.actDateFrom = object.act_date_from;
        this.actDateTo = object.act_date_to;
        this.applyInCurrentPrice = object.apply_in_current_price;
        if (object.stocks?.length > 0) {
            this.stock = new Stock(object.stocks[0]);
        }
        (object['available_methods'] || []).forEach((method) => {
            this.availableMethods[method['type']] = method['name'];
        });
        this.isActiveInBundle = object['active_in_bundle'] === 1 && !this.availableMethods['standalone'];
    }

    /**
     * Получить описание цены
     * @returns {string}
     */
    getActivatedInfo() {
        if (this.isActiveInBundle) {
            return `В подписке ${this.availableMethods['bundle']}`;
        }
        if (this.stock?.isTNB()) {
            return 'Пробный период';
        }
        if (this.currentPrice === 0) {
            return 'Доступно';
        }
        if (!this.currentPrice) {
            return '';
        }
        return `Подключено за ${this.currentPrice} ₽ в месяц`;
    }
}
