import { nanoid } from 'nanoid';

export default class ApiException extends Error {
    /** @type {string} */
    name = 'ApiException';
    /** @type {string} */
    code;
    /** @type {string} */
    uniqueId;
    /** @type {number} */
    status = 3;

    /**
     * ApiException constructor.
     * @param {string} message
     * @param {string} code
     */
    constructor(message = '', code = '') {
        super(message);
        this.code = code;
        this.uniqueId = nanoid();
    }
}
