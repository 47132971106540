import styles from './MenuDropdown.module.scss';
import MenuGroup from '@/components/header/menu-group/MenuGroup';
import BannerTv from '@/components/banner-tv/BannerTv';
import { LANDING_URL } from '@/src/constants/global';

export default function MenuDropdown({ submenu, modifier, isActive }) {
    return (
        <div
            className={
                styles['menu-dropdown'] + (modifier ? ' ' + modifier : '') + (isActive ? ' menu-dropdown--visible' : '')
            }
        >
            {submenu.submenu &&
                submenu.submenu.length > 0 &&
                submenu.submenu.map(
                    (item, index) =>
                        item && (
                            <div
                                className="menu-dropdown__submenu"
                                key={index} // NOSONAR
                            >
                                <MenuGroup
                                    title={item.title}
                                    url={item.url}
                                    group={item.list}
                                    modifier={'menu-dropdown__group'}
                                />
                            </div>
                        )
                )}
            {submenu.cards && (
                <div className="menu-dropdown__card">
                    <MenuGroup
                        title={submenu.cards.title}
                        url={submenu.cards.url}
                        group={submenu.cards.list}
                        modifier={submenu.cards.modifier}
                        grid={true}
                    />
                </div>
            )}
            {submenu.banner && (
                <div className="menu-dropdown__banner">
                    <BannerTv
                        modifier="banner-tv--small"
                        title={'Приставка Movix превратит ТВ в мощный Smart TV'}
                        description={<>Кино, сериалы, видеоблоги, ТВ-каналы, игры и&nbsp;приложения</>}
                        infoButton={{
                            title: 'Подробнее',
                            href: LANDING_URL,
                        }}
                    />
                </div>
            )}
        </div>
    );
}
