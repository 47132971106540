import { YANDEX_METRIKA_ID } from '@/src/constants/statistics';

/**
 * @param {...*} args
 */
const log = (...args) => {
    if (typeof window !== 'undefined' && typeof window.ym === 'function') {
        window.ym(YANDEX_METRIKA_ID, ...args);
    }
};

/**
 * Метод для передачи просмотра страницы для сайтов с клиентским роутингом
 * @see {https://yandex.ru/support/metrica/objects/hit.html}
 * @param {string} url
 */
export const hit = (url = window.location.href) => log('hit', url);
