import { ApiMixin } from '@/src/api/ApiMixin';

export class Undocumented extends ApiMixin {
    /**
     * Получение поля списка каналов (id, sid, lcn, time_expired, url)
     * @returns {Promise<Array>}
     * @throws {ApiBackendException}
     */
    getChannelListUrl = async () => {
        let response = await this.api.v2({
            uri: '/er/billing/channel_list/url',
        });
        return response['collection'];
    };
}
