import atob from 'atob';

export default class ApiToken {
    /** @type string */
    encodedToken;

    /** @type Object */
    decodedToken;

    /** @type Object */
    principal;

    /**
     * ApiToken constructor.
     * @param token string
     */
    constructor(token) {
        this.encodedToken = token;
        this.decode();
    }

    decode() {
        let json = atob(this.encodedToken);
        let data = JSON.parse(json);
        this.decodedToken = JSON.parse(data.data);
        this.principal = this.decodedToken.principal;
    }

    /**
     * Проверка гостевой ли токен
     * @returns boolean
     */
    isGuest() {
        const { is_guest } = this.principal?.subscriber || {};
        return typeof is_guest === 'boolean' ? is_guest : true;
    }

    /**
     * Проверка является ли токен отт
     * @returns boolean
     */
    isOTT() {
        return this.getRegionId() === 'ott';
    }

    /**
     * Проверка является ли токен МЕ
     * @returns boolean
     */
    isME() {
        return !this.isGuest() && !this.isOTT();
    }

    /**
     * Получить номер договора
     * @returns null|string
     */
    getContractNumber() {
        const extidParts = this.getExtId().split(':');
        return extidParts[1] || null;
    }

    /**
     * Получить extid
     * @returns string
     */
    getExtId() {
        const { extid } = this.principal?.subscriber || {};
        return typeof extid === 'string' ? extid : '';
    }

    /**
     * Получить регион
     * @returns null|string
     */
    getRegionId() {
        const [region] = this.getExtId().split(':');
        return region || null;
    }

    /**
     * @returns null|string
     */
    getSubscriberId() {
        return this.principal.subscriber.id;
    }

    /**
     * @returns null|string
     */
    getDeviceId() {
        return this.principal.id;
    }

    /**
     * Получить время истечения токена
     * @returns {Date}
     */
    getExpires() {
        return new Date(this.decodedToken.expires * 1000);
    }

    /**
     * Проверка гостевой ли токен
     *
     * @param token string
     * @returns boolean
     */
    static isGuest(token) {
        try {
            let decodedToken = new this(token);
            return decodedToken.isGuest();
        } catch (error) {
            return true;
        }
    }
}
