// noinspection JSUnusedGlobalSymbols

import { QUERY_POST } from '@/src/api/Api';
import BonusBalance from '@/src/api/v2/models/BonusBalance';
import BillingDevice from '@/src/api/v2/models/BillingDevice';
import { ApiMixin } from '@/src/api/ApiMixin';

/**
 * Billing
 * @see https://kblion.ertelecom.ru/display/BA/Trans+10.+Billing
 */

export class Billing extends ApiMixin {
    /**
     * Возвращает баланс пользователя
     * @returns {Promise<Number>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getBalance = async () => {
        const response = await this.api.v2({
            uri: '/api/er/billing/balance',
        });
        return response['balance'];
    };

    /**
     * Возвращает бонусный баланс пользователя
     * @returns {Promise<BonusBalance>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getBonusBalance = async () => {
        const response = await this.api.v2({
            uri: '/api/er/billing/bonus_balance',
        });
        /** @namespace response.bonus_balance **/
        return new BonusBalance(response.bonus_balance);
    };

    /**
     * Receiving the list of devices (terminals) for packages connection
     * @returns {Promise<BillingDevice[]>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getBillingDevices = async () => {
        let response = await this.api.v2({
            uri: '/er/billing/devices',
        });
        let devices = response['devices'] || [];
        return devices.map((device) => new BillingDevice(device));
    };

    /**
     * Activate channel package
     * @param {number} packageId
     * @param {?string} trName
     * @param {Object} params
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    activateChannelPackage = async (packageId, trName = null, params = {}) => {
        await this.api.v2({
            uri: '/er/billing/activate_channel_package',
            params: trName
                ? {
                      package_id: packageId,
                      tr_name: trName,
                      ...params,
                  }
                : {
                      package_id: packageId,
                      ...params,
                  },
            method: QUERY_POST,
        });
    };

    /**
     * Switch off the channel package
     * @param {number} packageId
     * @param {string} trName
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    deactivateChannelPackage = async (packageId, trName) => {
        await this.api.v2({
            uri: '/er/billing/deactivate_channel_package',
            params: {
                package_id: packageId,
                tr_name: trName,
            },
            method: QUERY_POST,
        });
    };

    /**
     * Initiate TVOD purchase, return Number identifier of the transaction.
     * @param {number} assetId
     * @param {number} offerId
     * @returns {Promise<number>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    initiatePurchase = async (assetId, offerId) => {
        const response = await this.api.v2({
            uri: '/er/billing/initiate_purchase',
            params: {
                asset_id: assetId,
                offer_id: offerId,
            },
        });
        return response['tx_id'];
    };

    /**
     * Confirm TVOD purchase
     * @param {number} txId
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    confirmPurchase = async (txId) => {
        await this.api.v2({
            uri: '/er/billing/acknowledge_purchase',
            params: {
                tx_id: txId,
                confirmation_code: '0000',
            },
        });
    };

    /**
     * Instant activation of TVOD / SVOD subscription
     * @param {number} assetId
     * @param {number} offerId
     * @param {Object} params
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    purchase = async (assetId, offerId, params = {}) => {
        await this.api.v2({
            uri: '/er/billing/purchase',
            params: {
                asset_id: assetId,
                offer_id: offerId,
                ...params,
            },
            method: QUERY_POST,
        });
    };

    /**
     * Instant cancel/deactivation of SVOD subscription
     * @param {number} assetId
     * @param {number} offerId
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    cancelSVOD = async (assetId, offerId) => {
        await this.api.v2({
            uri: '/er/billing/cancel_purchase_svod',
            params: {
                svod_id: assetId,
                offer_id: offerId,
            },
        });
    };

    /**
     * Bundle activation
     * @param {number} assetId
     * @param {number} offerId
     * @param {Object} params
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    bundleActivate = async (assetId, offerId, params = {}) => {
        await this.api.v2({
            uri: '/er/billing/bundle/activate',
            params: {
                asset_id: assetId,
                offer_id: offerId,
                ...params,
            },
            method: QUERY_POST,
        });
    };

    /**
     * Bundle deactivation
     * @param {number} assetId
     * @param {number} offerId
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    bundleDeactivate = async (assetId, offerId) => {
        await this.api.v2({
            uri: '/er/billing/bundle/deactivate',
            params: {
                asset_id: assetId,
                offer_id: offerId,
            },
            method: QUERY_POST,
        });
    };

    /**
     * Активация промокода
     * @param {string} promocode
     * @returns {Promise<string>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    activatePromocode = async (promocode) => {
        const response = await this.api.v2({
            uri: '/er/billing/promocode/activate',
            params: {
                promo_code: promocode,
            },
            method: QUERY_POST,
        });
        return response['text'];
    };

    /**
     * Проверка статуса подписки на стороне партнера по номеру телефона
     * @see https://kblion.ertelecom.ru/pages/viewpage.action?pageId=73935499
     * @param {number} subscriptionId - Task ID of partner SVOD subscriptions (ivi - 3102186, kinopoisk - 4139203, okko - 7954446)
     * @param {?string} partnerContactId - Id for client number phone
     * @param {?string = null} partnerPhone - Client number phone
     * @returns {void}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    partnerPhoneCheck = async (subscriptionId, partnerContactId, partnerPhone = null) => {
        await this.api.v2({
            uri: '/er/billing/partner/phone/check',
            params: {
                subscription_id: subscriptionId,
                ...(partnerPhone ? { partner_phone: partnerPhone } : {}),
                ...(partnerContactId ? { partner_contact_id: partnerContactId } : {}),
            },
        });
    };
}
