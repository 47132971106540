import Resource from '@/src/api/v3/models/Resource';
import md5 from 'md5';
import * as UrlHelper from '@/src/helpers/UrlHelper';
import { emptyBackground } from '@/src/modules/Common';

const availableStubs = [
    'stub_1564-367.png',
    'stub_1000-413.png',
    'stub_227-127.png',
    'stub_300-169.png',
    'stub_167-240.png',
    'stub_325-227.png',
    'stub_350-196.png',
    'stub_605-284.png',
    'stub_55-37.png',
    'stub_300-211.png',
    'stub_40-40.png',
    'stub_70-70.png',
    'stub_226-226.png',
    'stub_228-124.png',
    'stub_284-160.png',
    'stub_262-150.png',
    'stub_380-222.png',
];

/**
 * Получить имя заглушки по размеру
 */
function stubNameBySize(size) {
    return `stub_${size['width']}-${size['height']}.png`;
}

export default class Resources {
    resources = {};
    emptyBackground = emptyBackground;

    /**
     * Получить первый не пустой ресурс
     * @param {string|string[]} name
     */
    getResource(name) {
        const resourceNames = Array.isArray(name) ? name : [name];
        for (let resourceName of resourceNames) {
            const resource = this.resources[resourceName];
            if (resource) {
                return resource;
            }
        }
        return null;
    }

    loadResources(object) {
        if (object?.resources) {
            object.resources.forEach((resourceObject) => {
                const resource = new Resource(resourceObject);
                this.resources[resource.type] = resource;
            });
        }
    }

    /**
     * Получить URL ресурса
     */
    getResourceUrl(name, options = [], fallback = emptyBackground) {
        const resource = this.getResource(name);
        if (resource) {
            return UrlHelper.secureURL(resource.getUrl(options));
        }
        if (!fallback) {
            return null;
        }
        const sizeParams = options['size'] ?? null;
        const stub = sizeParams ? this.getStubBySize(sizeParams) : null;
        return stub || fallback;
    }

    /**
     * Получить ID для HLS
     */
    getHlsID() {
        const resource = this.resources['hls_mcast_local'] || this.resources['hls'];
        if (resource) {
            return resource.id;
        }
        return null;
    }

    /**
     * Получить ID для Трейлера
     */
    getTrailerID() {
        const resource = this.resources['trailer'];
        if (resource) {
            return resource.id;
        }
        return null;
    }

    /**
     * Получить URL заглушки по размеру
     */
    getStubBySize(size) {
        const name = stubNameBySize(size);
        return this.getStubUrl(name);
    }

    /**
     * Получить URL заглушки по имени
     */
    getStubUrl(name) {
        if (availableStubs.includes(name)) {
            return '/assets/img/' + name;
        }
        return null;
    }

    /**
     * Возвращает хеш ресурсов
     */
    getResourcesHash() {
        let hash = '';
        Object.values(this.resources).forEach((item) => {
            hash = md5(hash + item.getHash());
        });
        return hash;
    }
}
