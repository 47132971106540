import { useState } from 'react';
import styles from './Input.module.scss';
import ReactInputMask from 'react-input-mask';
import Icon from '@/components/icon/Icon';

const INPUT_TYPE_TEXT = 'text';
const INPUT_TYPE_EMAIL = 'email';
const INPUT_TYPE_PASSWORD = 'password';

// TODO: Убрать inputRef или value

export default function Input({
    name,
    title,
    value,
    type = INPUT_TYPE_TEXT,
    error,
    mask,
    className,
    onRestorePassword,
    inputRef,
    icon,
    inputClassName = 'input__text--full',
    wrapperDiv = true,
    showPasswordButton = true,
    ...props
}) {
    const [showText, setShowText] = useState([INPUT_TYPE_TEXT, INPUT_TYPE_EMAIL].includes(type));
    const [isFocused, setIsFocused] = useState(false);

    const isActive = value || isFocused || inputRef?.current?.value;

    // noinspection JSUnusedGlobalSymbols
    const inputProps = {
        name,
        value,
        required: true,
        autoComplete: 'off',
        placeholder: isActive ? '' : title,
        className: 'input__text' + (isActive ? ' ' + inputClassName : '') + (error ? ' input__text--error' : ''),
        ...props,
        onChange: (event) => (props.onChange ? props.onChange(event.target.value) : null),
        onFocus: () => setIsFocused(true),
        onBlur: () => setIsFocused(false),
    };

    const wrapperCss = styles['input'] + (className ? ' ' + className : '');
    return (
        <Wrapper divClass={wrapperDiv ? wrapperCss : null}>
            <Wrapper divClass={type === INPUT_TYPE_PASSWORD && showPasswordButton ? 'input__wrapper' : null}>
                <div className="lb_wrap" style={{ display: 'inline' }}>
                    <div className="input__pad">
                        {mask ? (
                            <ReactInputMask mask={mask} {...inputProps} maskChar={null}>
                                {(inputProps) => <input ref={inputRef} {...inputProps} />}
                            </ReactInputMask>
                        ) : (
                            <input
                                ref={inputRef}
                                {...inputProps}
                                type={showText ? INPUT_TYPE_TEXT : INPUT_TYPE_PASSWORD}
                            />
                        )}
                        {isActive ? (
                            <div
                                className="lb_label active"
                                style={{
                                    opacity: '0.6',
                                    left: '10px',
                                    right: 'auto',
                                    fontSize: '12px',
                                    lineHeight: 1,
                                    color: 'rgb(255, 255, 255)',
                                    position: 'absolute',
                                    top: '6px',
                                    transition: 'all 500ms cubic-bezier(0.175, 0.885, 0.42, 1.31) 0s',
                                }}
                            >
                                {title}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                {type === INPUT_TYPE_PASSWORD && showPasswordButton ? (
                    <button
                        className={(showText ? '' : 'input__show-pass-btn--active ') + 'input__show-pass-btn'}
                        type="button"
                        onClick={() => setShowText(!showText)}
                    >
                        <Icon id={'view'} modifier={'input__show-pass-btn-icon'} />
                        <span className="h-hidden--text" title="Показать пароль">
                            Показать пароль
                        </span>
                    </button>
                ) : (
                    ''
                )}
            </Wrapper>
            {error?.length ? <p className="input__helptext input__helptext--warning">{error}</p> : ''}
            {onRestorePassword ? (
                <button className="input__restore" onClick={onRestorePassword} type="button">
                    Не помню
                </button>
            ) : (
                ''
            )}
            <label className="input__label input__label--hidden" htmlFor={name}>
                {title}
            </label>
        </Wrapper>
    );
}

function Wrapper({ children, divClass }) {
    return divClass ? <div className={divClass}>{children}</div> : children;
}

export function InputPassword(props) {
    return <Input {...props} type={INPUT_TYPE_PASSWORD} />;
}

export function InputModal(props) {
    return <Input {...props} className={'input--modal'} />;
}
