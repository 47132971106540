export default class ParentalControlLevel {
    /** @type {string}  */
    title;
    /** @type {number}  */
    extId;

    /**
     * Region constructor.
     * @param {Object} object
     */
    constructor(object) {
        this.title = object.title;
        this.extId = object.extid;
    }
}
