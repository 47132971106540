import { useState, useEffect } from 'react';
import { getChannelPackages } from '@/src/helpers/DataHelper';
import { useApiStore } from '@/src/stores/RootStoreProvider';

export default function useChannelPackages(isPurchasedFilter = false, isNotAdultFilter = false, fetch = true) {
    const { api } = useApiStore();
    const [channelPackages, setChannelPackages] = useState(/** @type {ChannelPackage[]} */ undefined);

    useEffect(() => {
        if (!api) {
            return;
        }
        if (fetch) {
            async function fetchData() {
                try {
                    setChannelPackages(await getChannelPackages(api, isPurchasedFilter));
                } catch (e) {
                    // Nothing
                }
            }

            // noinspection JSIgnoredPromiseFromCall
            fetchData();
        }
    }, [api, isPurchasedFilter, isNotAdultFilter, fetch]);

    return channelPackages;
}
