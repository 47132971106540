import { QUERY_POST } from '@/src/api/Api';
import WelcomeItem from '@/src/api/v3/models/WelcomeItem';
import { ApiMixin } from '@/src/api/ApiMixin';

/**
 * Trait WelcomeItems
 * @see https://kblion.ertelecom.ru/pages/viewpage.action?pageId=56875020#Onboarding(welcomescreen)-Onboardinglist
 */

export class WelcomeItems extends ApiMixin {
    /** Getting list of welcomeItems available to user (Получение списка welcome скринов доступных пользователю)
     * @returns {Promise<[]>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getWelcomeItems = async () => {
        const result = await this.api.v3({ uri: '/api/v3/welcome' });
        return (result.data?.items || []).map((item) => new WelcomeItem(item));
    };

    /**
     * Set viewed welcomeItems of user (Установка, что пользователь посмотрел welcome скрины)
     * @param {int} id
     * @param {int} count
     * @returns {Promise<void>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    setWelcomeViewed = async (id, count) => {
        await this.api.v3({
            uri: '/api/v3/welcome',
            params: {
                id: id,
                count: count,
            },
            method: QUERY_POST,
        });
    };
}
