// noinspection JSUnusedGlobalSymbols

import { QUERY_POST } from '@/src/api/Api';
import { ApiMixin } from '@/src/api/ApiMixin';
import Timezone from '@/src/api/v2/models/Timezone';
import Contact from '@/src/api/v2/models/Contact';

/**
 * GeneralInformation
 * @see https://kblion.ertelecom.ru/display/BA/Trans+13.+General+information
 */

export class GeneralInformation extends ApiMixin {
    /**
     * Set subscriber timezone
     * @param {number} timezoneId
     * @returns {void}
     */
    changeTimezone = async (timezoneId) => {
        await this.api.v2({
            uri: '/er/misc/subscriber/timezone/change',
            params: { timezone_id: timezoneId },
            method: QUERY_POST,
        });
    };

    /**
     * Возвращает временную зону
     * @returns {Promise<Timezone>}
     */
    getTimezone = async () => {
        const response = await this.api.v2({ uri: '/er/misc/subscriber/timezone' });
        /** @namespace response.timezone **/
        return new Timezone(response.timezone);
    };

    /**
     * Get the list of devices
     * @returns {Promise<Timezone[]>}
     */
    getTimezoneList = async () => {
        const response = await this.api.v2({ uri: '/er/misc/subscriber/timezone_list' });
        /** @namespace response.timezone_list **/
        const timezones = response.timezone_list;
        return timezones.map((timezone) => new Timezone(timezone));
    };

    /**
     * This call allows getting the information on subscriber domain (region)
     * @returns {Promise<string>}
     */
    getRegion = async () => {
        const response = await this.api.v2({ uri: '/er/integration/access/region' });
        /** @namespace response.region **/
        return response.region;
    };

    /**
     * This call allows getting the information on subscriber domain (region)
     * @returns {Promise<Array>}
     */
    getRegions = async () => {
        const response = await this.api.v2({ uri: '/er/misc/domains' });
        /** @namespace response.domains **/
        return response.domains.map((domain) => ({
            name: domain.title || null,
            code: domain.code || null,
            timeshift: domain.timeshift || null,
            id: domain.id || null,
            extId: domain.extid || null,
            weight: domain.weight || null,
        }));
    };

    /**
     * Receive the support service telephone number
     * @returns {Promise<string>}
     */
    getSupportPhone = async () => {
        const response = await this.api.v2({
            uri: '/er/misc/subscriber/support_phone',
        });
        /** @namespace response.support_phone **/
        return response.support_phone;
    };

    /**
     * This call allows receiving an sms-code for telephone confirmation
     * @param {string} phone
     * @returns {void}
     * @throws ApiBackendException
     */
    sendConfirmContactPhoneSMSCode = async (phone) => {
        await this.api.v2({
            uri: '/er/misc/subscriber/contact/phone/request_sms_code',
            params: { phone },
            method: QUERY_POST,
        });
    };

    /**
     *  Add new telephone number to the contacts
     * @param {string} phone
     * @param {string} code
     * @returns {void}
     */
    addContactPhone = async (phone, code) => {
        await this.api.v2({
            uri: '/er/misc/subscriber/contact/phone/add',
            method: QUERY_POST,
            params: { phone, code },
        });
    };

    /**
     *  Add a new email-address to the contacts
     * @param {string} email
     * @returns {void}
     */
    addContactEmail = async (email) => {
        await this.api.v2({
            uri: '/er/misc/subscriber/contact/email/add',
            params: { email },
            method: QUERY_POST,
        });
    };

    /**
     * Confirmation of adding an e-mail address to the subscriber's contacts
     * @param {string} contactId
     * @param {string} key
     * @returns {void}
     */
    confirmContactEmail = async (contactId, key) => {
        await this.api.v2({
            uri: '/er/misc/subscriber/contact/email/confirm',
            params: { contact_id: contactId, key },
            method: QUERY_POST,
        });
    };

    /**
     * Send a confirmation code to e-mail to confirm adding an e-mail address to the contacts
     * @param {string} contactId
     * @returns {void}
     */
    sendContactEmailConfirmationCode = async (contactId) => {
        await this.api.v2({
            uri: '/er/misc/subscriber/contact/email/request_confirmation',
            params: { contact_id: contactId },
            method: QUERY_POST,
        });
    };

    /**
     * Delete the e-mail address from the subscriber's contacts
     * @param {string} contactId
     * @returns {void}
     */
    removeContactEmail = async (contactId) => {
        await this.api.v2({
            uri: '/er/misc/subscriber/contact/email/remove',
            params: { contact_id: contactId },
            method: QUERY_POST,
        });
    };

    /**
     * Delete the telephone from the contacts
     * @param {string} contactId
     * @returns {void}
     */
    removeContactPhone = async (contactId) => {
        await this.api.v2({
            uri: '/er/misc/subscriber/contact/phone/remove',
            params: { contact_id: contactId },
            method: QUERY_POST,
        });
    };

    /**
     * Receive the list of subscriber's contacts
     * @returns {Promise<Contact[]>}
     */
    getContacts = async () => {
        const response = await this.api.v2({ uri: '/er/misc/subscriber/contacts' });
        /** @namespace response.contacts **/
        const contacts = response.contacts || [];
        return contacts.map((contact) => new Contact(contact));
    };

    /**
     * Receive a sms-code for telephone confirmation
     * @returns {Promise<void>}
     */
    requestSmsCode2 = async (phone) => {
        await this.api.v2({
            uri: '/er/misc/subscriber/contact/phone/request_sms_code/',
            params: { phone: phone },
            method: QUERY_POST,
        });
    };

    /**
     * Возращает ФИО пользователя
     * @returns {Promise<string>}
     */
    getSubscriberFio = async () => {
        const response = await this.api.v2({ uri: '/er/misc/subscriber/get_fio' });
        /** @namespace response.fio **/
        return response.fio;
    };

    /**
     * Get permits for service information about subscriber
     * @returns {Promise<Object>}
     */
    getPermits = async () => {
        return this.api.v2({ uri: '/er/misc/subscriber/contact/permit' });
    };

    /**
     * Receive an SMS code for confirmation by phone number [DRAFT]
     * @param {string} phone
     * @returns {void}
     */
    requestSMSCode = async (phone) => {
        await this.api.v2({ uri: '/er/billing/phone/request_sms_code', params: { phone: phone }, method: QUERY_POST });
    };

    /**
     * Receive an SMS code for confirmation by contact ID [DRAFT]
     * @param {Number} contactId
     * @returns {void}
     */
    requestSMSCodeByContactId = async (contactId) => {
        await this.api.v2({
            uri: '/er/billing/phone/request_sms_code',
            params: { contact_id: contactId },
            method: QUERY_POST,
        });
    };

    /**
     * Receive an SMS code for confirmation by phone number [DRAFT]
     * @param {string} phone
     * @param {string} code
     * @returns {void}
     */
    phoneConfirm = async (phone, code) => {
        await this.api.v2({
            uri: '/er/billing/phone/confirm',
            params: { phone: phone, code: code },
            method: QUERY_POST,
        });
    };

    /**
     * Receive the agreement number, region and address of the subscriber
     * @param {?string} phone
     * @param {?string} email
     * @param {?string} contract
     * @returns {Promise<Array>}
     */
    getContractsByPhoneOrEmailOrContract = async (phone, email, contract) => {
        const response = await this.api.v2({
            uri: '/er/misc/subscriber/agreement_list',
            params: {
                ...(phone ? { phone_number: phone } : {}),
                ...(email ? { email: email } : {}),
                ...(contract ? { agreement_number: contract } : {}),
            },
            method: QUERY_POST,
        });
        /** @namespace response.agreements **/
        return response.agreements;
    };

    /**
     * Receive the agreement number, region and address of the subscriber
     * @param {string} email
     * @returns {Promise<Array>}
     */
    getContractsByEmail = async (email) => {
        return this.getContractsByPhoneOrEmailOrContract(null, email, null);
    };

    /**
     * Receive the agreement number, region and address of the subscriber
     * @param {string} phone
     * @returns {Promise<Array>}
     */
    getContractsByPhone = async (phone) => {
        return this.getContractsByPhoneOrEmailOrContract(phone, null, null);
    };

    /**
     * Receive the agreement number, region and address of the subscriber
     * @param {string} contract
     * @returns {Promise<Array>}
     */
    getContractsByContract = async (contract) => {
        return this.getContractsByPhoneOrEmailOrContract(null, null, contract);
    };

    /**
     * Receive the agreement number, region and address of the subscriber
     * @param {string} data
     * @returns {Promise<Array>}
     */
    getContractsByData = async (data) => {
        let phone, email, contract;
        if (data.includes('@')) {
            email = data;
        } else if (data.length === 11) {
            phone = data;
        } else {
            contract = data;
        }
        return this.getContractsByPhoneOrEmailOrContract(phone, email, contract);
    };
}
