import BaseModel, { Images } from '@/src/api/v3/models/BaseModel';
import { ShowcaseItemType } from '@/src/constants/types';

export default class Person extends BaseModel {
    CARD_IMAGE_SIZE = { width: 226, height: 226 };
    URL_PREFIX = 'persons';
    EVENT_ITEM_CATEGORY = ShowcaseItemType.PERSON;

    /** Название типа
     *  @type {string} */
    typeName = 'Персона';
    /** Роли
     * @type {string[]} */
    roles = [];
    backgroundModifier = 'backbg--secondary';

    constructor(object) {
        super(object);
        if (object.roles) {
            object.roles.forEach((role) => {
                this.roles[role.type] = role.title;
            });
        }
        this.resources.emptyBackground = null;
    }

    /**
     * Получение изображения для саджеста
     * @returns null|string
     */
    getSuggestSrc() {
        return this.resources.getResourceUrl([Images.SMARTTV_PERSON_POSTER_VIDEO_LIBRARY_BLUEPRINT], {
            size: { width: 40, height: 40 },
        });
    }

    /**
     * Получить изображение для страницы деталей
     * @returns null|string
     */
    getDetailsSrc() {
        return this.resources.getResourceUrl([Images.SMARTTV_PERSON_POSTER_VIDEO_LIBRARY_BLUEPRINT], {
            size: this.CARD_IMAGE_SIZE,
        });
    }

    /**
     * Получить названия ролей
     * @returns string
     */
    getRolesTitles() {
        return Object.values(this.roles).join(', ');
    }
}
