import BaseModel from '@/src/api/v3/models/BaseModel';

/**
 *  Welcome item
 */
export default class WelcomeItem extends BaseModel {
    /** @type {integer} ID */
    id;
    /** @type {integer} WelcomeId */
    welcomeId;
    /** @type {string} Title */
    title;
    /** @type {string} Description */
    description;
    /** @type {null|string} Resource url Desktop */
    resourceUrlDesktop;
    /** @type {null|string} Resource url Mobile */
    resourceUrlMobile;

    constructor(object) {
        super(object);

        this.resourceUrlDesktop = this.resources.getResourceUrl('welcome_screen_web', {
            size: { width: 646, height: 410 },
        });
        this.resourceUrlMobile = this.resources.getResourceUrl('welcome_screen_web_mobile');

        this.id = object.id;
        this.welcomeId = object.welcomeId;
        this.title = object.title;
        this.description = object.description;
    }
}
