import { useAuthStore } from '@/src/stores/RootStoreProvider';
import useModals from '@/src/hooks/useModals';
import dynamic from 'next/dynamic';
const ModalAuthorization = dynamic(() => import('@/components/modal/authorization/ModalAuthorization'));

/**
 * Возвращает функцию для проверки Авторизации
 * Функция показывает окно авторизации, если пользователь не авторизован, иначе вызывает callback
 * @returns {function}
 */
export default function useAuthCheck() {
    const { isGuest } = useAuthStore();
    const { openModal } = useModals();

    return (callback, { title, backgroundSrc } = {}) => {
        if (isGuest) {
            openModal(<ModalAuthorization title={title} backgroundSrc={backgroundSrc} />);
        } else if (callback && typeof callback === 'function') {
            callback();
        }
    };
}
