const PROFILE_ITEMS = [
    {
        name: 'Профиль',
        url: '/profile/personal',
    },
    {
        name: 'Фильмы',
        url: '/profile/videoteka',
    },
    {
        name: 'Подписки',
        url: '/profile/subscriptions',
    },
    {
        name: 'Услуги',
        url: '/profile/services',
    },
    {
        name: 'Мои устройства',
        url: '/profile/connected-devices',
    },
    {
        name: 'Пин-код',
        url: '/profile/pin-code',
    },
    {
        name: 'Выйти',
        action: 'logout',
    },
];

export default PROFILE_ITEMS;
