// noinspection JSUnusedGlobalSymbols

import { ApiMixin } from '@/src/api/ApiMixin';

/**
 * OTT
 * @see https://kblion.ertelecom.ru/pages/viewpage.action?pageId=48433231
 */

export class OTT extends ApiMixin {
    /**
     * Check availability for OTT registration
     * @returns {Promise<Boolean>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    isOTTRegistrationAvailable = async () => {
        const response = await this.api.v2({
            uri: '/er/ott/is_available',
        });
        return response['status'] === 1;
    };

    /**
     * Get agreements by phone number
     * @param {string} phone - phone number (11 chars)
     * @returns {Promise<Array>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getOttContractsByPhone = async (phone) => {
        const response = await this.api.v2({
            uri: '/er/ott/get_agreements_by_phone',
            params: { phone_number: phone },
        });
        return response['principals'];
    };

    /**
     * This call allows to get verification code via sms
     * @param {string} phone
     * The phone number specified in the agreement for which the SMS will be sent in the verification code
     * @returns {Promise<number>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    sendOttRegistrationSMS = async (phone) => {
        const response = await this.api.v2({
            uri: '/er/ott/get_sms_code',
            params: { phone_number: phone },
        });
        return response['time_until_next'];
    };

    /**
     * Create agreement
     * @param {string} phone - phone number
     * @param {string} code
     * @returns {Promise<Object>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    createOTTContract = async (phone, code) => {
        const response = await this.api.v2({
            uri: '/er/ott/create_agreement',
            params: {
                phone_number: phone,
                sms_code: code,
            },
        });
        return {
            domain: response['domain'],
            principal: response['principal'],
        };
    };

    /**
     * Get test period activate and expiration dates
     * @returns {Promise<Array>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getOttTnbExpirationDate = async () => {
        const response = await this.api.v2({
            uri: '/er/billing/tnb_date_activate',
        });
        return response['status'];
    };
}
