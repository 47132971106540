import Asset from '@/src/api/v3/models/Asset';
import { ShowcaseItemType } from '@/src/constants/types';

/**
 *  Фильм
 */

export default class Movie extends Asset {
    URL_PREFIX = 'movies';
    EVENT_ITEM_CATEGORY = ShowcaseItemType.MOVIE;

    /** Название типа
     *  @type {string} */
    typeName = 'Фильм';
}
