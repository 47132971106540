import Common from '@/src/modules/Common';
import { ApiMixin } from '@/src/api/ApiMixin';

/**
 * Epg
 * @see https://kblion.ertelecom.ru/display/BA/Trans+3.+EPG
 */

export class Epg extends ApiMixin {
    /**
     * Obtaining the schedule for a period FROM TO
     * @param {number|array} [channelId] One or several number identifiers of a channel (comma separated).
     * @param {number} [startFrom]  Time in UNIX timestamp format, from which the schedule will be developed.
     * @param {number} [startTo]  Time in UNIX timestamp format, to which the schedule will be developed.
     * @returns {Promise<array>}
     * @throws ApiBackendException
     * @throws ApiNetworkException
     */
    getEpgSchedule = async (channelId, startFrom = 0, startTo = 0) => {
        let response = await this.api.v2({
            uri: '/epg/get_schedule',
            params: {
                select: 'channel_id,id,start,end,duration,title,description,age_rating,countries,tags,genres,program,exact_time_start,exact_time_end',
                channel_id: Array.isArray(channelId) ? channelId.join(',') : channelId,
                start_from: startFrom || Common.getTime(),
                start_to: startTo || Common.getTime(),
            },
        });
        return response['channels'];
    };
}
