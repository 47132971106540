import BaseModel from '@/src/api/v3/models/BaseModel';
import Showcase from '@/src/api/v3/models/Showcase';
import { ShowcaseItemType } from '@/src/constants/types';

/**
 *  Программа передач
 */

export default class Program extends BaseModel {
    static CARD_IMAGE_SIZE = {
        width: 228,
        height: 124,
    };
    EVENT_ITEM_CATEGORY = ShowcaseItemType.PROGRAM;

    /** Название типа
     *  @type {string} */
    typeName = 'Программа передач';
    /** Описание
     * @type {string} */
    description;
    /** Название эпизода
     *  @type {string} */
    episodeTitle;
    /** Номер эпизода
     *  @type {Number} */
    episodeNumber;
    /** Год выпуска
     *  @type {?Date} */
    releasedYear;
    /** Оценки
     *  @type {number[]} */
    scores = [];
    /** Жанры
     *  @type {string[]} */
    genres = [];
    /** Страны
     *  @type {string[]} */
    countries = [];
    /** @type {Showcase[]} Персоны */
    persons = [];

    /**
     * Program constructor.
     * @param {object} object
     */
    constructor(object) {
        super(object);
        this.description = object.description;
        this.episodeNumber = object.episodeNumber;
        this.episodeTitle = object.episodeTitle;

        if (object.persons?.showcases) {
            object.persons.showcases.forEach((showcase_object) => {
                let showcase = new Showcase(showcase_object);
                this.persons.push(showcase);
            });
        }

        if (object.score) {
            object.score.forEach((score) => {
                this.scores[score.type] = score.value;
            });
        }

        this.releasedYear = object.year || (object.releasedAt ? new Date(object.releasedAt).getFullYear() : null);

        if (object.countries) {
            this.countries = [];
            object.countries.forEach((country) => {
                if (this.countries.indexOf(country.title) === -1) {
                    this.countries.push(country.title);
                }
            });
        }

        if (object.genres) {
            this.genres = [];
            object.genres.forEach((genre) => {
                this.genres.push(genre.title);
            });
        }
    }

    /**
     * Получение изображения
     * @returns null|string
     * @param options
     */
    getImageSrc(options = null) {
        if (options === null) {
            options = { size: Program.CARD_IMAGE_SIZE };
        }
        return this.resources.getResourceUrl(
            [
                '3_smarttv_*_poster_video_library_blueprint',
                '3_smarttv_tvshow_poster_video_library_blueprint',
                'poster_blueprint',
                'poster',
                'subscription_blueprint',
            ],
            options
        );
    }

    /**
     * Получить изображения дл фона
     * @returns null|string
     */
    getBackgroundSrc() {
        return this.resources.getResourceUrl('3_smarttv_tvshow_background_video_library_blueprint');
    }

    /**
     * @returns Array<string>
     */
    getTags() {
        let tags = [];
        if (this.releasedYear) {
            tags.push(this.releasedYear);
        }
        if (this.duration) {
            tags.push(this.getDurationText());
        }
        if (this.genres) {
            tags = tags.concat(this.genres);
        }
        if (this.countries) {
            tags = tags.concat(this.countries);
        }
        return tags;
    }
}
