import { MENU_GENRES } from '@/src/constants/genres';
import { slug } from '@/src/modules/Common';
import { ShowcaseItemType } from '@/src/constants/types';

const getMenuItem = (id, title, resourceId, category = ShowcaseItemType.COLLECTION) => {
    const prefix = category === ShowcaseItemType.COLLECTION ? 'compilations' : '';
    return {
        name: title,
        url: `/${prefix}/${slug(title)}-${id}`,
        imgSrc: `https://er-cdn.ertelecom.ru/content/public/r${resourceId}/380x222:crop`,
        eventParams: {
            item_id: id,
            item_name: title,
            item_category: category,
        },
    };
};

/**
 * @param {Boolean} isGuest
 * @param {Subscription[]} subscriptions
 * @param {ChannelPackage[]} channelPackages
 * @param {string} path
 */
export const getMenuItems = (isGuest, subscriptions, channelPackages, path = '') => {
    let menuSubscription = subscriptions
        ? subscriptions.map((item) => ({
              title: item.title,
              url: item.getUrl(),
              imgSrc: item.getImageSrc(),
              offerText: item.offers?.isTNBAvailable() ? item.offers.offer.stock.getPeriodText() : null,
              offerPrice: item.offers?.offer?.stock?.priceWithDiscount,
              eventParams: {
                  item_id: item.id,
                  item_name: item.title,
                  item_category: item.EVENT_ITEM_CATEGORY,
              },
          }))
        : [];
    if (menuSubscription?.length > 8) {
        menuSubscription = menuSubscription.slice(0, 7);
        menuSubscription.push({
            title: 'Все',
            url: '/subscriptions',
        });
    }
    let menuChannelPackages = channelPackages
        ? channelPackages.map((item) => {
              return {
                  title: item.title,
                  url: item.getUrl(),
                  imgSrc: item.getImageSrc(),
                  offerText: item.offers.isTNBAvailable() ? item.offers.offer.stock.getPeriodText() : '',
                  offerPrice: item.offers?.offer?.stock?.priceWithDiscount,
                  eventParams: {
                      item_id: item.id,
                      item_name: item.title,
                      item_category: item.EVENT_ITEM_CATEGORY,
                  },
              };
          })
        : [];
    if (menuChannelPackages.length > 8) {
        menuChannelPackages = menuChannelPackages.slice(0, 7);
        menuChannelPackages.push({
            title: 'Все',
            url: '/channel-packages',
        });
    }
    let items = [
        {
            name: 'Телевидение',
            url: isGuest ? '/television' : '/television/watch',
            active:
                path.includes('/television') ||
                path.includes('/tv-program-table') ||
                path.includes('/tv-shows') ||
                path.includes('/channel-packages') ||
                path.includes('/catchup-search') ||
                path.includes('/programms'),
            submenu: {
                submenu: [
                    {
                        list: [
                            {
                                name: 'Прямой эфир',
                                url: '/television/watch',
                                modifier: 'menu-group__item--live',
                            },
                            {
                                name: 'Телепрограмма',
                                url: '/tv-program-table',
                            },
                            {
                                name: 'Каналы',
                                url: '/television',
                            },
                            {
                                name: 'В записи',
                                url: '/catchup-search',
                            },
                        ],
                    },
                ],
                cards: {
                    title: 'Пакеты каналов',
                    url: '/channel-packages',
                    modifier: 'menu-group--grid',
                    list: menuChannelPackages,
                },
            },
        },
        {
            name: 'Фильмы',
            url: '/pages/films-new',
            active:
                path.includes('/pages/films-new') ||
                path.includes('/movies') ||
                path.includes('/films-new') ||
                path.includes('/showcases/films-new/topsub'),
            submenu: {
                submenu: [
                    {
                        title: 'Жанры',
                        url: '/genres',
                        list: getSubmenuGenresItems('movies'),
                    },
                    {
                        title: 'Разделы',
                        url: '/pages/films-new',
                        list: [
                            {
                                name: 'Все фильмы',
                                url: '/movies',
                            },
                            {
                                name: 'Новинки',
                                url: '/showcases/films-new/new-title',
                            },
                            {
                                name: 'Популярное',
                                url: '/showcases/films-new/topsub',
                            },
                            {
                                name: 'Лучшее',
                                url: '/showcases/films-new/best-films',
                            },
                        ],
                    },
                ],
                cards: {
                    title: 'Популярные франшизы',
                    url: '/showcases/films-new/franfshi',
                    modifier: 'menu-group--grid-small',
                    list: [
                        getMenuItem(7153626, 'Мумия', 75371187),
                        getMenuItem(3156097, 'Форсаж', 75371235),
                        getMenuItem(7181328, 'Назад в будущее', 57939158),
                        getMenuItem(8169931, 'Помогите, я уменьшил', 75371233),
                        getMenuItem(7314491, 'Пятьдесят оттенков серого', 57939284),
                        getMenuItem(6968721, 'Мир Юрского периода', 57939518),
                    ],
                },
            },
        },
        {
            name: 'Сериалы',
            url: '/pages/series-new',
            active: path.includes('/series'),
            submenu: {
                submenu: [
                    {
                        title: 'Жанры',
                        url: '/genres',
                        list: getSubmenuGenresItems('series'),
                    },
                    {
                        title: 'Разделы',
                        url: '/pages/series-new',
                        list: [
                            {
                                name: 'Все сериалы',
                                url: '/series',
                            },
                            {
                                name: 'Новинки',
                                url: '/showcases/series-new/all-series',
                            },
                            {
                                name: 'Популярное',
                                url: '/showcases/series-new/popular-series',
                            },
                            {
                                name: 'Культовые',
                                url: '/showcases/children/package/1157085/series',
                            },
                        ],
                    },
                ],
                cards: {
                    title: 'Подборки',
                    url: '/showcases/series-new/series-collections',
                    modifier: 'menu-group--grid-small',
                    list: [
                        getMenuItem(1157095, 'Детективные сериалы', 63568110),
                        getMenuItem(7120065, 'Наши новые сериалы', 75185989),
                        getMenuItem(3376597, 'Сериалы про отношения', 57890531),
                        getMenuItem(3376552, 'Криминальные сериалы', 57890675),
                        getMenuItem(3376525, 'Комедийные сериалы', 70441777),
                        getMenuItem(2598634, 'Сериалы-фентези', 70441790),
                    ],
                },
            },
        },
        {
            name: 'Для детей',
            url: '/pages/kids-new',
            active: path.includes('/kids-new'),
            submenu: {
                submenu: [
                    {
                        title: 'Возраст',
                        url: '/pages/kids-new',
                        list: [
                            {
                                name: 'От 0 до 6 лет',
                                url: '/showcases/kids-new/multser',
                            },
                            {
                                name: 'От 6 до 12 лет',
                                url: '/showcases/kids-new/primaryschoolstb',
                            },
                            {
                                name: 'От 12 и старше',
                                url: '/showcases/kids-new/secondaryschoolstb',
                            },
                        ],
                    },
                    {
                        title: 'Разделы',
                        url: '/pages/kids-new',
                        list: [
                            {
                                name: 'Новинки',
                                url: '/showcases/kids-new/newforchild',
                            },
                            {
                                name: 'Подборки',
                                url: '/showcases/kids-new/kids-collections',
                            },
                            {
                                name: 'Обучение',
                                url: '/showcases/kids-new/collections-skysmart',
                            },
                            {
                                name: 'Детские каналы',
                                url: '/showcases/kids-new/kids-channels',
                            },
                        ],
                    },
                ],
                cards: {
                    title: 'Любимые герои',
                    url: '/showcases/kids-new/mult-hero-web',
                    modifier: 'menu-group--grid-small',
                    list: [
                        getMenuItem(7028723, 'Маша и медведь', 57897407),
                        getMenuItem(2126421, 'Гадкий я', 57897317),
                        getMenuItem(2236917, 'Кунг-Фу Панда', 57897398),
                        getMenuItem(3340714, 'Тролли', 75371103),
                        getMenuItem(7028741, 'Зверопой', 75371055),
                        getMenuItem(2237305, 'Шрэк', 57897254),
                    ],
                },
            },
        },
        {
            name: 'Подписки',
            url: '/subscriptions',
            active: path.includes('/subscriptions') && !path.includes('/profile'),
            modifier: 'menu-dropdown--space',
            submenu: {
                cards: {
                    title: 'Подписки',
                    modifier: 'menu-group--grid menu-group--space',
                    url: '/subscriptions',
                    list: menuSubscription,
                },
                banner: true,
            },
        },
    ];
    if (!process.env.NEXT_PUBLIC_PRODUCTION_MODE) {
        // noinspection JSCheckFunctionSignatures
        items.push({
            name: 'Selena',
            url: '/selena',
            active: path.includes('/selena'),
        });
    }
    return items;
};

const getSubmenuGenresItems = (urlPrefix) => {
    return Object.keys(MENU_GENRES).map((index) => ({
        name: MENU_GENRES[index],
        url: `/${urlPrefix}?genres=${index}`,
    }));
};
